import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { GET_INVOICE } from "../url_constants";

const getInvoice = async (id) => {
  const result = await axios.get(`${GET_INVOICE}?id=${id}`);
  return result.data;
};

export const useGetInvoice = (invoiceId, options) => {
  return useQuery({
    queryFn: () => getInvoice(invoiceId),
    queryKey: ["get-price", invoiceId],
    ...options,
  });
};
