import axios from "axios";
import { FEEDBACK } from "../url_constants";
import { useMutation } from "@tanstack/react-query";

const createFeedback = (payload) => {
  const config = {
    url: `${FEEDBACK}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    data: payload,
  };
  return axios(config).then().catch();
};

export const useCreateFeedback = (options) => {
  return useMutation({
    mutationFn: (data) => createFeedback(data),
    mutationKey: ["create-feedback"],
    ...options,
  });
};
