import { Route } from "react-router-dom";
import ProtectedRoute from "../components/ProtectedRoute";
import Dashboard from "../pages/Dashboard";
import Bookmarks from "../pages/Bookmarks";
import Bookings from "../pages/Bookings";
import Reviews from "../pages/Reviews";
import MyProfile from "../pages/MyProfile";
import Listing from "../pages/Listing";
import React from "react";

export const AdminRoutesConstant = {
  dashboard: {
    name: "Dashboard",
    path: "/dashboard",
    Component: Dashboard,
    isProtected: true,
  },
  bookmarks: {
    name: "Bookmarks",
    path: "/bookmarks",
    Component: Bookmarks,
    isProtected: true,
  },
  reviews: {
    name: "Reviews",
    path: "/reviews",
    Component: Reviews,
    isProtected: true,
  },
  myProfile: {
    name: "MyProfile",
    path: "/myProfile",
    Component: MyProfile,
    isProtected: true,
  },
  bookings: {
    name: "Bookings",
    path: "/bookings",
    Component: Bookings,
    isProtected: true,
  },
  listing: {
    name: "Listing",
    path: "/listing",
    Component: Listing,
    isProtected: true,
  },
  addListing: {
    name: "AddListing",
    path: "/listing/add",
    Component: Listing,
    isProtected: true,
  },
  listingDetail: {
    name: "ListingDetail",
    path: "/listing/:id",
    Component: Listing,
    isProtected: true,
  },
  editListing: {
    name: "EditListing",
    path: "/listing/:id/edit",
    Component: Listing,
    isProtected: true,
  },
  listingDetailId: {
    name: "ListingDetailId",
    path: "/listing-details/:id",
    Component: Listing,
    isProtected: true,
  },
};

export const AdminRoutes = () => {
  return (
    <React.Fragment>
      {Object.keys(AdminRoutesConstant).map((r, k) => {
        const R = AdminRoutesConstant[r];
        return (
          <Route
            key={k}
            path={R.path}
            element={
              R.isProtected ? (
                <ProtectedRoute isVendor={false} element={<R.Component />} />
              ) : (
                <r.component />
              )
            }
          />
        );
      })}
    </React.Fragment>
  );
};
