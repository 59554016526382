import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useGetInvoice } from "../apis/booking/use-get-invoice";
import { CircularProgress } from "@mui/material";
import { format } from "date-fns";
import { formatCurrency } from "../utils/common";

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("./../images", false, /\.(png|jpe?g|svg)$/)
);

const Invoice = () => {
  const { state } = useLocation();
  const ids = new URLSearchParams(window.location.search);
  const invoiceId = ids.get("id");
  const { data, isLoading } = useGetInvoice(invoiceId, {
    enabled: !!invoiceId,
  });

  if (isLoading && invoiceId) {
    return (
      <div className="vh-100 vw-100 d-flex justify-content-center align-items-center">
        <CircularProgress />
      </div>
    );
  }
  const invoiceDetail =
    data?.data?.length > 0
      ? data.data?.[0]
      : state?.invoiceData?.invoice_detail?.[0];

  return (
    <>
      <section className="invoice-area py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 mx-auto">
              <div className="block-card">
                <div className="block-card-header d-flex flex-wrap align-items-center justify-content-between">
                  <div className="invoice-logo">
                    <img src={images["logo-black.png"]} alt="logo" />
                  </div>
                  <h2 className="widget-title pb-0 font-size-30 line-height-30">
                    Invoice
                  </h2>
                </div>
                <div className="block-card-body">
                  <div className="invoice-item mb-5">
                    <ul className="list-items text-right">
                      <li className="mb-1">
                        <span className="text-color font-weight-semi-bold">
                          Order:
                        </span>{" "}
                        {invoiceDetail?.invoice_number}
                      </li>
                      <li className="mb-1">
                        <span className="text-color font-weight-semi-bold">
                          Date:
                        </span>{" "}
                        {format(
                          new Date(invoiceDetail?.booking_date),
                          "dd-MM-yyyy"
                        )}
                      </li>
                    </ul>
                  </div>
                  <div className="invoice-item">
                    <div className="row">
                      {/* <div className="col-lg-6">
                        <div className="invoice-info">
                          <h3 className="widget-title pb-2">Author:</h3>
                          <ul className="list-items">
                            <li className="mb-1">Listhub Ltd.</li>
                            <li className="mb-1">
                              36 Edgewater Street, Melbourne, AU
                            </li>
                            <li className="mb-1">Australia, CF44 6ZL, AU</li>
                          </ul>
                        </div>
                      </div> */}
                      <div className="col-lg-6">
                        <div className="invoice-info">
                          <h3 className="widget-title pb-2">To:</h3>
                          <ul className="list-items">
                            <li className="mb-1">{invoiceDetail?.name}</li>
                            <li className="mb-1">{invoiceDetail?.email}</li>
                            <li className="mb-1">{invoiceDetail?.phone}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="invoice-item mt-5">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="my-table table-responsive">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th>Booking name</th>
                                <th>Quantity</th>
                                <th>Guests</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{invoiceDetail?.venue}</td>
                                <td>1</td>
                                <td>{invoiceDetail?.guests}</td>
                                <td>{formatCurrency(invoiceDetail?.amount)}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-lg-3 ml-auto">
                        <ul className="list-items list--items text-right">
                          <li>
                            <span className="text-color mr-3">
                              Total Amount:
                            </span>{" "}
                            {formatCurrency(invoiceDetail?.amount)}
                          </li>
                        </ul>
                        {/* <p className="text-right font-weight-medium">
                          Paid via PayPal
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="btn-box mt-4 text-center">
                <a href="/" className="btn-gray">
                  <i className="la la-backward mr-2"></i>Back to Home
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Invoice;
