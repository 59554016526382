import { toast } from 'react-toastify';
import { updateProfilePicture as updateProfilePictureApi } from '../../apis/user_apis/profilePicture';
import { updateUserEmail as updateUserEmailApi } from '../../apis/user_apis/user_data';
export const updateProfilePicture = (token, payload, callback) => {
  updateProfilePictureApi(token, payload)
    .then(() => {
      toast.success('Changes saved successfully!! :)');
      if (callback) callback();
    })
    .catch(() => {
      toast.error('Something went wrong');
    });
};

export const updateUserEmail = async (token, payload, callback) => {
  try {
    const res = await updateUserEmailApi(token, payload);
    callback();
    toast.success(res.data.message);
  } catch (err) {
    toast.error('Something went wrong');
  }
};
