import React from 'react';
import '../styles/style.css';
import '../styles/bootstrap.min.css';
import '../styles/line-awesome.min.css';
import '../styles/owl.carousel.min.css';
import '../styles/owl.theme.default.min.css';
import '../styles/animated-headline.css';
import '../styles/jquery-ui.css';
import '../styles/jquery.fancybox.css';
import '../styles/chosen.min.css';
import logo_black from '../images/logo-black.png';
import logo_white from '../images/logo-white.png';
const Footer = () => {
  return (
    <section className="footer-area bg-dark pattern-bg before-none padding-top-30px padding-bottom-30px">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="cta-content d-flex align-items-center justify-content-between p-0">
              <div className="section-heading">
                <h2 className="sec__title mb-0 font-size-24 line-height-30 text-white">
                  Don't miss your monthly offer
                </h2>
                <p className="sec__desc font-size-16 text-white-50">
                  Subscribe to get latest updates and information.
                </p>
              </div>
              <form method="post" className="form-box">
                <div className="form-group mb-0">
                  <span className="la la-envelope-o form-icon"></span>
                  <input
                    className="form-control form-control-styled form-control-long"
                    type="email"
                    placeholder="Enter your email"
                  />
                  <p className="font-size-12 font-weight-medium pt-1 text-white-50">
                    <i className="la la-lock mr-1"></i>Your are 100% protected
                  </p>
                  <button
                    className="theme-btn gradient-btn subscribe-btn input-btn-append border-0"
                    type="submit"
                  >
                    Subscribe <i className="la la-arrow-right ml-1"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="section-block-2 margin-top-30px margin-bottom-40px"></div>
        <div className="row">
          <div className="col-lg-3 responsive-column">
            <div className="footer-item footer-item-2">
              <div className="footer-logo">
                <a className="foot-logo">
                  <img src={logo_white} alt="logo" />
                </a>
              </div>
              <p className="footer__desc">
                Morbi convallis bibendum urna ut viverra. Maecenas quis
                consequat libero, a feugiat eros culpa officia deserunt mollit.
              </p>
              <p className="footer__desc">
                <a className="btn-text">
                  View on the map <i className="la la-arrow-right icon"></i>
                </a>
              </p>
            </div>
          </div>
          <div className="col-lg-3 responsive-column">
            <div className="footer-item footer-item-2">
              <h4 className="footer__title text-white">Quick Links</h4>
              <div className="stroke-shape mb-3"></div>
              <ul className="list-items">
                <li>
                  <a href="about.html">About Us</a>
                </li>
                <li>
                  <a data-toggle="modal" data-target="#signUpModal">
                    Sign Up
                  </a>
                </li>
                <li>
                  <a data-toggle="modal" data-target="#loginModal">
                    Log In
                  </a>
                </li>
                <li>
                  <a href="add-listing.html">Add Listing</a>
                </li>
                <li>
                  <a href="contact.html">Contact Us</a>
                </li>
                <li>
                  <a href="pricing.html">Pricing</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 responsive-column">
            <div className="footer-item footer-item-2">
              <h4 className="footer__title text-white">Categories</h4>
              <div className="stroke-shape mb-3"></div>
              <ul className="list-items">
                <li>
                  <a>Shops</a>
                </li>
                <li>
                  <a>Hotels</a>
                </li>
                <li>
                  <a>Restaurants</a>
                </li>
                <li>
                  <a>Bars</a>
                </li>
                <li>
                  <a>Events</a>
                </li>
                <li>
                  <a>Fitness</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 responsive-column">
            <div className="footer-item footer-item-2">
              <h4 className="footer__title text-white">Contact with Us</h4>
              <div className="stroke-shape mb-3"></div>
              <ul className="list-items contact-links">
                <li>
                  <span className="d-block text-white mb-1">
                    <i className="la la-map mr-1 text-color-2"></i>Address:
                  </span>{' '}
                  12345 Little Baker St, Melbourne
                </li>
                <li>
                  <span className="d-block text-white mb-1">
                    <i className="la la-phone mr-1 text-color-2"></i>Phone:
                  </span>
                  <a>+ 61 23 8093 3400</a>
                </li>
                <li>
                  <span className="d-block text-white mb-1">
                    <i className="la la-envelope mr-1 text-color-2"></i>Email:
                  </span>
                  <a>Uniworkpass@gmail.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="row pt-4 align-items-center">
          <div className="col-lg-5">
            <div className="footer-chosen-container footer-chosen-container-2 user-chosen-select-container d-flex align-items-center justify-content-start">
              <div className="mr-2">
                <select className="user-chosen-select">
                  <option value="1" selected>
                    English
                  </option>
                  <option value="2">Espanol</option>
                  <option value="3">Deutsch</option>
                  <option value="4">Türkçe</option>
                  <option value="5">Polski</option>
                  <option value="6">Português</option>
                  <option value="7">Italiano</option>
                  <option value="8">Dansk</option>
                  <option value="9">French</option>
                  <option value="10">German</option>
                </select>
              </div>
              <div>
                <select className="user-chosen-select">
                  <option value="1">Argentina</option>
                  <option value="2">Australia</option>
                  <option value="3">Canada</option>
                  <option value="4">Chile</option>
                  <option value="5">Czech Republic</option>
                  <option value="6">France</option>
                  <option value="7">Germany</option>
                  <option value="8">Italy</option>
                  <option value="9">Japan</option>
                  <option value="10">Mexico</option>
                  <option value="11">New Zealand</option>
                  <option value="12">Spain</option>
                  <option value="13">Turkey</option>
                  <option value="14">United Kingdom</option>
                  <option value="15" selected>
                    United States
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            <ul className="list-items term-list term-list-2 text-right">
              <li>
                <a href="terms-and-conditions.html">Terms & Conditions</a>
              </li>
              <li>
                <a href="privacy-policy.html">Privacy Policy</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="section-block-2 margin-top-30px margin-bottom-30px"></div>
        <div className="row">
          <div className="col-lg-12">
            <div className="copy-right d-flex align-items-center justify-content-between">
              <p className="copy__desc">
                &copy; Copyright Uniworkpass{' '}
                <script> document.write(new Date().getFullYear()); </script>.
                Made with
                <span className="la la-heart-o"></span> by{' '}
                <a href="https://themeforest.net/user/techydevs/portfolio">
                  TechyDevs
                </a>
              </p>
              <ul className="social-profile social-profile-colored">
                <li>
                  <a className="facebook-bg">
                    <i className="lab la-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a className="twitter-bg">
                    <i className="lab la-twitter"></i>
                  </a>
                </li>
                <li>
                  <a className="instagram-bg">
                    <i className="lab la-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
