export const Reviews = () => {
  return (
    <div className="star-rating-wrap d-flex align-items-center ">
      <div className="star-rating text-color-5 font-size-18">
        <span>
          <i className="la la-star"></i>
        </span>
        <span className="ml-n1">
          <i className="la la-star"></i>
        </span>
        <span className="ml-n1">
          <i className="la la-star"></i>
        </span>
        <span className="ml-n1">
          <i className="la la-star"></i>
        </span>
        <span className="ml-n1">
          <i className="la la-star"></i>
        </span>
      </div>
      <p className="font-size-14 pl-2 font-weight-medium mb-0">1348 reviews</p>
    </div>
  );
};
