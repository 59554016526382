import { LoadingButton } from "@mui/lab";
import { Card } from "../card";
import { Controller, useForm } from "react-hook-form";
import { Box } from "@mui/material";
import Cleave from "cleave.js/react";
import { getUserDetails } from "../../utils/common";
import { useBookingStore } from "../../states/use-booking-store";
import { useCheckout } from "../../lib/use-checkout";

export const UPIPayment = () => {
  const { control, handleSubmit } = useForm();
  const { booking } = useBookingStore();
  const info = booking?.info;
  const userData = getUserDetails();
  const { createBooking, creatingBooking, isLoading } = useCheckout();

  const handlePayment = (data) => {
    createBooking(
      {
        user_id: userData.id,
        venue: booking.venue.id,
        vendor_id: booking.venue.user_id_id,
        guests: info?.guests,
        full_price: info?.full_price,
        start_date: info?.start_date,
        end_date: info?.end_date,
      },
      {
        methodType: "upi",
        method: data,
      }
    );
  };

  return (
    <Card title={"UPI"}>
      <form onSubmit={handleSubmit(handlePayment)}>
        <div className="block-card-body">
          <div className="payment-option-wrap">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <Controller
                  name="vpa"
                  control={control}
                  render={({ field: { onChange, name } }) => (
                    <Cleave
                      className="form-control form-control-styled"
                      placeholder="abc@okhdfc"
                      name={name}
                      onChange={onChange}
                    />
                  )}
                />
              </Box>
            </Box>
          </div>
          <div className="btn-box pt-3">
            <LoadingButton
              variant="outlined"
              type="submit"
              loading={creatingBooking}
              disabled={isLoading}
              className="theme-btn gradient-btn border-0 text-white"
            >
              Proceed to pay
            </LoadingButton>
          </div>
        </div>
      </form>
    </Card>
  );
};
