import React from "react";
import NavbarAdminWrapper from "../NavbarAdminWrapper";
import AdminSidebar from "../admin_sidebar";
import { useGetMyBooking } from "../../apis/booking/use-get-my-booking";
import { getUserDetails } from "../../utils/common";
import { BookingList } from "./booking-list";

export const AdminBooking = () => {
  const user = getUserDetails();
  const { data, isLoading } = useGetMyBooking(user.id, {
    enabled: !!user?.id,
  });

  return (
    <div>
      <AdminSidebar activeRoute="bookings" />
      <NavbarAdminWrapper>
        <div className="container-fluid dashboard-inner-body-container">
          <div className="breadcrumb-content d-sm-flex align-items-center justify-content-between mb-4">
            <div className="section-heading">
              <h2 className="sec__title font-size-24 mb-0">Bookings</h2>
            </div>
            <ul className="list-items bread-list bread-list-2">
              <li>
                <a>Home</a>
              </li>
              <li>Dashboard</li>
              <li>Bookings</li>
            </ul>
          </div>
          <BookingList isAdmin bookings={data?.data} />
        </div>
      </NavbarAdminWrapper>
    </div>
  );
};

