// ** LOGIN USER
export const LOGIN_START = "LOGIN_START";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

// ** LOGOUT USER
export const LOGOUT_START = "LOGOUT_START";
export const LOGOUT_FAIL = "LOGOUT_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";

// ** REGISTER USER
export const REGISTER_START = "REGISTER_START";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";

// ** GET USER DETAILS
export const GET_USER_DETAILS_START = "GET_USER_DETAILS_START";
export const GET_USER_DETAILS_FAIL = "GET_USER_DETAILS_FAIL";
export const GET_USER_DETAILS_SUCCESS = "GET_USER_DETAILS_SUCCESS";

// ** GET LISTINGS
export const GET_LISTING_START = "GET_LISTING_START";
export const GET_LISTING_FAIL = "GET_LISTING_FAIL";
export const GET_LISTING_SUCCESS = "GET_LISTING_SUCCESS";

// ** POST LISTINGS
export const POST_LISTING_START = "POST_LISTING_START";
export const POST_LISTING_FAIL = "POST_LISTING_FAIL";
export const POST_LISTING_SUCCESS = "POST_LISTING_SUCCESS";
