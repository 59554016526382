import axios from "axios";
import { DELETE_LISTING } from "../url_constants";

export const removeListings = (listing_id, token = null) => {
  const data = JSON.stringify({
    id: listing_id,
  });
  const config = {
    method: "DELETE",
    url: DELETE_LISTING,
    headers: {
      "Content-Type": "application/json",
      // 'Authorization': `Bearer ${token}`
    },
    data: data,
  };
  return axios(config).then().catch();
};
