import axios from "axios";
import { GET_LISTING } from "../url_constants";

export const getListings = (token = null, id = null, user = null) => {
  let formattedURL = `${GET_LISTING}`;
  if (id || user) {
    formattedURL += "?";
    if (id && user) {
      formattedURL += `user=${user}&id=${id}`;
    } else if (id) {
      formattedURL += `id=${id}`;
    } else if (user) {
      formattedURL += `user=${user}`;
    }
  }
  const config = {
    method: "GET",
    url: formattedURL,
    // headers: {
    //     'Authorization': `Bearer ${token}`
    // }
  };
  return axios(config).then().catch();
};
