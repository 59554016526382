import { toast } from 'react-toastify';
import { deleteSubCatRecord as deleteSubCatRecordApi } from '../../apis/listing_apis/editListing';

export const deleteSubCatRecord = (payload, callback) => {
  deleteSubCatRecordApi(payload)
    .then(() => {
      callback();
      toast.success('Record deleted successfully!! :)');
    })
    .catch(() => {
      toast.error('Something went wrong');
    });
};
