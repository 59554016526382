import { Container, ImageList, ImageListItem } from "@mui/material";
import { getImageFromPath } from "../../utils/common";
import Slider from "react-slick";

export const Gallery = ({ images }) => {
  // console.log("images", images);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Container className="d-flex justify-content-center">
      <ImageList
        // sx={{ width: "100%", height: 500 }}
        sx={{ width: "100%" }}
        cols={3}
        rowHeight={200}
        variant="masonry"
        gap={40}
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          minHeight: "300px",
        }}
      >
        <Slider {...settings}>
          {images &&
            images.map((img, idx) => {
              return (
                <ImageListItem>
                  <div
                    data-slick='{"slidesToShow": 4, "slidesToScroll": 4}'
                    style={{ width: "100%" }}
                  >
                    <img
                      style={{
                        objectFit: "fill",
                        borderRadius: "15px",
                      }}
                      src={getImageFromPath(img.path)}
                      alt={`listing ${idx}`}
                      width={500}
                    />
                  </div>
                </ImageListItem>
              );
            })}
        </Slider>
      </ImageList>
    </Container>
  );
};
