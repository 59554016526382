import axios from "axios";
import { GET_CITIES } from "../url_constants";
import { useQuery } from "@tanstack/react-query";

export const getCities = () => {
  const config = {
    method: "GET",
    url: GET_CITIES,
    headers: {
      "Content-Type": "application/json",
      // 'Authorization': `Bearer ${token}`
    },
  };
  return axios(config).then().catch();
};

export const useGetCities = (options) => {
  return useQuery({
    queryFn: getCities,
    queryKey: ["get-cities"],
    ...options,
  });
};

export const useGetCitiesExtended = () => {
  const { data, isLoading } = useGetCities();

  return {
    data: data?.data?.map((value) => {
      return {
        label: value?.name,
        value: value?.id,
        state: value?.state__name,
      };
    }),
    isLoading,
  };
};
