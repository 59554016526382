import { Box } from "@mui/material";
import { Card } from "../card";
import Footer from "../footer";
import { MiniHeader } from "../mini-header";
import { useBookingStore } from "../../states/use-booking-store";
import { format } from "date-fns";
import { formatCurrency } from "../../utils/common";
import { PersonalInfo } from "./personal-info";
import { useCheckoutStore } from "../../states/use-checkout-store";
import { SelectPaymentMethod } from "./select-payment-method";
import { useMemo } from "react";
import { CardInfo } from "./card-info";
import { UPIPayment } from "./upi";

export const Payment = () => {
  const { booking } = useBookingStore();
  const info = booking?.info;
  const { checkout } = useCheckoutStore();

  const renderPaymentMethod = useMemo(() => {
    if (checkout?.step < 3) {
      return;
    }
    if (checkout?.paymentMethod?.code === "vpa") {
      return <UPIPayment />;
    } else if (checkout?.paymentMethod?.code === "card") {
      return <CardInfo />;
    }
  }, [checkout?.step, checkout?.paymentMethod?.code]);

  return (
    <>
      <MiniHeader />
      <div className="container">
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr",
            gap: "20px",
          }}
        >
          <div>
            <PersonalInfo />
            {checkout?.step > 1 ? <SelectPaymentMethod /> : null}
            {renderPaymentMethod}
          </div>
          <Card title={"Booking Summary"}>
            <div className="block-card-body">
              <div className="booking-summary">
                <ul className="list-items list--items">
                  <li>
                    <span className="text-color">Start Date:</span>{" "}
                    {format(new Date(info?.start_date), "dd/LL/uu")}
                  </li>
                  <li>
                    <span className="text-color">End Date:</span>{" "}
                    {format(new Date(info?.end_date), "dd/LL/uu")}
                  </li>
                  <li>
                    <span className="text-color">Guests:</span> {info?.guests}
                  </li>
                </ul>
                <div className="section-block-2 my-3"></div>
                <h3 className="widget-title d-flex align-items-center justify-content-between font-size-16 pb-0">
                  Total Cost:{" "}
                  <span className="text-color-2 font-weight-semi-bold">
                    {formatCurrency(info?.full_price)}
                  </span>
                </h3>
                <div className="section-block-2 my-3"></div>
              </div>
            </div>
          </Card>
        </Box>
      </div>
      <Footer />
    </>
  );
};
