import React, { useState } from "react";
import "../styles/style.css";
import "../styles/bootstrap.min.css";
import "../styles/line-awesome.min.css";
import "../styles/owl.carousel.min.css";
import "../styles/owl.theme.default.min.css";
import "../styles/animated-headline.css";
import "../styles/jquery-ui.css";
import "../styles/jquery.fancybox.css";
import "../styles/chosen.min.css";
import Image1 from "../images/img1.jpg";
import Loader from "../components/loader";
import { SEARCH_CITY } from "../apis/url_constants";
import { useNavigate } from "react-router-dom";

const PopularListings = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handleCity = async (val) => {
    setLoader(true);
    const res = `search?data=${val.toLowerCase()}`;
    const url = SEARCH_CITY + res;
    let data = await fetch(url);
    let parsedData = await data.json();
    if (parsedData) {
      setLoader(false);
      navigate("/listinggrid", { state: { text: val.toLowerCase() } });
      // setPosts(parsedData);
      // console.log(parsedData);
    }
  };

  const citiesArray = [
    "Bangalore",
    "Gurugram",
    "Mumbai",
    "Hyderabad",
    "Pune",
    "Delhi",
    "Noida",
    "Indore",
    "Chennai",
    "Kochi",
    "Chandigarh",
    "Jaipur",
    "Lucknow",
    "Ahmedabad",
    "Coimbatore",
    "Kolkata",
    "Goa",
    "Bhubaneswar",
    "Faridabad",
  ];

  return loader ? (
    <Loader />
  ) : (
    <div>
      <section className="category-area position-relative bg-gray section--padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading text-center">
                <div className="section-badge pb-3">
                  <span className="ribbon ribbon-2">Cities</span>
                </div>
                <h2 className="sec__title">Browse By Cities</h2>
                <p className="sec__desc">
                  What kind of city are you looking for? Maecenas quis consequat
                  libero, <br />a feugiat eros. Nunc ut lacinia tortors.
                </p>
              </div>
            </div>
          </div>
          <div className="row highlighted-categories justify-content-center">
            {citiesArray &&
              citiesArray.map((values, index) => {
                return (
                  <div key={index} className="col-lg-2 col-sm-6">
                    <div className="hero-category-item hero-category-item-layout-2">
                      <div
                        className="d-block hero-cat-link"
                        style={{ cursor: "pointer" }}
                        onClick={() => handleCity(values)}
                      >
                        <span
                          className={`icon-element bg-${values.length}  mx-auto`}
                        >
                          <i className="la la-city"></i>
                        </span>
                        {values}
                        {/* <span className="ribbon d-block mt-3 font-size-14 py-1">
                    10 Listings
                  </span> */}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <section className="card-area section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading text-center">
                <div className="section-badge pb-3">
                  <span className="ribbon ribbon-2 bg-2 text-white">
                    New Listing
                  </span>
                </div>
                <h2 className="sec__title">New Listings Added</h2>
                <p className="sec__desc">
                  Morbi convallis bibendum urna ut viverra. Maecenas quis
                  consequat libero, <br />a feugiat eros. Nunc ut lacinia
                  tortors.
                </p>
              </div>
            </div>
          </div>
          <div className="row padding-top-60px">
            <div className="col-lg-4 responsive-column">
              <div className="card-item">
                <div className="card-image">
                  <a href="listing-details.html" className="d-block">
                    <img
                      src={Image1}
                      data-src="images/img1.jpg"
                      className="card__img lazy"
                      alt=""
                    />
                  </a>
                </div>
                <div className="card-content">
                  <a
                    className="user-thumb d-inline-block"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="TechyDevs"
                  >
                    <img src={Image1} alt="author-img" />
                  </a>
                  <h4 className="card-title pt-3">
                    <a href="listing-details.html">
                      Roma’s Ristorante Italiano
                    </a>
                    <i
                      className="la la-check-circle ml-1"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Claimed"
                    ></i>
                  </h4>
                  <p className="card-sub">
                    <a>
                      <i className="la la-map-marker mr-1 text-color-2"></i>
                      Bishop Avenue, New York
                    </a>
                  </p>
                  <ul className="listing-meta d-flex align-items-center">
                    <li className="d-flex align-items-center">
                      <span className="rate flex-shrink-0">4.7</span>
                      <span className="rate-text">5 reviews</span>
                    </li>
                    <li>
                      <span
                        className="price-range"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Pricey"
                      >
                        <strong className="font-weight-medium">$</strong>
                        <strong className="font-weight-medium">$</strong>
                        <strong className="font-weight-medium">$</strong>
                      </span>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="la la-cutlery mr-1 listing-icon"></i>
                      <a className="listing-cat-link">Restaurant</a>
                    </li>
                  </ul>
                  <ul className="info-list padding-top-20px">
                    <li>
                      <span className="la la-calendar-check-o icon"></span>
                      Opened a few days ago
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 responsive-column">
              <div className="card-item">
                <div className="card-image">
                  <a href="listing-details.html" className="d-block">
                    <img
                      src={Image1}
                      data-src="images/img2.jpg"
                      className="card__img lazy"
                      alt=""
                    />
                  </a>
                </div>
                <div className="card-content">
                  <a
                    className="user-thumb d-inline-block"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="TechyDevs"
                  >
                    <img src={Image1} alt="author-img" />
                  </a>
                  <h4 className="card-title pt-3">
                    <a href="listing-details.html">
                      Dumpling Baby China Bistro
                    </a>
                  </h4>
                  <p className="card-sub">
                    <a>
                      <i className="la la-map-marker mr-1 text-color-2"></i>
                      Bishop Avenue, New York
                    </a>
                  </p>
                  <ul className="listing-meta d-flex align-items-center">
                    <li className="d-flex align-items-center">
                      <span className="rate flex-shrink-0">4.7</span>
                      <span className="rate-text">5 reviews</span>
                    </li>
                    <li>
                      <span
                        className="price-range"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Moderate"
                      >
                        <strong className="font-weight-medium">$</strong>
                        <strong className="font-weight-medium">$</strong>
                      </span>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="la la-cutlery mr-1 listing-icon"></i>
                      <a className="listing-cat-link">Restaurant</a>
                    </li>
                  </ul>
                  <ul className="info-list padding-top-20px">
                    <li>
                      <span className="la la-calendar-check-o icon"></span>
                      Opened 5 weeks ago
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 responsive-column">
              <div className="card-item">
                <div className="card-image">
                  <a href="listing-details.html" className="d-block">
                    <img
                      src={Image1}
                      data-src="images/img3.jpg"
                      className="card__img lazy"
                      alt=""
                    />
                  </a>
                </div>
                <div className="card-content">
                  <a
                    className="user-thumb d-inline-block"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="TechyDevs"
                  >
                    <img src={Image1} alt="author-img" />
                  </a>
                  <h4 className="card-title pt-3">
                    <a href="listing-details.html">Yerba Buena Fresh Mex</a>
                    <i
                      className="la la-check-circle ml-1"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Claimed"
                    ></i>
                  </h4>
                  <p className="card-sub">
                    <a>
                      <i className="la la-map-marker mr-1 text-color-2"></i>
                      Bishop Avenue, New York
                    </a>
                  </p>
                  <ul className="listing-meta d-flex align-items-center">
                    <li className="d-flex align-items-center">
                      <span className="rate flex-shrink-0">4.7</span>
                      <span className="rate-text">5 reviews</span>
                    </li>
                    <li>
                      <span
                        className="price-range"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Pricey"
                      >
                        <strong className="font-weight-medium">$</strong>
                        <strong className="font-weight-medium">$</strong>
                        <strong className="font-weight-medium">$</strong>
                      </span>
                    </li>
                    <li className="d-flex align-items-center">
                      <i className="la la-cutlery mr-1 listing-icon"></i>
                      <a className="listing-cat-link">Restaurant</a>
                    </li>
                  </ul>
                  <ul className="info-list padding-top-20px">
                    <li>
                      <span className="la la-calendar-check-o icon"></span>
                      Opened 3 weeks ago
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="more-btn-box pt-3 text-center">
            <a href="/listinggrid" className="theme-btn gradient-btn">
              <i className="la la-list-alt mr-1"></i>View More New Listings
            </a>
          </div>
        </div>
      </section>
      <div className="section-block"></div>
      <section className="card-area position-relative section-padding overflow-hidden">
        <div className="svg-bg gradient-bg">
          <svg
            className="h-auto"
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            viewBox="0 0 1200 415"
            preserveAspectRatio="none"
          >
            <defs>
              <linearGradient id="svg-gradient-3">
                <stop offset="15%" stopColor="#ffc9c4" />
                <stop offset="95%" stopColor="#53ad96" />
              </linearGradient>
            </defs>
            <path
              opacity="0.1"
              d="M9.09494702e-13,373.771254 C80.6666667,298.406109 159.333333,260.723537 236,260.723537 C421,260.723537 525,344.759008 670,273.729027 C789.088175,215.392262 940.369069,48.85905 1200,74.7885669 C1200,148.788567 1200,262.192378 1200,415 L1.0227342e-12,414.788567 L9.09494702e-13,373.771254 Z"
            ></path>
            <defs></defs>
          </svg>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="card-heading-content mt-0">
                <div className="section-heading">
                  <div className="section-badge pb-3">
                    <span className="ribbon ribbon-2 bg-2 text-white">
                      Featured Listing
                    </span>
                  </div>
                  <h2 className="sec__title">
                    Check out Our Featured Listings
                  </h2>
                  <p className="sec__desc">
                    Morbi convallis bibendum urna ut viverra. Maecenas quis
                    consequat libero, a feugiat eros. Nunc ut lacinia tortors.
                  </p>
                </div>
                <div className="more-btn-box pt-4">
                  <a
                    href="listing-grid.html"
                    className="theme-btn gradient-btn"
                  >
                    <i className="la la-list-alt mr-1"></i>View All Featured
                    Listings
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="card-carousel-3 owl-trigger-action">
                <div className="card-item border border-color">
                  <div className="card-image">
                    <a href="listing-details.html" className="d-block">
                      <img
                        src={Image1}
                        data-src="images/img4.jpg"
                        className="card__img lazy"
                        alt=""
                      />
                      <span className="badge">now open</span>
                    </a>
                    <span
                      className="bookmark-btn"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Save"
                    >
                      <i className="la la-bookmark"></i>
                    </span>
                  </div>
                  <div className="card-content">
                    <a
                      className="user-thumb d-inline-block"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="TechyDevs"
                    >
                      <img src={Image1} alt="author-img" />
                    </a>
                    <h4 className="card-title pt-3">
                      <a href="listing-details.html">
                        Favorite Place Food Bank
                      </a>
                      <i
                        className="la la-check-circle ml-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Claimed"
                      ></i>
                    </h4>
                    <p className="card-sub">
                      <a>
                        <i className="la la-map-marker mr-1 text-color-2"></i>
                        Bishop Avenue, New York
                      </a>
                    </p>
                    <ul className="listing-meta d-flex align-items-center">
                      <li className="d-flex align-items-center">
                        <span className="rate flex-shrink-0">4.7</span>
                        <span className="rate-text">5 Ratings</span>
                      </li>
                      <li>
                        <span
                          className="price-range"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Pricey"
                        >
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                        </span>
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="la la-cutlery mr-1 listing-icon"></i>
                        <a className="listing-cat-link">Restaurant</a>
                      </li>
                    </ul>
                    <ul className="info-list padding-top-20px">
                      <li>
                        <span className="la la-link icon"></span>
                        <a> www.techydevs.com</a>
                      </li>
                      <li>
                        <span className="la la-calendar-check-o icon"></span>
                        Opened 1 month ago
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-item border border-color">
                  <div className="card-image">
                    <a href="listing-details.html" className="d-block">
                      <img
                        src={Image1}
                        data-src={Image1}
                        className="card__img lazy"
                        alt=""
                      />
                      <span className="badge bg-10">closed</span>
                    </a>
                    <span
                      className="bookmark-btn"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Save"
                    >
                      <i className="la la-bookmark"></i>
                    </span>
                  </div>
                  <div className="card-content">
                    <a
                      className="user-thumb d-inline-block"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="TechyDevs"
                    >
                      <img src={Image1} alt="author-img" />
                    </a>
                    <h4 className="card-title pt-3">
                      <a href="listing-details.html">Beach Blue Boardwalk</a>
                    </h4>
                    <p className="card-sub">
                      <a>
                        <i className="la la-map-marker mr-1 text-color-2"></i>
                        Bishop Avenue, New York
                      </a>
                    </p>
                    <ul className="listing-meta d-flex align-items-center">
                      <li className="d-flex align-items-center">
                        <span className="rate flex-shrink-0">4.7</span>
                        <span className="rate-text">5 Ratings</span>
                      </li>
                      <li>
                        <span
                          className="price-range"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Moderate"
                        >
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                        </span>
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="la la-plane mr-1 listing-icon"></i>
                        <a className="listing-cat-link">Travel</a>
                      </li>
                    </ul>
                    <ul className="info-list padding-top-20px">
                      <li>
                        <span className="la la-link icon"></span>
                        <a> www.techydevs.com</a>
                      </li>
                      <li>
                        <span className="la la-calendar-check-o icon"></span>
                        Opened 1 month ago
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-item border border-color">
                  <div className="card-image">
                    <a href="listing-details.html" className="d-block">
                      <img
                        src={Image1}
                        data-src="images/img6.jpg"
                        className="card__img lazy"
                        alt=""
                      />
                      <span className="badge">now open</span>
                    </a>
                    <span
                      className="bookmark-btn"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Save"
                    >
                      <i className="la la-bookmark"></i>
                    </span>
                  </div>
                  <div className="card-content">
                    <a
                      className="user-thumb d-inline-block"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="TechyDevs"
                    >
                      <img src={Image1} alt="author-img" />
                    </a>
                    <h4 className="card-title pt-3">
                      <a href="listing-details.html">Kennedy Space Center</a>
                      <i
                        className="la la-check-circle ml-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Claimed"
                      ></i>
                    </h4>
                    <p className="card-sub">
                      <a>
                        <i className="la la-map-marker mr-1 text-color-2"></i>
                        Bishop Avenue, New York
                      </a>
                    </p>
                    <ul className="listing-meta d-flex align-items-center">
                      <li className="d-flex align-items-center">
                        <span className="rate flex-shrink-0">4.7</span>
                        <span className="rate-text">5 Ratings</span>
                      </li>
                      <li>
                        <span
                          className="price-range"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Pricey"
                        >
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                        </span>
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="la la-cutlery mr-1 listing-icon"></i>
                        <a className="listing-cat-link">Restaurant</a>
                      </li>
                    </ul>
                    <ul className="info-list padding-top-20px">
                      <li>
                        <span className="la la-link icon"></span>
                        <a> www.techydevs.com</a>
                      </li>
                      <li>
                        <span className="la la-calendar-check-o icon"></span>
                        Opened 1 month ago
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-item border border-color">
                  <div className="card-image">
                    <a href="listing-details.html" className="d-block">
                      <img
                        src={Image1}
                        data-src="images/img7.jpg"
                        className="card__img lazy"
                        alt=""
                      />
                      <span className="badge bg-10">closed</span>
                    </a>
                    <span
                      className="bookmark-btn"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Save"
                    >
                      <i className="la la-bookmark"></i>
                    </span>
                  </div>
                  <div className="card-content">
                    <a
                      className="user-thumb d-inline-block"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="TechyDevs"
                    >
                      <img src={Image1} alt="author-img" />
                    </a>
                    <h4 className="card-title pt-3">
                      <a href="listing-details.html">National Gallery of Art</a>
                    </h4>
                    <p className="card-sub">
                      <a>
                        <i className="la la-map-marker mr-1 text-color-2"></i>
                        Bishop Avenue, New York
                      </a>
                    </p>
                    <ul className="listing-meta d-flex align-items-center">
                      <li className="d-flex align-items-center">
                        <span className="rate flex-shrink-0">4.7</span>
                        <span className="rate-text">5 Ratings</span>
                      </li>
                      <li>
                        <span
                          className="price-range"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Moderate"
                        >
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                        </span>
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="la la-plane mr-1 listing-icon"></i>
                        <a className="listing-cat-link">Travel</a>
                      </li>
                    </ul>
                    <ul className="info-list padding-top-20px">
                      <li>
                        <span className="la la-link icon"></span>
                        <a> www.techydevs.com</a>
                      </li>
                      <li>
                        <span className="la la-calendar-check-o icon"></span>
                        Opened 1 month ago
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="card-area position-relative section-padding overflow-hidden">
        <div className="svg-bg svg-bg-3 gradient-bg-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 118"
            preserveAspectRatio="none"
          >
            <defs>
              <linearGradient id="svg-gradient-4">
                <stop offset="15%" stopColor="#F8F9FA" />
                <stop offset="95%" stopColor="#b9caf1" />
              </linearGradient>
            </defs>
            <path
              opacity="0.2"
              d="M0,112 C400.666667,36 800.666667,-0.666666667 1200,2 C1200,41.3333333 1200,80 1200,118 L0,118 L0,112 Z"
            ></path>
          </svg>
        </div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="card-carousel-3 owl-trigger-action">
                <div className="card-item border border-color">
                  <div className="card-image">
                    <a href="listing-details.html" className="d-block">
                      <img
                        src={Image1}
                        data-src="images/img4.jpg"
                        className="card__img lazy"
                        alt=""
                      />
                      <span className="badge">now open</span>
                    </a>
                    <span
                      className="bookmark-btn"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Save"
                    >
                      <i className="la la-bookmark"></i>
                    </span>
                  </div>
                  <div className="card-content">
                    <a
                      className="user-thumb d-inline-block"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="TechyDevs"
                    >
                      <img src={Image1} alt="author-img" />
                    </a>
                    <h4 className="card-title pt-3">
                      <a href="listing-details.html">
                        Favorite Place Food Bank
                      </a>
                      <i
                        className="la la-check-circle ml-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Claimed"
                      ></i>
                    </h4>
                    <p className="card-sub">
                      <a>
                        <i className="la la-map-marker mr-1 text-color-2"></i>
                        Bishop Avenue, New York
                      </a>
                    </p>
                    <ul className="listing-meta d-flex align-items-center">
                      <li className="d-flex align-items-center">
                        <span className="rate flex-shrink-0">4.7</span>
                        <span className="rate-text">5 Ratings</span>
                      </li>
                      <li>
                        <span
                          className="price-range"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Pricey"
                        >
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                        </span>
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="la la-cutlery mr-1 listing-icon"></i>
                        <a className="listing-cat-link">Restaurant</a>
                      </li>
                    </ul>
                    <ul className="info-list padding-top-20px">
                      <li>
                        <span className="la la-link icon"></span>
                        <a> www.techydevs.com</a>
                      </li>
                      <li>
                        <span className="la la-calendar-check-o icon"></span>
                        Opened 1 month ago
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-item border border-color">
                  <div className="card-image">
                    <a href="listing-details.html" className="d-block">
                      <img
                        src={Image1}
                        data-src="images/img5.jpg"
                        className="card__img lazy"
                        alt=""
                      />
                      <span className="badge bg-10">closed</span>
                    </a>
                    <span
                      className="bookmark-btn"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Save"
                    >
                      <i className="la la-bookmark"></i>
                    </span>
                  </div>
                  <div className="card-content">
                    <a
                      className="user-thumb d-inline-block"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="TechyDevs"
                    >
                      <img src={Image1} alt="author-img" />
                    </a>
                    <h4 className="card-title pt-3">
                      <a href="listing-details.html">Beach Blue Boardwalk</a>
                    </h4>
                    <p className="card-sub">
                      <a>
                        <i className="la la-map-marker mr-1 text-color-2"></i>
                        Bishop Avenue, New York
                      </a>
                    </p>
                    <ul className="listing-meta d-flex align-items-center">
                      <li className="d-flex align-items-center">
                        <span className="rate flex-shrink-0">4.7</span>
                        <span className="rate-text">5 Ratings</span>
                      </li>
                      <li>
                        <span
                          className="price-range"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Moderate"
                        >
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                        </span>
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="la la-plane mr-1 listing-icon"></i>
                        <a className="listing-cat-link">Travel</a>
                      </li>
                    </ul>
                    <ul className="info-list padding-top-20px">
                      <li>
                        <span className="la la-link icon"></span>
                        <a> www.techydevs.com</a>
                      </li>
                      <li>
                        <span className="la la-calendar-check-o icon"></span>
                        Opened 1 month ago
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-item border border-color">
                  <div className="card-image">
                    <a href="listing-details.html" className="d-block">
                      <img
                        src={Image1}
                        data-src="images/img6.jpg"
                        className="card__img lazy"
                        alt=""
                      />
                      <span className="badge">now open</span>
                    </a>
                    <span
                      className="bookmark-btn"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Save"
                    >
                      <i className="la la-bookmark"></i>
                    </span>
                  </div>
                  <div className="card-content">
                    <a
                      className="user-thumb d-inline-block"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="TechyDevs"
                    >
                      <img src={Image1} alt="author-img" />
                    </a>
                    <h4 className="card-title pt-3">
                      <a href="listing-details.html">Kennedy Space Center</a>
                      <i
                        className="la la-check-circle ml-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Claimed"
                      ></i>
                    </h4>
                    <p className="card-sub">
                      <a>
                        <i className="la la-map-marker mr-1 text-color-2"></i>
                        Bishop Avenue, New York
                      </a>
                    </p>
                    <ul className="listing-meta d-flex align-items-center">
                      <li className="d-flex align-items-center">
                        <span className="rate flex-shrink-0">4.7</span>
                        <span className="rate-text">5 Ratings</span>
                      </li>
                      <li>
                        <span
                          className="price-range"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Pricey"
                        >
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                        </span>
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="la la-cutlery mr-1 listing-icon"></i>
                        <a className="listing-cat-link">Restaurant</a>
                      </li>
                    </ul>
                    <ul className="info-list padding-top-20px">
                      <li>
                        <span className="la la-link icon"></span>
                        <a> www.techydevs.com</a>
                      </li>
                      <li>
                        <span className="la la-calendar-check-o icon"></span>
                        Opened 1 month ago
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-item border border-color">
                  <div className="card-image">
                    <a href="listing-details.html" className="d-block">
                      <img
                        src={Image1}
                        data-src="images/img7.jpg"
                        className="card__img lazy"
                        alt=""
                      />
                      <span className="badge bg-10">closed</span>
                    </a>
                    <span
                      className="bookmark-btn"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Save"
                    >
                      <i className="la la-bookmark"></i>
                    </span>
                  </div>
                  <div className="card-content">
                    <a
                      className="user-thumb d-inline-block"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="TechyDevs"
                    >
                      <img src={Image1} alt="author-img" />
                    </a>
                    <h4 className="card-title pt-3">
                      <a href="listing-details.html">National Gallery of Art</a>
                    </h4>
                    <p className="card-sub">
                      <a>
                        <i className="la la-map-marker mr-1 text-color-2"></i>
                        Bishop Avenue, New York
                      </a>
                    </p>
                    <ul className="listing-meta d-flex align-items-center">
                      <li className="d-flex align-items-center">
                        <span className="rate flex-shrink-0">4.7</span>
                        <span className="rate-text">5 Ratings</span>
                      </li>
                      <li>
                        <span
                          className="price-range"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Moderate"
                        >
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                        </span>
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="la la-plane mr-1 listing-icon"></i>
                        <a className="listing-cat-link">Travel</a>
                      </li>
                    </ul>
                    <ul className="info-list padding-top-20px">
                      <li>
                        <span className="la la-link icon"></span>
                        <a> www.techydevs.com</a>
                      </li>
                      <li>
                        <span className="la la-calendar-check-o icon"></span>
                        Opened 1 month ago
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card-heading-content mb-0">
                <div className="section-heading">
                  <div className="section-badge pb-3">
                    <span className="ribbon ribbon-2 bg-2 text-white">
                      Most Viewed Listing
                    </span>
                  </div>
                  <h2 className="sec__title">
                    Browse this Month's Most Viewed Listings
                  </h2>
                  <p className="sec__desc">
                    Morbi convallis bibendum urna ut viverra. Maecenas quis
                    consequat libero, a feugiat eros. Nunc ut lacinia tortors.
                  </p>
                </div>
                <div className="more-btn-box pt-4">
                  <a
                    href="listing-grid.html"
                    className="theme-btn gradient-btn"
                  >
                    <i className="la la-list-alt mr-1"></i>View All Most Viewed
                    Listings
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cta-area cta-bg bg-fixed section-padding text-center">
        <div className="overlay"></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="cta-content p-0">
                <div className="section-heading">
                  <div className="section-badge pb-3">
                    <span className="ribbon ribbon-2">
                      Best Local Business Site
                    </span>
                  </div>
                  <h2 className="sec__title mb-4 font-size-45 line-height-60 text-white">
                    Uniworkpass's Purpose is to Help People Find <br />
                    Great Local Businesses
                  </h2>
                  <p className="sec__desc text-white line-height-35 mb-3">
                    Grow your marketing and be happy with your business Morbi
                    convallis <br />
                    bibendum urna ut viverra. Maecenas quis consequat libero
                  </p>
                </div>
                <div className="btn-box pt-4">
                  <a
                    className="theme-btn gradient-btn shadow-none"
                    data-toggle="modal"
                    data-target="#signUpModal"
                  >
                    <i className="la la-user-plus mr-2"></i>I Want to Start
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pricing-area position-relative section--padding overflow-hidden">
        <div className="svg-bg svg-bg-3 gradient-bg-3">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 118"
            preserveAspectRatio="none"
          >
            <defs>
              <linearGradient>
                <stop offset="15%" stopColor="#F8F9FA" />
                <stop offset="95%" stopColor="#b9caf1" />
              </linearGradient>
            </defs>
            <path
              opacity="0.2"
              d="M0,112 C400.666667,36 800.666667,-0.666666667 1200,2 C1200,41.3333333 1200,80 1200,118 L0,118 L0,112 Z"
            ></path>
          </svg>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading text-center">
                <div className="section-badge pb-3">
                  <span className="ribbon ribbon-2 bg-2 text-white">
                    Best Pricing We Offered
                  </span>
                </div>
                <h2 className="sec__title">Post Your Listing Today!</h2>
                <p className="sec__desc">
                  Find the plan that’s right for you. Mark your listing as an
                  official.
                </p>
              </div>
            </div>
          </div>
          <div className="row padding-top-60px">
            <div className="col-lg-4 responsive-column">
              <div className="price-item">
                <div className="price-head bg-1">
                  <h3 className="price__title text-uppercase">Standard</h3>
                  <svg
                    className="svg-bg h-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 10"
                    preserveAspectRatio="none"
                  >
                    <path
                      fill="#fff"
                      d="M0 10 0 0 A 90 59, 0, 0, 0, 100 0 L 100 10 Z"
                    ></path>
                  </svg>
                </div>
                <div className="price-box d-flex flex-column align-items-center justify-content-center mx-auto text-color-3">
                  <h3 className="price__text text-color-3">Free</h3>
                  <span className="price__text-meta">/ per month</span>
                </div>
                <ul className="list-items price-list mt-4">
                  <li>
                    <i className="la la-check text-success mr-2"></i>One Listing
                  </li>
                  <li>
                    <i className="la la-check text-success mr-2"></i>10 Days
                    Availability
                  </li>
                  <li>
                    <i className="la la-check text-success mr-2"></i>2 Tags Per
                    Listing
                  </li>
                  <li>
                    <i className="la la-check text-success mr-2"></i>
                    Non-Featured
                  </li>
                  <li>
                    <i className="la la-check text-success mr-2"></i>Limited
                    Support
                  </li>
                  <li>
                    <i className="la la-close text-danger mr-2"></i>Average
                    Price Range
                  </li>
                  <li>
                    <i className="la la-close text-danger mr-2"></i>Business
                    Hours
                  </li>
                  <li>
                    <i className="la la-close text-danger mr-2"></i>Lifetime
                    Availability
                  </li>
                  <li>
                    <i className="la la-close text-danger mr-2"></i>Featured In
                    Search Results
                  </li>
                </ul>
                <div className="price-btn-box text-center">
                  <a
                    href="add-listing.html"
                    className="theme-btn bg-1 text-white"
                  >
                    Get Started <i className="la la-arrow-right ml-2"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 responsive-column">
              <div className="price-item">
                <div className="price-head bg-2">
                  <span className="ribbon ribbon-2">Most Popular</span>
                  <h3 className="price__title text-uppercase">Professional</h3>
                  <svg
                    className="svg-bg h-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 10"
                    preserveAspectRatio="none"
                  >
                    <path
                      fill="#fff"
                      d="M0 10 0 0 A 90 59, 0, 0, 0, 100 0 L 100 10 Z"
                    ></path>
                  </svg>
                </div>
                <div className="price-box d-flex flex-column align-items-center justify-content-center mx-auto text-color-4">
                  <h3 className="price__text text-color-4">$19.90</h3>
                  <span className="price__text-meta">/ per month</span>
                </div>
                <ul className="list-items price-list mt-4">
                  <li>
                    <i className="la la-check text-success mr-2"></i>Ten Listing
                  </li>
                  <li>
                    <i className="la la-check text-success mr-2"></i>30 Days
                    Availability
                  </li>
                  <li>
                    <i className="la la-check text-success mr-2"></i>5 Tags Per
                    Listing
                  </li>
                  <li>
                    <i className="la la-check text-success mr-2"></i>
                    Non-Featured
                  </li>
                  <li>
                    <i className="la la-check text-success mr-2"></i>Limited
                    Support
                  </li>
                  <li>
                    <i className="la la-close text-success mr-2"></i>Average
                    Price Range
                  </li>
                  <li>
                    <i className="la la-close text-success mr-2"></i>Business
                    Hours
                  </li>
                  <li>
                    <i className="la la-close text-danger mr-2"></i>Lifetime
                    Availability
                  </li>
                  <li>
                    <i className="la la-close text-danger mr-2"></i>Featured In
                    Search Results
                  </li>
                </ul>
                <div className="price-btn-box text-center">
                  <a
                    href="add-listing.html"
                    className="theme-btn bg-2 text-white"
                  >
                    Get Started <i className="la la-arrow-right ml-2"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 responsive-column">
              <div className="price-item">
                <div className="price-head bg-3">
                  <h3 className="price__title text-uppercase">Business</h3>
                  <svg
                    className="svg-bg h-auto"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 100 10"
                    preserveAspectRatio="none"
                  >
                    <path
                      fill="#fff"
                      d="M0 10 0 0 A 90 59, 0, 0, 0, 100 0 L 100 10 Z"
                    ></path>
                  </svg>
                </div>
                <div className="price-box d-flex flex-column align-items-center justify-content-center mx-auto text-color-5">
                  <h3 className="price__text text-color-5">$29.90 </h3>
                  <span className="price__text-meta">/ per month</span>
                </div>
                <ul className="list-items price-list mt-4">
                  <li>
                    <i className="la la-check text-success mr-2"></i>Twenty
                    Listing
                  </li>
                  <li>
                    <i className="la la-check text-success mr-2"></i>90 Days
                    Availability
                  </li>
                  <li>
                    <i className="la la-check text-success mr-2"></i>15 Tags Per
                    Listing
                  </li>
                  <li>
                    <i className="la la-check text-success mr-2"></i>
                    Non-Featured
                  </li>
                  <li>
                    <i className="la la-check text-success mr-2"></i>Limited
                    Support
                  </li>
                  <li>
                    <i className="la la-close text-success mr-2"></i>Average
                    Price Range
                  </li>
                  <li>
                    <i className="la la-close text-success mr-2"></i>Business
                    Hours
                  </li>
                  <li>
                    <i className="la la-close text-success mr-2"></i>Lifetime
                    Availability
                  </li>
                  <li>
                    <i className="la la-close text-success mr-2"></i>Featured In
                    Search Results
                  </li>
                </ul>
                <div className="price-btn-box text-center">
                  <a
                    href="add-listing.html"
                    className="theme-btn bg-3 text-white"
                  >
                    Get Started <i className="la la-arrow-right ml-2"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blog-area section--padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading text-center">
                <div className="section-badge pb-3">
                  <span className="ribbon ribbon-2 bg-2 text-white">
                    From Our Blog
                  </span>
                </div>
                <h2 className="sec__title">What's on Our Mind</h2>
                <p className="sec__desc">
                  Morbi convallis bibendum urna ut viverra. Maecenas quis
                  consequat libero, <br />a feugiat eros. Nunc ut lacinia
                  tortors.
                </p>
              </div>
            </div>
          </div>
          <div className="row padding-top-60px">
            <div className="col-lg-4 responsive-column">
              <div className="card-item card-item-layout-2">
                <div className="card-image">
                  <a href="blog-single.html" className="d-block">
                    <img
                      src={Image1}
                      data-src={Image1}
                      className="card__img lazy"
                      alt="blog image"
                    />
                    <span className="badge">January 15, 2020</span>
                  </a>
                  <div className="post-share">
                    <span className="la la-share-alt share-icon"></span>
                    <div className="post-share-social">
                      <a className="post-share-social-icon">
                        <i className="lab la-facebook-f"></i>
                      </a>
                      <a className="post-share-social-icon">
                        <i className="lab la-twitter"></i>
                      </a>
                      <a className="post-share-social-icon">
                        <i className="lab la-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-content">
                  <a
                    className="user-thumb d-inline-block"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Kamran Adi"
                  >
                    <img src={Image1} alt="author-img" />
                  </a>
                  <ul className="listing-meta d-flex justify-content-center align-items-center">
                    <li>
                      <a className="listing-cat-link">Travel</a>,
                      <a className="listing-cat-link">News</a>
                    </li>
                  </ul>
                  <h4 className="card-title pt-2">
                    <a href="blog-single.html">
                      Five Of Nature’s Swimming Pools
                    </a>
                  </h4>
                  <p className="card-sub mt-3">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem eaque ipsam
                  </p>
                  <ul className="listing-action d-flex justify-content-around align-items-center border-top border-top-color mt-4 pt-4">
                    <li className="pill">
                      <i className="la la-share mr-1"></i>275
                    </li>
                    <li className="pill">
                      <i className="la la-eye mr-1"></i>319
                    </li>
                    <li className="pill">
                      <i className="la la-comment mr-1"></i>24
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 responsive-column">
              <div className="card-item card-item-layout-2">
                <div className="card-image">
                  <a href="blog-single.html" className="d-block">
                    <img
                      src={Image1}
                      data-src={Image1}
                      className="card__img lazy"
                      alt="blog image"
                    />
                    <span className="badge">March 15, 2020</span>
                  </a>
                  <div className="post-share">
                    <span className="la la-share-alt share-icon"></span>
                    <div className="post-share-social">
                      <a className="post-share-social-icon">
                        <i className="lab la-facebook-f"></i>
                      </a>
                      <a className="post-share-social-icon">
                        <i className="lab la-twitter"></i>
                      </a>
                      <a className="post-share-social-icon">
                        <i className="lab la-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-content">
                  <a
                    className="user-thumb d-inline-block"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Kamran Adi"
                  >
                    <img src={Image1} alt="author-img" />
                  </a>
                  <ul className="listing-meta d-flex justify-content-center align-items-center">
                    <li>
                      <a className="listing-cat-link">BusinessOwners</a>,
                      <a className="listing-cat-link">Restaurants</a>
                    </li>
                  </ul>
                  <h4 className="card-title pt-2">
                    <a href="blog-single.html">
                      The joyless side of happy hour
                    </a>
                  </h4>
                  <p className="card-sub mt-3">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem eaque ipsam
                  </p>
                  <ul className="listing-action d-flex justify-content-around align-items-center border-top border-top-color mt-4 pt-4">
                    <li className="pill">
                      <i className="la la-share mr-1"></i>275
                    </li>
                    <li className="pill">
                      <i className="la la-eye mr-1"></i>319
                    </li>
                    <li className="pill">
                      <i className="la la-comment mr-1"></i>24
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 responsive-column">
              <div className="card-item card-item-layout-2">
                <div className="card-image">
                  <a href="blog-single.html" className="d-block">
                    <img
                      src={Image1}
                      data-src={Image1}
                      className="card__img lazy"
                      alt="blog image"
                    />
                    <span className="badge">July 15, 2020</span>
                  </a>
                  <div className="post-share">
                    <span className="la la-share-alt share-icon"></span>
                    <div className="post-share-social">
                      <a className="post-share-social-icon">
                        <i className="lab la-facebook-f"></i>
                      </a>
                      <a className="post-share-social-icon">
                        <i className="lab la-twitter"></i>
                      </a>
                      <a className="post-share-social-icon">
                        <i className="lab la-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-content">
                  <a
                    className="user-thumb d-inline-block"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Kamran Adi"
                  >
                    <img src={Image1} alt="author-img" />
                  </a>
                  <ul className="listing-meta d-flex justify-content-center align-items-center">
                    <li>
                      <a className="listing-cat-link">Tour</a>,
                      <a className="listing-cat-link">News</a>
                    </li>
                  </ul>
                  <h4 className="card-title pt-2">
                    <a href="blog-single.html">Top 5 Boat journey in london</a>
                  </h4>
                  <p className="card-sub mt-3">
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem eaque ipsam
                  </p>
                  <ul className="listing-action d-flex justify-content-around align-items-center border-top border-top-color mt-4 pt-4">
                    <li className="pill">
                      <i className="la la-share mr-1"></i>275
                    </li>
                    <li className="pill">
                      <i className="la la-eye mr-1"></i>319
                    </li>
                    <li className="pill">
                      <i className="la la-comment mr-1"></i>24
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PopularListings;
