import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUserDataAction } from "../../redux/actions/user_actions/user_data";
import { LOGIN_URL } from "../../apis/url_constants";
import { getUserDetails } from "../../utils/common";
import logo_white from "../../images/logo-white.png";
import LoginImg from "../../images/vectors/Login-Image.svg";
import LightImg from "../../images/vectors/lights-img.svg";
import axios from "axios";
import Loader from "../../components/loader";
// import styled from "@emotion/styled";
import Header from "../../components/header";

const Login = ({ toggleModal, closeModal }) => {
  const dispatch = useDispatch();
  const userData = getUserDetails();
  const [form_inputs, setFormInputs] = useState({});
  const [loading, setLoading] = useState(false);
  const close_btn = useRef();
  const history = useNavigate();

  useEffect(() => {
    document.getElementById("email").focus();
  }, []);

  //   const toggleReset = () => {
  //     toggleModal("reset");
  //     close_btn.current.click();
  //   };

  const handleLogin = async (email, password) => {
    const data = JSON.stringify({
      email: email,
      password: password,
    });

    const config = {
      method: "POST",
      url: LOGIN_URL,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    return axios(config).then().catch();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    handleLogin(form_inputs.email, form_inputs.password)
      .then((res) => {
        setLoading(false);
        localStorage.setItem("refresh", res.data.refresh);
        localStorage.setItem("access", res.data.access);
        sessionStorage.setItem("refresh", res.data.refresh);
        sessionStorage.setItem("access", res.data.access);
        toast.success("Welcome back!");
        // close_btn.current.click();

        // setFormInputs({
        //   email: "",
        //   password: "",
        // });
        // history("/", { replace: true });
        dispatch(
          getUserDataAction(res.data.access, () => {
            history("/", { replace: true });
          })
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data?.detail);
        console.log("Error in login");
      })
      .finally(() => {
        setLoading(false);
        // sessionStorage.setItem("Loader", true);
      });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "email") {
      value = value.toLowerCase();
    }
    setFormInputs({ ...form_inputs, [name]: value });
  };

  // return (
  return loading ? (
    <Loader />
  ) : (
    <>
      <section
        className="container-fluid"
        // style={{ backgroundColor: "#f7f8fb" }}
      >
        <div className="row">
          <Header />
        </div>
        {/* <div className="row"> */}
        {/* </div> */}
        <div className="row">
          <div className="col-lg-1"></div>
          <div className="col-lg-6 col-md-5 login-left-content">
            <img src={LightImg} width={170} />
            <div className="">
              <h4 className="mt-4">List Your Property With Uniwork.</h4>
              <ul className="custom-list-style">
                <li>Zero Brokerage</li>
                <li>Genuine leads and quick deals</li>
                <li>PAN India Network</li>
              </ul>
            </div>
            <img
              src={LoginImg}
              className="login-vector-image"
              width={300}
              height={350}
              style={{ margin: "-10rem 0 0 19rem" }}
            />
          </div>
          {/* <div className="col-lg-3 col-md-5 mt-5 py-5"></div> */}
          {/* <div className="col-lg-1 col-md-1"></div> */}
          <div className="col-lg-4 col-md-7 parent-form-div">
            <div className="form-div">
              <h5 className="mb-3" id="loginModalTitle">
                Hey, Welcome back!
              </h5>
              <form method="post" className="form-box" onSubmit={handleSubmit}>
                <div className="input-box">
                  <label className="label-text">Email</label>
                  <div className="form-group">
                    <span className="la la-user form-icon"></span>
                    <input
                      required
                      id="email"
                      className="form-control form-control-styled"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      value={form_inputs.email}
                      disabled={loading}
                      placeholder="Email address"
                    />
                  </div>
                </div>
                <div className="input-box">
                  <label className="label-text">Password</label>
                  <div className="form-group">
                    <span className="la la-lock form-icon"></span>
                    <input
                      className="form-control form-control-styled"
                      type="password"
                      name="password"
                      onChange={handleChange}
                      value={form_inputs.password}
                      disabled={loading}
                      placeholder="Enter password"
                    />
                  </div>
                </div>
                <div className="input-box d-flex align-items-center justify-content-between pb-4 user-action-meta">
                  <Link
                    to="/reset"
                    className="margin-bottom-10px lost-pass-btn font-size-14"
                  >
                    Lost Password?
                  </Link>
                </div>
                <div className="btn-box">
                  <button
                    type="submit"
                    className="theme-btn gradient-btn w-100"
                  >
                    <i className="la la-sign-in mr-1"></i> Login to Account
                  </button>
                  <br />
                  <br />
                  <p className="sub-text-box text-right pt-1 font-weight-medium font-size-14">
                    New to UniWork?{" "}
                    <NavLink
                      to="/register"
                      style={{ cursor: "pointer" }}
                      className="text-color-2 signup-btn"
                      // onClick={() => {
                      //   toggleModal("register");
                      //   close_btn.current.click();
                      // }}
                    >
                      Create account
                    </NavLink>
                  </p>
                </div>
              </form>
            </div>
          </div>
          {/* <div className="col-lg-1 col-md-auto"></div> */}
        </div>
      </section>
    </>
  );
};
export default Login;
