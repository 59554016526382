import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useDebounce } from "use-debounce";
import { checkUser } from "../../apis/user_apis/check_user";
import RegisterImg from "../../images/vectors/Register-Image.svg";
import { REGISTER_URL, SEND_OTP, VERIFY_OTP } from "../../apis/url_constants";
import LightImg from "../../images/vectors/lights-img.svg";
import Header from "../../components/header";

const Register = () => {
  const navigate = useNavigate();
  const [form_inputs, setFormInputs] = useState({});
  const [email, setEmail] = useState("");
  const [deb_email] = useDebounce(email, 2000);
  const [mobile, setMobile] = useState("");
  const [deb_mobile] = useDebounce(mobile, 2000);
  const [availability, setAvailability] = useState(true);
  const [submit_state, toggleSubmit] = useState(false);
  const [otp_mode, setOTPMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otp_loading, setOTPLoading] = useState(false);
  const [otp, setOTP] = useState();

  const handleSendOTP = async (mobile_number) => {
    const config = {
      method: "get",
      url: SEND_OTP + "?mobile_number=" + mobile_number,
    };

    return axios(config).then().catch();
  };

  const handleVerifyOTP = async (mobile_number, otp) => {
    const data = JSON.stringify({
      mobile_number: mobile_number.toString(),
      otp: otp.toString(),
    });

    const config = {
      method: "post",
      url: VERIFY_OTP,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    return axios(config).then().catch();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // setOTPLoading(false);
    setOTPMode(true);

    handleSendOTP(form_inputs.phone_number)
      .then((response) => {
        toast.success("OTP sent to " + form_inputs.phone_number);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleChange = (e) => {
    let { name, value } = e.target;
    if (name === "email") {
      value = value.toLowerCase();
    }
    setFormInputs({ ...form_inputs, [name]: value });
  };

  const checkAvailability = async (email, mobile) => {
    checkUser(email, mobile)
      .then((response) => {
        if (response.status === 200) {
          setAvailability(true);
        } else {
          setAvailability(false);
        }
      })
      .catch((error) => {
        // toast.error("Something went wrong !!");
        toast.error("Email already taken !!");
        console.log(error);
        setAvailability(false);
      });
  };

  const handleRegistration = async (data) => {
    let payload = JSON.stringify(data);
    const config = {
      url: `${REGISTER_URL}`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    return axios(config).then().catch();
  };

  const otpVerification = async () => {
    handleVerifyOTP(form_inputs.phone_number, otp)
      .then((res) => {
        toast.success(res.data.message);
        let structure = form_inputs;
        structure.phone_number = parseInt(form_inputs.phone_number);
        structure.is_vendor = form_inputs.is_vendor === "true" ? true : false;
        handleRegistration(structure)
          .then((res) => {
            toast.success(res.data.message);
            // close_button.current.click();
            // closeModal(false);
            setLoading(false);
            setOTPLoading(false);
            navigate("/");
          })
          .catch((err) => {
            toast.error(err.message);
            setLoading(false);
            setOTPLoading(false);
            setOTPMode(false);
          });
      })
      .catch((err) => {
        toast.error(err.data.message);
      });
  };

  useEffect(() => {
    if (deb_email !== "" || deb_mobile !== "") {
      checkAvailability(deb_email, deb_mobile);
    }
  }, [deb_email, deb_mobile]);

  return (
    <section
      className="container-fluid"
      //  style={{ backgroundColor: "#f7f8fb" }}
    >
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-lg-1"></div>
        <div className="col-lg-6 col-md-6 login-left-content">
          <img src={LightImg} width={170} />
          <div className="">
            <h4 className="mt-4">List Your Property With Uniwork.</h4>
            <ul className="custom-list-style">
              <li>Zero Brokerage</li>
              <li>Genuine leads and quick deals</li>
              <li>PAN India Network</li>
            </ul>
          </div>
          <img
            src={RegisterImg}
            width={300}
            height={350}
            className="login-vector-image"
            style={{ margin: "-10rem 0 0 19rem" }}
          />
        </div>
        <div className="col-lg-5 col-md-6 mt-4 signup-form-div">
          <div className="form-div" style={{ width: "80%" }}>
            <h5 className="modal-title" id="signUpModalTitle">
              Create your UniWork account
            </h5>
            {otp_mode ? (
              <div className="modal-body">
                <div className="input-box">
                  <label className="label-text">Enter OTP</label>
                  <div className="form-group">
                    {/* <span className="la la-user form-icon"></span> */}
                    <input
                      required
                      disabled={otp_loading}
                      className="form-control form-control-styled"
                      type="password"
                      minLength={6}
                      maxLength={6}
                      name="otp"
                      value={otp}
                      onChange={(e) => {
                        setOTP(e.target.value);
                      }}
                      placeholder="Enter your OTP"
                    />
                  </div>
                </div>
                <div className="btn-box">
                  <button
                    type="submit"
                    disabled={otp_loading}
                    onClick={async () => {
                      otpVerification();
                    }}
                    className="theme-btn gradient-btn w-100"
                    style={{
                      cursor: otp_loading ? "not-allowed" : "pointer",
                    }}
                  >
                    <i className="la la-user-plus mr-1"></i>{" "}
                    {otp_loading ? "Validating..." : "Submit OTP"}
                  </button>
                </div>
              </div>
            ) : (
              <form method="post" className="form-box" onSubmit={handleSubmit}>
                {/* <div className="modal-body" style={{ marginBottom: "1rem" }}> */}
                <div className="row">
                  <div className="col-lg-6">
                    <div className="input-box">
                      <label className="label-text">First Name</label>
                      <div className="form-group">
                        <span className="la la-user form-icon"></span>
                        <input
                          required
                          disabled={loading}
                          className="form-control form-control-styled"
                          type="text"
                          name="first_name"
                          value={form_inputs.first_name || ""}
                          onChange={handleChange}
                          placeholder="First Name"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-box">
                      <label className="label-text">Last Name</label>
                      <div className="form-group">
                        <span className="la la-user form-icon"></span>
                        <input
                          required
                          disabled={loading}
                          className="form-control form-control-styled"
                          type="text"
                          name="last_name"
                          value={form_inputs.last_name || ""}
                          onChange={handleChange}
                          placeholder="Last Name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="input-box">
                      <label className="label-text">Email</label>
                      <div className="form-group">
                        <span className="la la-envelope form-icon"></span>
                        <input
                          required
                          disabled={loading}
                          onInput={(e) => {
                            setEmail(e.target.value);
                          }}
                          style={
                            availability ? {} : { border: "1px solid red" }
                          }
                          className="form-control form-control-styled"
                          type="email"
                          name="email"
                          value={form_inputs.email || ""}
                          onChange={handleChange}
                          placeholder="Email address"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="input-box">
                      <label className="label-text">Phone Number</label>
                      <div className="form-group">
                        <span className="la la-phone form-icon"></span>
                        <input
                          required
                          disabled={loading}
                          className="form-control form-control-styled"
                          type="tel"
                          name="phone_number"
                          pattern="^[6-9]\d{9}$"
                          onInput={(e) => {
                            setMobile(e.target.value);
                          }}
                          style={
                            availability ? {} : { border: "1px solid red" }
                          }
                          value={form_inputs.phone_number || ""}
                          onChange={handleChange}
                          placeholder="Phone number"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-box">
                      <label className="label-text">Are you a vendor?</label>
                      <div className="form-group">
                        <span className="la la-user form-icon"></span>
                        <select
                          className="form-control form-control-styled"
                          name="is_vendor"
                          onChange={handleChange}
                          value={form_inputs.is_vendor || "false"}
                          required
                          disabled={loading}
                        >
                          <option value="false">No</option>
                          <option value="true">Yes</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="input-box">
                      <label className="label-text">Password</label>
                      <div className="form-group">
                        <span className="la la-lock form-icon"></span>
                        <input
                          className="form-control form-control-styled"
                          type="password"
                          name="password"
                          required
                          disabled={loading}
                          value={form_inputs.password || ""}
                          onChange={handleChange}
                          placeholder="Enter password"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="input-box">
                      <label className="label-text">Confirm Password</label>
                      <div className="form-group">
                        <span className="la la-lock form-icon"></span>
                        <input
                          className="form-control form-control-styled"
                          type="password"
                          name="confirm_password"
                          required
                          disabled={loading}
                          value={form_inputs.confirm_password || ""}
                          onChange={handleChange}
                          placeholder="Confirm password"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <small className="mt-n2" style={{ fontSize: "12px" }}>
                      Your password must be at least 6 characters long and must
                      contain letters, numbers and special characters. Cannot
                      contain whitespace.
                    </small>
                  </div>
                </div>
                <div className="input-box py-1 user-action-meta">
                  <div className="custom-checkbox">
                    <input
                      onChange={(e) => {
                        toggleSubmit(e.target.checked);
                      }}
                      type="checkbox"
                      name="agreeChb"
                      required
                      disabled={loading}
                      id="agreeChb"
                    />
                    <label for="agreeChb" className="font-size-14">
                      By signing up, you agree to our{" "}
                      {/* <a href="privacy-policy.html" className="text-color-2">
                        Privacy Policy.
                      </a> */}
                      <Link to="/privacy-policy.html" className="text-color-2">
                        Privacy Policy.
                      </Link>
                    </label>
                  </div>
                </div>
                <div className="btn-box">
                  <button
                    type="submit"
                    // disabled={!submit_state || !availability || loading}
                    className="theme-btn gradient-btn w-100 mb-1"
                    style={{
                      cursor:
                        !submit_state || loading ? "not-allowed" : "pointer",
                    }}
                  >
                    <i className="la la-user-plus mr-1"></i>{" "}
                    {loading ? "Registering..." : "Register Account"}
                  </button>
                  <p className="sub-text-box text-right pt-1 font-weight-medium font-size-14">
                    Already on UniWork?{" "}
                    <Link
                      to="/login"
                      className="text-color-2 login-btn"
                      // onClick={() => {
                      //   toggleModal("login");
                      //   close_button.current.click();
                      // }}
                    >
                      Log in
                    </Link>
                  </p>
                </div>
                {/* </div> */}
              </form>
            )}
          </div>
        </div>
        {/* <div className="col-lg-1"></div> */}
      </div>
    </section>
  );
};

export default Register;
