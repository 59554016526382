import { useLocation } from "react-router-dom";
import Footer from "../../components/footer";
import { MiniHeader } from "../../components/mini-header";
import { Card } from "../../components/card";
import { Box, Typography } from "@mui/material";
import { formatCurrency, getImageFromPath } from "../../utils/common";
import { useGetCities } from "../../apis/listing_apis/get_cities";
import { useEffect, useState } from "react";
import { Reviews } from "./reviews";
import { Booking } from "../../components/book-now/booking";
import { useBookingStore } from "../../states/use-booking-store";
import { bookingPrice } from "../../helpers/constants";

export const PriceDetails = () => {
  const { state } = useLocation();
  const selectedCatData = state?.subCatData;
  const { booking } = useBookingStore();
  const listing = booking?.venue;
  const [city, setCity] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useGetCities({
    onSuccess: (e) => {
      if (e.data.length > 0) {
        let structure = [];
        if (e?.data) {
          e.data.map((value) => {
            structure.push({
              label: value?.name,
              value: value?.id,
              state: value?.state__name,
            });
          });
        }
        let obj = structure.find((city) => listing.city_id === city.value);
        setCity(`${obj?.label}, ${obj?.state}`);
      }
    },
    enabled: !!listing,
  });

  console.log("booking", booking);

  return (
    <>
      <MiniHeader />
      <div className="container py-3">
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "2fr 1fr",
            gap: "20px",
          }}
        >
          <div>
            {selectedCatData ? (
              <Card title={"Booking Info"}>
                <Booking listing={selectedCatData} />
              </Card>
            ) : null}
          </div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                objectFit: "contain",
                borderRadius: "8px",
                overflow: "hidden",
                background: "white",
                boxShadow: "0 0 40px rgb(82 85 90 / 10%)",
              }}
            >
              <img
                src={getImageFromPath(listing?.images?.[0].path)}
                style={{
                  width: "100%",
                  height: "250px",
                }}
                alt="property"
              />
              <Box
                sx={{
                  px: 5,
                  py: 2,
                }}
              >
                <Typography variant="h6">{listing.name}</Typography>
                <span>
                  <i className="la la-map-marker mr-1 text-color-2" />
                  {city}
                </span>
                <Reviews />
              </Box>
            </Box>
            <Box>
              <Card title={"Prices"}>
                <Box>
                  {Object.keys(bookingPrice).map((price) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        key={price}
                      >
                        <span>{bookingPrice[price]} </span>
                        <span>{formatCurrency(selectedCatData[price])}</span>
                      </Box>
                    );
                  })}
                </Box>
              </Card>
            </Box>
          </Box>
        </Box>
      </div>
      <Footer />
    </>
  );
};
