import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const options = {
  responsive: true,
  bezierCurve: true,
  plugins: {
    legend: {
      position: 'top',
      display: false,
    },
    // title: {
    //   display: true,
    //   text: 'Chart.js Line Chart',
    // },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      data: [12, 19, 3, 5, 2, 3, 34],
      lineTension: 0.3,
      fill: true,
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      fill: true,
      lineTension: 0.3,
      data: [19, 11, 5, 34, 12, 55, 34],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

const LineChart = () => {
  return (
    <div className="block-card dashboard-card mb-4">
      <div className="block-card-header d-flex flex-wrap align-items-center justify-content-between">
        <div id="myChartLegend">
          <ul className="0-legend d-flex align-items-center">
            <li className="btn-gray mr-2">
              <span></span>Listings Views
            </li>
            <li className="btn-gray">
              <span></span>Bookings
            </li>
          </ul>
        </div>
        <div className="user-chosen-select-container">
          <select className="user-chosen-select">
            <option value="1">Today</option>
            <option value="2">Week</option>
            <option value="3" selected>
              Month
            </option>
            <option value="4">This Year</option>
          </select>
        </div>
      </div>
      <div className="block-card-body">
        <div className="chart-block">
          {/* <canvas id="lineChart"></canvas> */}
          <Line options={options} data={data} />
        </div>
      </div>
    </div>
  );
};

export default LineChart;
