import * as actionTypes from "../../actions/actionTypes";

let initial_state = {
  data: null,
  loading: null,
  error: null,
};

const getUserDetailsReducer = (state = initial_state, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_DETAILS_START:
      return {
        ...state,
      };
    case actionTypes.GET_USER_DETAILS_FAIL:
      return {
        ...state,
        data: null,
        loading: false,
        error: action.payload,
      };
    case actionTypes.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export default getUserDetailsReducer;
