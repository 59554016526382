import React from "react";
import { Navigate } from "react-router-dom";
import { getUserDetails } from "../utils/common";

const ProtectedRoute = ({ component, isVendor = true }) => {
  const userDetails = getUserDetails();

  if (userDetails) {
    if (isVendor && !userDetails.is_vendor) {
      return <Navigate to="/" />;
    }
    return component;
  }
  return <Navigate to="/" />;
};

export default ProtectedRoute;
