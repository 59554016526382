import React from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import { keyCodes } from '../../helpers/constants';
import './style.css';

const TagInput = ({ list_of_tags, setListOfTags }) => {
  const delimiters = [keyCodes.comma, keyCodes.enter];
  const handleDelete = (i) => {
    setListOfTags(list_of_tags.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    setListOfTags([...list_of_tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newcategories = list_of_tags.slice();

    newcategories.splice(currPos, 1);
    newcategories.splice(newPos, 0, tag);

    // re-render
    setListOfTags(newcategories);
  };

  const handleTagClick = (index) => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  return (
    <ReactTags
      classNames={{
        tags: 'tagsClass',
        tagInput: 'tagInputClass',
        tagInputField: 'tagInput form-control',
        selected: 'selectedClass',
        tag: 'tag',
        remove: 'delete-button',
        suggestions: 'suggestionsClass',
        activeSuggestion: 'activeSuggestionClass',
        editTagInput: 'editTagInputClass',
        editTagInputField: 'editTagInputField',
        clearAll: 'clearAllClass',
      }}
      placeholder="Enter a Keywords"
      tags={list_of_tags}
      delimiters={delimiters}
      handleDelete={handleDelete}
      handleAddition={handleAddition}
      handleDrag={handleDrag}
      handleTagClick={handleTagClick}
      inputFieldPosition="bottom"
      autocomplete
    />
  );
};

export default TagInput;
