/* eslint-disable no-useless-computed-key */
import { LoadingButton } from "@mui/lab";
import { Card } from "../card";
import { Controller, useForm } from "react-hook-form";
import { Box, colors } from "@mui/material";
import Cleave from "cleave.js/react";
import { useState } from "react";
import { getUserDetails } from "../../utils/common";
import { useBookingStore } from "../../states/use-booking-store";
import { useCheckout } from "../../lib/use-checkout";

export const CardInfo = () => {
  const [cardType, setCardType] = useState();
  const { control, handleSubmit } = useForm();
  const { booking } = useBookingStore();
  const info = booking?.info;
  const userData = getUserDetails();
  const { createBooking, creatingBooking, isLoading } = useCheckout();

  const handlePayment = (data) => {
    const [month, year] = data.expiry.split("/");
    createBooking(
      {
        user_id: userData.id,
        venue: booking.venue.id,
        vendor_id: booking.venue.user_id_id,
        guests: info?.guests,
        full_price: info?.full_price,
        start_date: info?.start_date,
        end_date: info?.end_date,
      },
      {
        methodType: "card",
        method: {
          "card[name]": data.nameOnCard,
          "card[number]": data.card,
          "card[cvv]": data.cvv,
          "card[expiry_month]": month,
          "card[expiry_year]": year,
        },
      }
    );
  };

  return (
    <Card title={"Select payment"}>
      <form onSubmit={handleSubmit(handlePayment)}>
        <div className="block-card-body">
          <div className="payment-option-wrap">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <Controller
                  name="card"
                  control={control}
                  render={({ field: { onChange, name } }) => (
                    <Cleave
                      className="form-control form-control-styled"
                      placeholder="Card Number"
                      options={{
                        creditCard: true,
                        onCreditCardTypeChanged: (e) => {
                          setCardType(e?.toUpperCase());
                        },
                      }}
                      name={name}
                      onChange={onChange}
                    />
                  )}
                />
                <Box
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 6,
                    color: colors.grey[300],
                  }}
                >
                  {cardType}
                </Box>
              </Box>
              <Controller
                name="cvv"
                control={control}
                render={({ field: { onChange, name } }) => (
                  <Cleave
                    className="form-control form-control-styled"
                    placeholder="CVV"
                    options={{
                      blocks: [3],
                    }}
                    name={name}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name="expiry"
                control={control}
                render={({ field: { onChange, name } }) => (
                  <Cleave
                    className="form-control form-control-styled"
                    placeholder="MM/YY"
                    options={{
                      date: true,
                      datePattern: ["m", "y"],
                    }}
                    onChange={onChange}
                    name={name}
                  />
                )}
              />
              <Controller
                name="nameOnCard"
                control={control}
                render={({ field: { onChange, name } }) => (
                  <input
                    className="form-control form-control-styled"
                    placeholder="Name on card"
                    onChange={onChange}
                    name={name}
                  />
                )}
              />
            </Box>
          </div>
          <div className="btn-box pt-3">
            <LoadingButton
              variant="outlined"
              type="submit"
              loading={creatingBooking}
              disabled={isLoading}
              className="theme-btn gradient-btn border-0 text-white"
            >
              Proceed to pay
            </LoadingButton>
          </div>
        </div>
      </form>
    </Card>
  );
};
