export const Card = ({ title, children, sideAction = null }) => {
  return (
    <div className="block-card mb-4">
      <div className="block-card-header d-flex justify-content-between">
        <div>
          <h2 className="widget-title">{title}</h2>
          <div className="stroke-shape"></div>
        </div>
        <div>{sideAction}</div>
      </div>
      <div className="block-card-body">{children}</div>
    </div>
  );
};
