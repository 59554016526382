import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialState = {
  step: 0,
  venue: null,
  subCat: null,
  pricing: null,
  info: null,
};

export const useBookingStore = create(
  persist(
    (set) => ({
      booking: initialState,
      setVenue: (venue, subCat) =>
        set((s) => ({ booking: { ...s.booking, step: 1, venue: venue, subCat } })),
      setSubCategory: (subCat) =>
        set((s) => ({
          booking: { ...s.booking, step: 2, subCat: subCat },
        })),
      setPricing: (pricing) =>
        set((s) => ({ booking: { ...s.booking, step: 3, pricing: pricing } })),
      setInfo: (value) =>
        set((s) => ({
          booking: {
            ...s.booking,
            info: value,
          },
        })),
      clearBookingStore: () => set(() => ({ booking: initialState })),
    }),
    {
      name: "booking",
    }
  )
);
