import axios from "axios";
import { MY_BOOKINGS } from "../url_constants";
import { useQuery } from "@tanstack/react-query";

const getMyBookings = async (id) => {
  const result = await axios.get(`${MY_BOOKINGS}?id=${id}`);
  return result.data;
};

export const useGetMyBooking = (id, options) => {
  return useQuery({
    queryFn: () => getMyBookings(id),
    queryKey: ["my-booking", id],
    ...options,
  });
};
