import { Box, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import FormInput from '../FormInput';
import DeleteIcon from '@mui/icons-material/Delete';

const SubCategoryDetails = ({
  subCategory,
  setSubCategories,
  editMode = false,
  onDelete,
}) => {
  const [inputValue, setInputValue] = useState(subCategory.value);
  const [subCatDetails, setSubCatDetails] = useState({});
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(false);
  const [editRow, setEditRow] = useState(false);
  const dropdownOptions = [
    { value: true, label: 'YES' },
    { value: false, label: 'NO' },
  ];
  const addBtnDisabled =
    !inputValue || +subCategory?.value === subCategory?.data?.length;
  const modalStyle = {
    position: 'absolute',
    padding: '2rem',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: '#ffffff',
    // border: '2px solid #b2afaf',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
  };

  const debounce = (fn, query) => {
    const getData = setTimeout(() => {
      fn(query);
    }, 500);
    return getData;
  };

  const updateInputValue = (value) => {
    setSubCategories((prev) => {
      return prev.map((item) => {
        if (item.subcat_id === subCategory.subcat_id) {
          return { ...item, value };
        }
        return item;
      });
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubCatDetails({ ...subCatDetails, [name]: +value });
  };

  const handleDropdownOptionChange = (name, value) => {
    setSubCatDetails({ ...subCatDetails, [name]: value });
  };

  const submitDetails = () => {
    const {
      room,
      projector,
      conf_call,
      white_board,
      ac,
      television,
      hourly_rate,
      weekly_rate,
      full_day_rate,
      half_day_rate,
    } = subCatDetails;
    if (
      projector === undefined ||
      conf_call === undefined ||
      white_board === undefined ||
      ac === undefined ||
      television === undefined ||
      !hourly_rate ||
      !weekly_rate ||
      !full_day_rate ||
      !half_day_rate
    ) {
      setError(true);
      return;
    }

    setSubCategories((prev) => {
      return prev.map((item) => {
        if (item.subcat_id === subCategory.subcat_id) {
          const newItem = {
            ...item,
            data: [
              ...item.data,
              { ...subCatDetails, id: Date.now(), db: false },
            ],
          };
          setSubCatDetails({});
          return newItem;
        }
        return item;
      });
    });
    setOpen(false);
  };

  const submitEditDetails = () => {
    const { room, hourly_rate, weekly_rate, full_day_rate, half_day_rate } =
      subCatDetails;
    if (
      !room ||
      !hourly_rate ||
      !weekly_rate ||
      !full_day_rate ||
      !half_day_rate
    ) {
      setError(true);
      return;
    }

    setSubCategories((prev) => {
      const updateList = prev.map((item) => {
        if (item.subcat_id === subCategory.subcat_id) {
          const data = item.data;
          const updatedData = data.map((row) => {
            if (row.id === subCatDetails.id) {
              return subCatDetails;
            }
            return row;
          });
          return { ...item, data: [...updatedData] };
        }
        return item;
      });
      return updateList;
    });
    setOpen(false);
    setEditRow(false);
  };

  useEffect(() => {
    const id = debounce(updateInputValue, inputValue);

    return () => clearTimeout(id);
  }, [inputValue]);

  return (
    <>
      <Box
        mb={3}
        style={{
          width: '100%',
          // border: '1px solid #b4acac',
          borderRadius: '5px',
          boxShadow: '4px 4px 2px 1px #7a737333',
        }}
      >
        <div className="my-2 mx-5">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Typography variant="h6">{subCategory.subCategory}</Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <input
                type="number"
                className="form-control mr-3"
                style={{ padding: 10, height: '2.40rem' }}
                value={inputValue}
                defaultValue={subCategory?.data?.length}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <button
                className="theme-btn gradient-btn border-0"
                type="button"
                style={{
                  lineHeight: 0,
                  opacity: `${addBtnDisabled ? 0.5 : 1}`,
                }}
                disabled={addBtnDisabled}
                onClick={() => setOpen(true)}
              >
                Add
              </button>
            </div>
          </div>
          <div className="form-box table-responsive mt-3">
            <table className="table time-list mb-0">
              <thead>
                <tr>
                  <td>Room</td>
                  <td>Projector</td>
                  <td>Conf. Call</td>
                  <td>White Board</td>
                  <td>AC</td>
                  <td>Television</td>
                  <td>Hourly Rate</td>
                  <td>Half-day Rate</td>
                  <td>Full-day Rate</td>
                  <td>Weekly Rate</td>
                  {(onDelete || editMode) && <td>Actions</td>}
                </tr>
              </thead>
              <tbody>
                {subCategory.data &&
                  !!subCategory.data?.length &&
                  subCategory.data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.room}</td>
                      <td>{item.projector ? 'Yes' : 'No'}</td>
                      <td>{item.conf_call ? 'Yes' : 'No'}</td>
                      <td>{item.white_board ? 'Yes' : 'No'}</td>
                      <td>{item.ac ? 'Yes' : 'No'}</td>
                      <td>{item.television ? 'Yes' : 'No'}</td>
                      <td>{item.hourly_rate}</td>
                      <td>{item.half_day_rate}</td>
                      <td>{item.full_day_rate}</td>
                      <td>{item.weekly_rate}</td>
                      {(onDelete || editMode) && (
                        <td>
                          <div
                            style={{
                              display: 'flex',
                              alignContent: 'space-around',
                            }}
                          >
                            {editMode && (
                              <span
                                title="Edit"
                                style={{ cursor: 'pointer', marginRight: 10 }}
                                onClick={() => {
                                  setSubCatDetails(item);
                                  setEditRow(true);
                                }}
                              >
                                <DriveFileRenameOutlineIcon />
                              </span>
                            )}
                            {onDelete && (
                              <span
                                title="Delete"
                                style={{ cursor: 'pointer' }}
                                onClick={() => onDelete(item)}
                              >
                                <DeleteIcon />
                              </span>
                            )}
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </Box>

      <Modal
        open={open || editRow}
        onClose={() => {
          setEditRow(false);
          setOpen(false);
          setSubCatDetails({});
          setError(false);
        }}
        disableScrollLock={false}
      >
        <Box style={modalStyle} sx={{ overflow: 'scroll', maxHeight: '90%' }}>
          <FormInput
            label="Room"
            type="number"
            name="room"
            onChange={handleChange}
            value={subCatDetails?.room}
          />
          <FormInput
            label="Seats"
            type="number"
            name="seats"
            onChange={handleChange}
            value={subCatDetails?.seats}
          />
          <div className="input-box">
            <label className="label-text">Projector</label>
            <div className="form-group user-chosen-select-container">
              <Select
                defaultValue={
                  editRow && {
                    value: subCatDetails?.projector,
                    label: subCatDetails?.projector ? 'YES' : 'NO',
                  }
                }
                options={dropdownOptions}
                name="projector"
                onChange={({ value }) => {
                  handleDropdownOptionChange('projector', value);
                }}
              />
            </div>
          </div>
          <div className="input-box">
            <label className="label-text">Conference Call</label>
            <div className="form-group user-chosen-select-container">
              <Select
                defaultValue={
                  editRow && {
                    value: subCatDetails?.conf_call,
                    label: subCatDetails?.conf_call ? 'YES' : 'NO',
                  }
                }
                options={dropdownOptions}
                name="conf_call"
                onChange={({ value }) => {
                  handleDropdownOptionChange('conf_call', value);
                }}
              />
            </div>
          </div>
          <div className="input-box">
            <label className="label-text">White Board</label>
            <div className="form-group user-chosen-select-container">
              <Select
                defaultValue={
                  editRow && {
                    value: subCatDetails?.white_board,
                    label: subCatDetails?.white_board ? 'YES' : 'NO',
                  }
                }
                options={dropdownOptions}
                name="white_board"
                onChange={({ value }) => {
                  handleDropdownOptionChange('white_board', value);
                }}
              />
            </div>
          </div>
          <div className="input-box">
            <label className="label-text">A.C.</label>
            <div className="form-group user-chosen-select-container">
              <Select
                defaultValue={
                  editRow && {
                    value: subCatDetails?.ac,
                    label: subCatDetails?.ac ? 'YES' : 'NO',
                  }
                }
                options={dropdownOptions}
                name="ac"
                onChange={({ value }) => {
                  handleDropdownOptionChange('ac', value);
                }}
              />
            </div>
          </div>
          <div className="input-box">
            <label className="label-text">Television</label>
            <div className="form-group user-chosen-select-container">
              <Select
                defaultValue={
                  editRow && {
                    value: subCatDetails?.television,
                    label: subCatDetails?.television ? 'YES' : 'NO',
                  }
                }
                options={dropdownOptions}
                name="television"
                onChange={({ value }) => {
                  handleDropdownOptionChange('television', value);
                }}
              />
            </div>
          </div>
          <FormInput
            label="Hourly Rate"
            type="number"
            name="hourly_rate"
            onChange={handleChange}
            value={subCatDetails?.hourly_rate}
          />
          <FormInput
            label="Half-Day Rate"
            type="number"
            name="half_day_rate"
            onChange={handleChange}
            value={subCatDetails?.half_day_rate}
          />
          <FormInput
            label="Full Day Rate"
            type="number"
            name="full_day_rate"
            onChange={handleChange}
            value={subCatDetails?.full_day_rate}
          />
          <FormInput
            label="Weekly Rate"
            type="number"
            name="weekly_rate"
            onChange={handleChange}
            value={subCatDetails?.weekly_rate}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            {error && (
              <Typography mb={4} variant="h9" sx={{ color: '#e20000' }}>
                All fields are mandatory!!
              </Typography>
            )}
            <button
              className="theme-btn gradient-btn border-0"
              type="button"
              disabled={subCategory?.value === subCategory?.data?.length}
              onClick={() => (!editRow ? submitDetails() : submitEditDetails())}
            >
              Add
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default SubCategoryDetails;
