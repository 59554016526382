import React, { useState } from 'react';
import Eye from '../../images/eye.svg';

const FormInput = ({
  handleChange,
  value,
  label,
  className = '',
  type,
  top,
  right,
  ...restProps
}) => {
  const [inputType, setInputType] = useState(type);

  return (
    <div className="input-box">
      {label && <label className="label-text">{label}</label>}
      <div className="form-group">
        <input
          className={`form-control ${className}`}
          type={inputType}
          onChange={handleChange}
          value={value}
          {...restProps}
        />
      </div>
      {type === 'password' && (
        <span
          style={{
            position: 'absolute',
            right: right,
            top: top,
            cursor: 'pointer',
          }}
          onClick={() => {
            setInputType(inputType === 'text' ? 'password' : 'text');
          }}
        >
          <img width="20" height="20" src={Eye} alt="Eye" />
        </span>
      )}
    </div>
  );
};

export default FormInput;
