/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate } from "react-router-dom";
import { useConfirmBooking } from "../apis/booking/use-confirm-booking";
import { MiniHeader } from "../components/mini-header";
import { useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";

export const BookingConfirmation = () => {
  const { mutate, isLoading, isError, data } = useConfirmBooking();
  const params = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (params) {
      const ids = new URLSearchParams(window.location.search);
      const order_id = ids.get("oi");
      const payment_id = ids.get("pi");
      const signature = ids.get("s");
      mutate({
        razorpay_order_id: order_id,
        razorpay_payment_id: payment_id,
        razorpay_signature: signature,
      });
    }
  }, [params]);

  const goToInvoice = () => {
    if (data?.invoice_id) {
      navigate(`/invoice`, {
        state: {
          invoiceData: data,
        },
      });
    }
  };

  return (
    <>
      <MiniHeader />
      <section className="booking-confirm-area section-padding mt-5">
        <span className="circle-bg circle-bg-1 position-absolute"></span>
        <span className="circle-bg circle-bg-2 position-absolute"></span>
        <span className="circle-bg circle-bg-3 position-absolute"></span>
        <span className="circle-bg circle-bg-4 position-absolute"></span>
        <span className="circle-bg circle-bg-5 position-absolute"></span>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="booking-confirm-content text-center">
                {isLoading || !params ? (
                  <Box>
                    <CircularProgress />
                  </Box>
                ) : isError ? (
                  <>
                    <i className="la la-close font-size-90 text-error"></i>
                    <div className="section-heading pt-3">
                      <h2 className="sec__title mb-2">Payment Failed.</h2>
                      <p className="sec__desc">
                        Your money should be refunded in 3 working days.
                      </p>
                      <div className="btn-box padding-top-30px">
                        <a href="/" className="btn-gray btn-gray-lg px-4">
                          Home <i className="la la-arrow-right ml-1"></i>
                        </a>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <i className="la la-check-circle-o font-size-90 text-success"></i>
                    <div className="section-heading pt-3">
                      <h2 className="sec__title mb-2">
                        Thank you. Your reservation <br /> has been received.
                      </h2>
                      <p className="sec__desc">
                        You'll receive a confirmation email at{" "}
                        <span>mail@example.com</span>
                      </p>
                      <div className="btn-box padding-top-30px">
                        <a href="/" className="btn-gray btn-gray-lg px-4">
                          Home <i className="la la-arrow-right ml-1"></i>
                        </a>
                      </div>
                      <div className="btn-box padding-top-30px">
                        <button
                          type={"button"}
                          onClick={goToInvoice}
                          className="btn-gray btn-gray-lg px-4"
                        >
                          Invoice <i className="la la-arrow-right ml-1"></i>
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
