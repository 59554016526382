import axios from "axios";
import { CHECK_USER } from "../url_constants";

export const checkUser = (email = "", mobile_number = "") => {
  let formattedURL = `${CHECK_USER}`;
  if (email.length > 0 && mobile_number.length < 1) {
    formattedURL += `?email=${email}`;
  } else if (email.length < 1 && mobile_number.length > 0) {
    formattedURL += `?mobile_number=${mobile_number}`;
  } else if (email.length > 0 && mobile_number.length > 0) {
    formattedURL += `?email=${email}&mobile_number=${mobile_number}`;
  }
  const config = {
    url: `${formattedURL}`,
    method: "GET",
  };
  return axios(config).then().catch();
};
