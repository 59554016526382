import React from "react";
import { Link, useNavigate } from "react-router-dom";
import author_image from "../images/avatar-img.jpg";
import { getUserDetails } from "../utils/common";
import { logoutUser } from "../apis/user_apis/logout";
import { toast } from "react-toastify";
import { BASE_URL } from "../apis/url_constants";

const NavbarAdminWrapper = ({ children }) => {
  const userData = getUserDetails();
  const history = useNavigate();
  const imageUrl = `${BASE_URL}${userData.profileimage}`;

  const handleLogout = async () => {
    try {
      logoutUser(sessionStorage?.access)
        .then((res) => {
          if (res.status === 200) {
            console.log("logged out!");
          } else {
            console.log("Error logging out: ", res);
          }
        })
        .catch((e) => {
          toast.error("Something went wrong !!");
          console.log("Error logging out: ", e);
        });
    } catch (e) {
      toast.error("Something went wrong !!");
      console.log("Error logging out: ", e);
    } finally {
      localStorage.clear();
      sessionStorage.clear();
      history("/", { replace: true }); //* Takes you back to home page in any case
    }
  };

  return (
    <div className="dashboard-body d-flex flex-column">
      <div className="dashboard-inner-body flex-grow-1">
        <nav className="navbar navbar-expand bg-navbar dashboard-topbar mb-4">
          <button id="sidebarToggleTop" className="btn rounded-circle mr-3">
            <i className="la la-bars"></i>
          </button>
          <ul className="navbar-nav ml-auto">
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle after-none"
                id="searchDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="la la-search"></i>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right p-3 animated--grow-in"
                aria-labelledby="searchDropdown"
              >
                <form className="search-box">
                  <div className="input-group">
                    <label className="input-label mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                      />
                    </label>
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="button">
                        <i className="la la-search"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle after-none"
                id="alertsDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="la la-bell"></i>
                <span className="badge badge-danger badge-counter">3+</span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right animated--grow-in"
                aria-labelledby="alertsDropdown"
              >
                <h6 className="generic-list-header">Alerts Center</h6>
                <div className="generic-list scrollable-content scrollbar-hidden">
                  <a className="generic-list-item d-flex align-items-center">
                    <div className="icon-element flex-shrink-0 bg-1">
                      <i className="la la-file-alt text-white"></i>
                    </div>
                    <div className="ml-2">
                      <p className="small text-gray">December 12, 2019</p>
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        A new monthly report is ready to download!
                      </p>
                    </div>
                  </a>
                  <a className="generic-list-item d-flex align-items-center">
                    <div className="icon-element flex-shrink-0 bg-2">
                      <i className="la la-donate"></i>
                    </div>
                    <div className="ml-2">
                      <p className="small text-gray">December 7, 2019</p>
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        $290.29 has been deposited into your account!
                      </p>
                    </div>
                  </a>
                  <a className="generic-list-item d-flex align-items-center">
                    <div className="icon-element flex-shrink-0 bg-3">
                      <i className="la la-exclamation-triangle"></i>
                    </div>
                    <div className="ml-2">
                      <p className="small text-gray">December 4, 2019</p>
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        Spending Alert: We've noticed unusually high spending
                        for your account.
                      </p>
                    </div>
                  </a>
                  <a className="generic-list-item d-flex align-items-center">
                    <div className="icon-element flex-shrink-0 bg-4">
                      <i className="la la-check-circle"></i>
                    </div>
                    <div className="ml-2">
                      <p className="small text-gray">December 1, 2019</p>
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        Your listing Hotel Govendor has been approved!{' '}
                      </p>
                    </div>
                  </a>
                  <a className="generic-list-item d-flex align-items-center">
                    <div className="icon-element flex-shrink-0 bg-5">
                      <i className="la la-check-circle"></i>
                    </div>
                    <div className="ml-2">
                      <p className="small text-gray">December 1, 2019</p>
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        Your listing Burger House has been approved!{' '}
                      </p>
                    </div>
                  </a>
                </div>
                <a className="dropdown-item text-center small text-gray font-weight-medium py-2">
                  Show All Alerts
                </a>
              </div>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle after-none"
                id="messagesDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="la la-envelope"></i>
                <span className="badge badge-warning badge-counter">2</span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right animated--grow-in"
                aria-labelledby="messagesDropdown"
              >
                <h6 className="generic-list-header">Messages Center</h6>
                <div className="generic-list scrollable-content scrollbar-hidden">
                  <a
                    className="generic-list-item d-flex align-items-center"
                    href="dashboard-messages.html"
                  >
                    <div className="user-thumb user-thumb-sm flex-shrink-0 position-relative">
                      <img src={author_image} />
                      <div className="status-indicator bg-success"></div>
                    </div>
                    <div className="ml-2">
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        Hi there! I am wondering if you can help me with a
                        problem I've been having.
                      </p>
                      <p className="small text-gray">Udin Cilok · 1m</p>
                    </div>
                  </a>
                  <a
                    className="generic-list-item d-flex align-items-center"
                    href="dashboard-messages.html"
                  >
                    <div className="user-thumb user-thumb-sm flex-shrink-0 position-relative">
                      <img src={author_image} />
                      <div className="status-indicator"></div>
                    </div>
                    <div className="ml-2">
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        Am I a good boy? The reason I ask is because someone
                        told me that people say this to all dogs, even if they
                        aren't good
                      </p>
                      <p className="small text-gray">Joynal Ali · 4m</p>
                    </div>
                  </a>
                  <a
                    className="generic-list-item d-flex align-items-center"
                    href="dashboard-messages.html"
                  >
                    <div className="user-thumb user-thumb-sm flex-shrink-0 position-relative">
                      <img src={author_image} />
                      <div className="status-indicator bg-success"></div>
                    </div>
                    <div className="ml-2">
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        Am I a good boy? The reason I ask is because someone
                        told me that people say this to all dogs, even if they
                        aren't good
                      </p>
                      <p className="small text-gray">Colin Smith · 10m</p>
                    </div>
                  </a>
                  <a
                    className="generic-list-item d-flex align-items-center"
                    href="dashboard-messages.html"
                  >
                    <div className="user-thumb user-thumb-sm flex-shrink-0 position-relative">
                      <img src={author_image} />
                      <div className="status-indicator bg-success"></div>
                    </div>
                    <div className="ml-2">
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        Am I a good boy? The reason I ask is because someone
                        told me that people say this to all dogs, even if they
                        aren't good
                      </p>
                      <p className="small text-gray">Alex Smith · 1h</p>
                    </div>
                  </a>
                  <a
                    className="generic-list-item d-flex align-items-center"
                    href="dashboard-messages.html"
                  >
                    <div className="user-thumb user-thumb-sm flex-shrink-0 position-relative">
                      <img src={author_image} />
                      <div className="status-indicator"></div>
                    </div>
                    <div className="ml-2">
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        Am I a good boy? The reason I ask is because someone
                        told me that people say this to all dogs, even if they
                        aren't good
                      </p>
                      <p className="small text-gray">Kamran adi · Yesterday</p>
                    </div>
                  </a>
                </div>
                <a className="dropdown-item text-center small text-gray font-weight-medium py-2">
                  Read More Messages
                </a>
              </div>
            </li> */}
            <li className="nav-item dropdown border-left pl-3 ml-4">
              <a
                className="nav-link dropdown-toggle after-none"
                id="userDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="user-thumb user-thumb-sm position-relative">
                  <img
                    src={userData.profileimage ? imageUrl : author_image}
                    alt="profile"
                  />
                  <div className="status-indicator bg-success"></div>
                </div>
                <span className="ml-2 small font-weight-medium d-none d-lg-inline">
                  {userData.first_name} {userData.last_name}
                </span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-sm dropdown-menu-right animated--grow-in py-2"
                aria-labelledby="userDropdown"
              >
                <Link className="dropdown-item text-color font-size-15" to="/myProfile">
                  <i className="la la-user mr-2 text-gray font-size-18"></i>
                  Profile
                </Link>
                {/* <a
                  className="dropdown-item text-color font-size-15"
                  href="dashboard-messages.html"
                >
                  <i className="la la-envelope mr-2 text-gray font-size-18"></i>
                  Messages
                </a> */}
                <Link className="dropdown-item text-color font-size-15" to="/bookings">
                  <i className="la la-shopping-bag mr-2 text-gray font-size-18"></i>
                  Bookings
                </Link>
                <Link className="dropdown-item text-color font-size-15" to="/bookmarks">
                  <i className="la la-bookmark mr-2 text-gray font-size-18"></i>
                  Bookmarks
                </Link>
                <Link className="dropdown-item text-color font-size-15" to="/listing/add">
                  <i className="la la-plus-circle mr-2 text-gray font-size-18"></i>
                  Add Listing
                </Link>
                <div
                  className="dropdown-item text-color font-size-15"
                  style={{ cursor: "pointer" }}
                  onClick={async () => {
                    handleLogout();
                  }}
                >
                  <i className="la la-power-off mr-2 text-gray font-size-18"></i>
                  Logout
                </div>
              </div>
            </li>
          </ul>
        </nav>
        {children}
      </div>
      <div className="dashboard-footer bg-white">
        <div className="container-fluid">
          <div className="copy-right d-flex align-items-center justify-content-between">
            <ul className="list-items term-list text-right">
              <li>
                <Link to="/home">Home</Link>
              </li>
              <li>
                <Link to="/home">About</Link>
              </li>
              <li>
                <Link href="/home">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarAdminWrapper;
