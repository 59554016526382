import axios from 'axios';
import { USER_DETAILS_URL } from '../url_constants';

export const updateProfilePicture = (token, payload) => {
  const config = {
    url: `${USER_DETAILS_URL}`,
    method: 'PUT',
    data: payload,
    headers: {
      Authorization: 'Bearer ' + token,
    },
  };
  return axios(config).then().catch();
};
