import { useState } from "react";
import Avatar from "../../images/avatar.png";
import SendMessageModal from "../SendMessageModal";
import { FormatDate, formatCurrency } from "../../utils/common";
import { useNavigate } from "react-router-dom";

export const BookingList = ({ isAdmin, bookings }) => {
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();

  console.log("bookings", bookings)

  const handleShowInvoice = (booking) => {
    navigate(`/invoice?id=${booking?.id}`);
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="block-card dashboard-card mb-4 px-0">
            <div className="block-card-header d-flex flex-wrap align-items-center justify-content-between px-4">
              <h2 className="widget-title pb-0">Bookings Requests</h2>
              <div className="user-chosen-select-container">
                <select className="user-chosen-select">
                  <option value="1">All</option>
                  <option value="2">Approved</option>
                  <option value="3">Pending</option>
                  <option value="4">Canceled</option>
                </select>
              </div>
            </div>
            <div className="block-card-body pt-0 gap-4">
              {bookings?.map((b, k) => {
                return (
                  <div
                    key={k}
                    className="generic-list dashboard-booking-list mb-4"
                  >
                    <div className="generic-list-item d-flex">
                      <div className="user-thumb user-thumb-lg flex-shrink-0 align-self-start">
                        <img src={Avatar} alt="author" />
                      </div>
                      <div className="ml-3 flex-grow-1 position-relative">
                        <h3 className="text-color font-size-20 mb-3">
                          <a href="listing-details.html" className="text-color">
                            {b.venue}
                          </a>
                        </h3>
                        <ul className="list-items list--items">
                          <li className="mb-3">
                            <span className="text-color">Booking Date:</span>{" "}
                            {FormatDate(b.start_date)} -{" "}
                            {FormatDate(b.end_date)}
                          </li>
                          <li className="mb-3">
                            <span className="text-color">Persons:</span>{" "}
                            {b.guests} {b.guests > 1 ? "People" : "People"}
                          </li>
                          <li className="mb-3">
                            <span className="text-color">Price:</span>{" "}
                            {formatCurrency(b.amount)}
                          </li>
                          <li className="mb-3">
                            <span className="text-color">Email:</span>
                            <span className="text-gray before-none">
                              {b.email}
                            </span>
                          </li>
                          <li className="mb-3">
                            <span className="text-color">Phone:</span>
                            <a
                              href="tel:+496170961709"
                              className="text-gray before-none"
                            >
                              {b.phone}
                            </a>
                          </li>
                          <li className="mb-3">
                            <span className="text-color">Client:</span>
                            {b.name}
                          </li>
                        </ul>

                        {isAdmin ? (
                          <>
                            <div
                              className="btn-gray line-height-26 mt-1"
                              onClick={() => setModal(true)}
                              style={{ cursor: "pointer" }}
                              data-toggle="modal"
                              data-target="#sendMessageModal"
                            >
                              Send Message{" "}
                              <i className="la la-arrow-right ml-1"></i>
                            </div>
                            <div className="action-buttons position-absolute top-0 right-0">
                              <button
                                type="button"
                                className="btn bg-rgb-danger font-weight-medium mr-2"
                              >
                                <i className="la la-times-circle mr-1"></i>
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn bg-rgb-success font-weight-medium"
                              >
                                <i className="la la-check-circle mr-1"></i>
                                Approve
                              </button>
                            </div>
                          </>
                        ) : null}
                        <button
                          type="button"
                          onClick={() => handleShowInvoice(b)}
                          className="btn btn-link font-weight-medium mr-2"
                        >
                          Show Invoice
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {modal && <SendMessageModal />}
    </>
  );
};
