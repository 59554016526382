import React, { useState, useEffect, useRef } from "react";
import "../styles/style.css";
import "../styles/bootstrap.min.css";
import "../styles/line-awesome.min.css";
import "../styles/owl.carousel.min.css";
import "../styles/owl.theme.default.min.css";
import "../styles/animated-headline.css";
import "../styles/jquery-ui.css";
import "../styles/jquery.fancybox.css";
import "../styles/chosen.min.css";
import { SEARCH_CITY, GET_CITIES } from "../apis/url_constants";
import { useNavigate } from "react-router-dom";
import Loader from "./loader";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import axios from "axios";

const Hero = (props) => {
  //const [posts, setPosts] = useState([]);
  const navigate = useNavigate();
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const [loader, setLoader] = useState(false);
  const [cities, setCities] = useState([]);
  const [selectClass, setSelectClass] = useState(false);
  const [selectClass2, setSelectClass2] = useState(false);
  const [text, setText] = useState("");
  const [selectLocationValue, setSelectLocationValue] =
    useState("Select a location");
  const [selectCatValue, setSelectCatValue] = useState("Select a Category");

  const loadCities = async () => {
    const data = await axios.get(GET_CITIES);
    setCities(data.data);
  };

  useEffect(() => {
    loadCities();
  }, []);

  const updatedPost = async () => {
    setLoader(true);
    const res = `search?data=${text}`;
    const url = SEARCH_CITY + res;
    let data = await fetch(url);
    let parsedData = await data.json();
    if (parsedData) {
      setLoader(false);
      // setPosts(parsedData);
      // console.log(parsedData);
    }
  };

  function myFunction() {
    document.getElementById("myDropdown").classList.toggle("show");
    document.getElementById("myInput").focus();
    // document.getElementById("pqrs").classList.toggle("pqr");
    setSelectClass(!selectClass);
  }
  function hiddenList() {
    document.getElementById("myDropdown").classList.remove("show");
    setSelectClass(!selectClass);
  }
  function myFunction2() {
    document.getElementById("myDropdown2").classList.toggle("show");
    document.getElementById("myInput2").focus();
    setSelectClass2(!selectClass2);
  }
  function hiddenList2() {
    document.getElementById("myDropdown2").classList.remove("show");
    setSelectClass2(!selectClass2);
  }
  const handleClickOutside = (e) => {
    if (ref1.current && !ref1.current.contains(e.target)) {
      hiddenList && hiddenList();
    }
  };
  const handleClickOutside2 = (e) => {
    if (ref2.current && !ref2.current.contains(e.target)) {
      hiddenList2 && hiddenList2();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside2, true);
    return () => {
      document.removeEventListener("click", handleClickOutside2, true);
    };
  }, []);

  function filterFunction() {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("myInput");
    filter = input.value.toUpperCase();
    var ul = document.getElementById("user-chosen-new");
    var li = ul.getElementsByTagName("li");
    // console.log(input.value);
    // const arr = [];
    // // arr.push(li);
    // console.log(li);
    // const abcd = li.filter((val) => val === input.value);
    // console.log(abcd);
    for (i = 0; i < li.length; i++) {
      var txtValue = li[i].textContent || li[i].innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }
  const handleInputText = (e) => {
    setText(e.target.value);
  };

  return loader ? (
    <Loader />
  ) : (
    <section class="hero-wrapper padding-bottom-150px">
      <div class="overlay"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="hero-heading text-center">
              <div class="section-heading">
                <h2 class="sec__title">
                  What's Your Plan Today? Let's Explore
                </h2>
                <p class="sec__desc">
                  All the top locations – from restaurants and clubs, to
                  galleries, famous places and more...
                </p>
              </div>
            </div>
            <div className="tab-content">
              <form action="#" className="form-box" onSubmit={updatedPost}>
                <div
                  className="tab-pane fade show active"
                  id="place"
                  role="tabpanel"
                  aria-labelledby="place-tab"
                >
                  <div className="main-search-input mt-0">
                    <div className="main-search-input-item">
                      <div className="form-group mb-0 d-flex align-items-center">
                        <span
                          style={{
                            top: 16,
                          }}
                          className="la la-search form-icon"
                        ></span>
                        <input
                          className="form-control"
                          type="search"
                          placeholder="What are you looking for?"
                          value={text}
                          onChange={handleInputText}
                          style={{
                            padding: "10px 0px 12px 40px",
                            fontSize: "15px",
                            // border: "1px solid rgba(0, 0, 0, 0.3)",
                            border: "1px solid #aaa",
                          }}
                        />
                      </div>
                    </div>
                    {/* ----Og Select --- */}
                    <div className="dropdown-new" ref={ref1}>
                      <div className="d-flex" onClick={myFunction}>
                        <p className="location-tag">{selectLocationValue}</p>
                        <label
                          style={{
                            color: "rgb(111 111 111)",
                            margin: 0,
                            cursor: "pointer",
                          }}
                        >
                          {selectClass ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </label>
                      </div>
                      <div id="myDropdown" className="dropdown-content">
                        <div className="inner-select-div">
                          <input
                            type="text"
                            // placeholder="Search.."
                            id="myInput"
                            onKeyUp={filterFunction}
                          />
                          <ul id="user-chosen-new">
                            {cities &&
                              cities.map((val, idx) => {
                                return (
                                  <li
                                    key={idx}
                                    onClick={() => {
                                      setSelectLocationValue(val.name);
                                      myFunction();
                                    }}
                                  >
                                    {val.name}
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="dropdown-new2" ref={ref2}>
                      <div className="d-flex" onClick={myFunction2}>
                        <p className="location-tag">{selectCatValue}</p>
                        <label
                          style={{
                            color: "rgb(111 111 111)",
                            margin: 0,
                            cursor: "pointer",
                          }}
                        >
                          {selectClass2 ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </label>
                      </div>
                      <div id="myDropdown2" className="dropdown-content2">
                        <div className="inner-select-div">
                          <input
                            type="text"
                            // placeholder="Search.."
                            id="myInput2"
                            // onKeyUp={filterFunction}
                          />
                          <ul id="user-chosen-new">
                            <li value="0">Select a Category</li>
                            <li value="1">Shops</li>
                            <li value="2">Hotels</li>
                            <li value="3">Foods & Restaurants</li>
                            <li value="4">Fitness</li>
                            <li value="5">Travel</li>
                            <li value="6">Salons</li>
                            <li value="7">Event</li>
                            <li value="8">Business</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="main-search-input-item">
                      <button
                        className="theme-btn gradient-btn border-0 w-100"
                        type="submit"
                        onClick={() => {
                          navigate("/listinggrid", { state: { text: text } });
                        }}
                      >
                        <i className="la la-search mr-2"></i>Search Now
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <a className="scroll-down-arrow">
            <i className="la la-arrow-down"></i>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Hero;
