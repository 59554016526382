import { create } from "zustand";
import { persist } from "zustand/middleware";

const initialState = {
  guestInfo: [],
  paymentMethod: {},
  step: 1,
  maxStep: 2,
};

export const useCheckoutStore = create(
  persist(
    (set) => ({
      checkout: initialState,
      setCheckoutData: (newState, step) =>
        set((s) => ({
          checkout: {
            ...s.checkout,
            ...newState,
            step: step,
          },
        })),
      clearCheckoutData: (key, step) =>
        set((s) => ({
          checkout: {
            ...s.checkout,
            [key]: null,
            step: step,
          },
        })),
      clearCheckout: () =>
        set((s) => ({
          checkout: initialState,
        })),
    }),
    {
      name: "checkout",
    }
  )
);
