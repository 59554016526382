import React, { useState, useEffect } from "react";
import logo_white from "../images/logo-white.png";
import { Link, useNavigate } from "react-router-dom";
import { logoutUser } from "../apis/user_apis/logout";
import { toast } from "react-toastify";

const AdminSidebar = ({ activeRoute }) => {
  const history = useNavigate();
  const [isVendor, setIsVendor] = useState("");

  const getVendorDetails = () => {
    const vDetails = sessionStorage.getItem("state")
      ? JSON.parse(sessionStorage.getItem("state"))
      : [];

    setIsVendor(vDetails.user_details.data[0].is_vendor);
  };
  useEffect(() => {
    getVendorDetails();
  }, []);

  const handleLogout = async () => {
    try {
      logoutUser(sessionStorage?.access)
        .then((res) => {
          // if (res.status === 200) {
          //   // console.log("logged out!");
          // } else {
          //   console.log("Error logging out: ", res);
          // }
        })
        .catch((e) => {
          console.log("Error logging out: ", e);
          toast.error("Something went wrong !!");
        });
    } catch (e) {
      console.log("Error logging out: ", e);
      toast.error("Something went wrong !!");
    } finally {
      localStorage.clear();
      sessionStorage.clear();
      sessionStorage.setItem("Loader", true);
      history("/", { replace: true }); //* Takes you back to home page in any case
    }
  };

  const VendorMenuList = () => {
    return (
      <ul className="navbar-nav dashboard-sidebar">
        <li>
          <span id="sidebar-close">
            <i className="fas fa-times"></i>
          </span>
        </li>
        <li>
          <a className="sidebar-brand" href="/">
            <img src={logo_white} alt="logo" />
          </a>
        </li>
        <li className="sidebar-heading pt-3 noselect">Main</li>
        <li className={`nav-item ${activeRoute === "home" && "active"}`}>
          <Link className="nav-link" to="/">
            <i className="la la-home font-size-18 mr-1"></i>
            <span>Home</span>
          </Link>
        </li>
        <li className={`nav-item ${activeRoute === "dashboard" && "active"}`}>
          <Link className="nav-link" to="/dashboard">
            <i className="la la-dashboard font-size-18 mr-1"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        <li className={`nav-item ${activeRoute === "bookings" && "active"}`}>
          <Link className="nav-link" to="/bookings">
            <i className="la la-shopping-bag font-size-18 mr-1"></i>
            <span>Bookings</span>
          </Link>
        </li>
        <li className={`nav-item ${activeRoute === "bookmarks" && "active"}`}>
          <Link className="nav-link" to="/bookmarks">
            <i className="la la-bookmark font-size-18 mr-1"></i>
            <span style={{ cursor: "pointer" }}>Bookmarks</span>
          </Link>
        </li>
        {/* <li className={`nav-item ${activeRoute === '' && 'active'}`}>
        <a className="nav-link" href="#">
          <i className="la la-envelope font-size-18 mr-1"></i>
          <span style={{ cursor: 'pointer' }}>
            Messages{' '}
            <span style={{'cursor': pointere"}} className="badge badge-success">3</span>
          </span>
        </a>
      </li> */}
        <li>
          <hr className="sidebar-divider border-top-color" />
        </li>
        <li className="sidebar-heading">Listings</li>
        <li className={`nav-item ${activeRoute === "my-listings" && "active"}`}>
          <Link to="/listing/" className="nav-link">
            <i className="la la-file-text-o font-size-18 mr-1"></i>
            <span style={{ cursor: "pointer" }}>My listings</span>
          </Link>
        </li>
        <li className={`nav-item ${activeRoute === "reviews" && "active"}`}>
          <Link className="nav-link" to="/reviews">
            <i className="la la-star-o font-size-18 mr-1"></i>
            <span style={{ cursor: "pointer" }}>Reviews</span>
          </Link>
        </li>
        <li className={`nav-item ${activeRoute === "add-listing" && "active"}`}>
          <Link to="/listing/add" className="nav-link">
            <i className="la la-plus-circle font-size-18 mr-1"></i>
            <span style={{ cursor: "pointer" }}>Add Listing</span>
          </Link>
        </li>
        <li>
          <hr className="sidebar-divider border-top-color" />
        </li>
        <li className="sidebar-heading">Account</li>
        <li className={`nav-item ${activeRoute === "myprofile" && "active"}`}>
          <Link className="nav-link" to="/myProfile">
            <i className="la la-user font-size-18 mr-1"></i>
            <span style={{ cursor: "pointer" }}>My Profile</span>
          </Link>
        </li>
        <li
          className={`nav-item ${activeRoute === "" && "active"}`}
          onClick={async () => {
            handleLogout();
          }}
        >
          <Link to="#" className="nav-link">
            <i className="la la-power-off font-size-18 mr-1"></i>
            <span style={{ cursor: "pointer" }}>Logout</span>
          </Link>
        </li>
      </ul>
    );
  };

  const UserMenuList = () => {
    return (
      <ul className="navbar-nav dashboard-sidebar">
        <li>
          <span id="sidebar-close">
            <i className="fas fa-times"></i>
          </span>
        </li>
        <li>
          <a className="sidebar-brand" href="/">
            <img src={logo_white} alt="logo" />
          </a>
        </li>
        <li className="sidebar-heading pt-3 noselect">Main</li>
        <li className={`nav-item ${activeRoute === "home" && "active"}`}>
          <Link className="nav-link" to="/">
            <i className="la la-home font-size-18 mr-1"></i>
            <span>Home</span>
          </Link>
        </li>
        <li className={`nav-item ${activeRoute === "bookings" && "active"}`}>
          <Link className="nav-link" to="/bookings">
            <i className="la la-shopping-bag font-size-18 mr-1"></i>
            <span>Bookings</span>
          </Link>
        </li>
        <li className={`nav-item ${activeRoute === "bookmarks" && "active"}`}>
          <Link className="nav-link" to="/bookmarks">
            <i className="la la-bookmark font-size-18 mr-1"></i>
            <span style={{ cursor: "pointer" }}>Bookmarks</span>
          </Link>
        </li>
        <li>
          <hr className="sidebar-divider border-top-color" />
        </li>
        <li className="sidebar-heading">Listings</li>

        <li className={`nav-item ${activeRoute === "reviews" && "active"}`}>
          <Link className="nav-link" to="/reviews">
            <i className="la la-star-o font-size-18 mr-1"></i>
            <span style={{ cursor: "pointer" }}>Reviews</span>
          </Link>
        </li>
        <li>
          <hr className="sidebar-divider border-top-color" />
        </li>
        <li className="sidebar-heading">Account</li>
        <li className={`nav-item ${activeRoute === "myprofile" && "active"}`}>
          <Link className="nav-link" to="/myProfile">
            <i className="la la-user font-size-18 mr-1"></i>
            <span style={{ cursor: "pointer" }}>My Profile</span>
          </Link>
        </li>
        <li className={`nav-item ${activeRoute === "" && "active"}`}>
          <a
            className="nav-link"
            onClick={async () => {
              handleLogout();
            }}
          >
            <i className="la la-power-off font-size-18 mr-1"></i>
            <span style={{ cursor: "pointer" }}>Logout</span>
          </a>
        </li>
      </ul>
    );
  };

  return isVendor == true ? <VendorMenuList /> : <UserMenuList />;
};

export default AdminSidebar;
