import React, { useState, useEffect } from "react";
import logo_white from "../images/logo-white.png";
import "../styles/style.css";
import "../styles/bootstrap.min.css";
import "../styles/line-awesome.min.css";
import "../styles/owl.carousel.min.css";
import "../styles/owl.theme.default.min.css";
import "../styles/animated-headline.css";
import "../styles/jquery-ui.css";
import "../styles/jquery.fancybox.css";
import "../styles/chosen.min.css";
import "./header.css";
import { getUserDetails, isUserVendor } from "../utils/common";
import author_image from "../images/avatar-img.jpg";
import { BASE_URL } from "../apis/url_constants";
import { logoutUser } from "../apis/user_apis/logout";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { AdminHeaderMenu, UserHeaderMenu } from "../helpers/constants";
// import Button from "@mui/material/Button";
import Loader from "./loader";
import styled from "@emotion/styled";
import { Box, Menu, MenuItem } from "@mui/material";
// import { styled } from "@mui/system";

const Header = () => {
  const [loading, setLoading] = useState(false);
  const [vendorAvatar, setVendorAvatar] = useState(false);
  const [avatar, setAvatar] = useState(null);
  const [userAvatar, setUserAvatar] = useState(false);
  const [vendorList, setVendorList] = useState(false);
  const userData = getUserDetails();
  const history = useNavigate();
  const isVendor = isUserVendor();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate();

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const stateVal = sessionStorage.getItem("state") ? true : false;
    if (stateVal) {
      if (isVendor) {
        setVendorAvatar(true);
        const imageUrl = `${BASE_URL}${userData.profileimage}`;
        setAvatar(imageUrl);
      } else {
        setUserAvatar(true);
        const imageUrl = author_image;
        setAvatar(imageUrl);
      }
    } else {
      // setUserAvatar(true);
      // setAvatar(false);
      return;
      // alert("Not Login");
    }
  }, [userData, isVendor]);

  useEffect(() => {
    if (userData !== null) {
      setVendorList(userData.is_vendor);
    }
  }, [userData, setVendorList]);

  const handleLogout = async () => {
    try {
      logoutUser(sessionStorage?.access)
        .then((res) => {
          if (res.status === 200) {
            // console.log(vendorList);
            setVendorList(!vendorList);
            // setVendorAvatar(!vendorAvatar);
            // history("/", { replace: true });
          } else {
            console.log("Error logging out: ", res);
          }
        })
        .catch((e) => {
          toast.error("Something went wrong !!");
          console.log("Error logging out: ", e);
        });
    } catch (e) {
      toast.error("Something went wrong !!");
      console.log("Error logging out: ", e);
    } finally {
      localStorage.clear();
      sessionStorage.clear();
      history("/", { replace: true }); //* Takes you back to home page in any case
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <StyledHeaderBG>
      {/* <div></div> */}
      <div className="header-aread">
        {/* {login_modal && <LoginModal closeModal={setLoginModal} />}
        {register_modal && <RegisterModal closeModal={setRegisterModal} />} */}
        <div className="header-top-bard py-2 padding-right-30px padding-left-30px border-top border-top-color">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center header-top-info font-size-14">
                <p className="mr-3 pr-3 border-right border-right-color">
                  <span className="mr-1 text-white font-weight-semi-bold">
                    Email:
                  </span>
                  <Link to="/" className="font-weight-medium">
                    Uniworkpass@gmail.com
                  </Link>
                </p>
                <p>
                  <span className="mr-1 text-white font-weight-semi-bold">
                    Phone:
                  </span>
                  <a href="tel:+918793656170" className="font-weight-medium">
                    +918793656170
                  </a>
                </p>
              </div>
              <div className="col-lg-6 d-flex align-items-center justify-content-end header-top-info">
                {!userData && (
                  <p className="login-and-signup-wrap">
                    <Link
                      to="/login"
                      // onClick={() => {
                      //   setLoginModal(!login_modal);
                      // }}
                    >
                      <span className="mr-1 la la-sign-in"></span>Login
                    </Link>
                    <span className="text-white px-2">or</span>
                    <Link
                      to="/register"
                      // onClick={() => {
                      //   setRegisterModal(!register_modal);
                      // }}
                      // onClick={handleOpenRegister}
                      data-toggle="modal"
                      data-target="#signUpModal"
                    >
                      <span className="mr-1 la la-user-plus"></span>Sign Up
                    </Link>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="header-menu-wrapper">
          <div className="container">
            <div className="row m-0">
              <div className="col-lg-12">
                <div className="menu-full-width">
                  <div className="logo">
                    <Link to="/">
                      <img src={logo_white} alt="logo" />
                    </Link>
                    <div className="d-flex align-items-center">
                      <Link
                        to="/listing/add"
                        className="btn-gray add-listing-btn-show font-size-24 mr-2 flex-shrink-0"
                        data-toggle="tooltip"
                        data-placement="left"
                        title="Add Listing"
                      >
                        <i className="la la-plus"></i>
                      </Link>
                      <div className="menu-toggle">
                        <span className="menu__bar"></span>
                        <span className="menu__bar"></span>
                        <span className="menu__bar"></span>
                      </div>
                    </div>
                  </div>
                  <div className="main-menu-content main-menu-content-2">
                    <nav className="main-menu">
                      <ul>
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/about">About</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact Us</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="nav-right-content ml-auto">
                    {(vendorAvatar && vendorList) ||
                    (userAvatar && !vendorList) ? (
                      <div className="nav-item dropdown border-left pl-3 ml-4 d-flex">
                        <div
                          className="nav-link dropdown-toggle after-none"
                          // id="userDropdown"
                          // role="button"
                          // data-toggle="dropdown"
                          // aria-haspopup="true"
                          // aria-expanded="false"
                          id="basic-button"
                          aria-controls={open ? "basic-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                        >
                          <div className="user-thumb user-thumb-sm position-relative">
                            {vendorAvatar && <img src={avatar} alt="profile" />}
                            {userAvatar && <img src={avatar} alt="profile" />}
                            <div className="status-indicator bg-success"></div>
                          </div>
                        </div>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-button",
                          }}
                        >
                          <Box
                            sx={{
                              width: 200,
                            }}
                          >
                            {vendorList
                              ? Object.keys(AdminHeaderMenu).map((a, k) => {
                                  const menu = AdminHeaderMenu[a];
                                  return (
                                    <MenuItem
                                      key={k}
                                      onClick={() => navigate(menu.path)}
                                    >
                                      <i
                                        className={`${menu.icon} mr-2 text-gray font-size-18`}
                                      ></i>
                                      {menu.name}
                                    </MenuItem>
                                  );
                                })
                              : Object.keys(UserHeaderMenu).map((a, k) => {
                                  const menu = UserHeaderMenu[a];
                                  return (
                                    <MenuItem
                                      key={k}
                                      onClick={() => navigate(menu.path)}
                                    >
                                      <i
                                        className={`${menu.icon} mr-2 text-gray font-size-18`}
                                      ></i>
                                      {menu.name}
                                    </MenuItem>
                                  );
                                })}

                            <MenuItem>
                              <i className="la la-power-off mr-2 text-gray font-size-18"></i>
                              Logout
                            </MenuItem>
                          </Box>
                        </Menu>
                        {vendorList && vendorAvatar ? (
                          <Link
                            to="/listing/add"
                            className="theme-btn gradient-btn shadow-none add-listing-btn-hide"
                          >
                            <i className="la la-layer-group mr-2"></i>Add
                            Listing
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledHeaderBG>
  );
};
export default Header;

export const StyledHeaderBG = styled.header`
  background-color: #5c6579;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;
