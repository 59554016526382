import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateProfilePicture, updateUserEmail } from "../../actions/user/user";
import { BASE_URL } from "../../apis/url_constants";
import { changePassword } from "../../apis/user_apis/changepassword";
import { logoutUser } from "../../apis/user_apis/logout";
import AdminSidebar from "../../components/admin_sidebar";
import FormInput from "../../components/FormInput";
import NavbarAdminWrapper from "../../components/NavbarAdminWrapper";
import Team from "../../images/avatar-img.jpg";
import { getUserDataAction } from "../../redux/actions/user_actions/user_data";

const MyProfile = () => {
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [accessToken, setAccessToken] = useState();
  const [form_inputs, setFormInputs] = useState({});
  const history = useNavigate();
  const [buttonDisable, setButtonDisable] = useState(true);

  const userName = useMemo(() => {
    if (userDetails?.first_name + userDetails?.last_name) {
      return `${userDetails?.first_name} ${userDetails?.last_name}`;
    }
  }, [userDetails?.first_name, userDetails?.last_name]);

  const handleChange = (e) => {
    setButtonDisable(false);
    const { name, value } = e.target;
    setFormInputs({ ...form_inputs, [name]: value });
  };
  const changepassword = () => {
    if (
      !(
        form_inputs.currentPassword &&
        form_inputs.newPassword &&
        form_inputs.confirmNewPassword
      )
    ) {
      toast.error("Fields cannot be blank");
      return;
    }
    if (form_inputs.currentPassword === form_inputs.newPassword) {
      toast.error("Current Password and New Password cannot be same");
      return;
    }
    if (
      form_inputs.newPassword &&
      !(form_inputs.newPassword === form_inputs.confirmNewPassword)
    ) {
      toast.error("New Password and Confirm Password are not same");
      return;
    }
    const payload = {
      currentpassword: form_inputs.currentPassword,
      newpassword: form_inputs.newPassword,
      confirmnewpassword: form_inputs.confirmNewPassword,
      token: accessToken,
    };
    changePassword(payload)
      .then((res) => {
        toast.success(res.data.message);
        handleLogout();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const handleLogout = async () => {
    try {
      logoutUser(sessionStorage?.access)
        .then((res) => {
          if (res.status === 200) {
            console.log("logged out!");
          } else {
            console.log("Error logging out: ", res);
          }
        })
        .catch((e) => {
          toast.error("Something went wrong !!");
          console.log("Error logging out: ", e);
        });
    } catch (e) {
      toast.error("Something went wrong !!");
      console.log("Error logging out: ", e);
    } finally {
      localStorage.clear();
      sessionStorage.clear();
      history("/", { replace: true }); //* Takes you back to home page in any case
    }
  };

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png"];
    const allowedSize = 10e6;
    console.log(file.type);
    if (file.size > allowedSize) {
      toast.error("Uploaded image size cannot be greater than 10MB");
      return;
    }
    if (!allowedTypes.includes(file.type)) {
      toast.error("Uploaded image can only be of JPG, JPEG or PNG type.");
      return;
    }
    setImagePreview(URL.createObjectURL(file));
    setFormInputs({ ...form_inputs, [e.target.name]: file });
  };
  const handleRemoveImage = () => {
    setImagePreview("");
  };

  const handleUserSaveChanges = () => {
    const { profileimage } = form_inputs;
    console.log(profileimage);
    if (!profileimage) {
      profileimage = {};
    }
    const data = new FormData();
    data.append("profileimage", profileimage);
    updateProfilePicture(accessToken, data, () => {
      dispatch(getUserDataAction(sessionStorage.access));
    });
  };

  const handleEmailChange = () => {
    const { currentemail, newemail, confirmnewemail } = form_inputs;
    if (!currentemail || !newemail || !confirmnewemail) {
      toast.error("Fields cannot be blank");
      return;
    }
    if (userDetails.email !== currentemail) {
      toast.error("Please enter correct current email.");
      return;
    } else if (newemail !== confirmnewemail) {
      toast.error("New Email and Confirm new email do not match.");
      return;
    }
    const payload = { currentemail, newemail, confirmnewemail };
    updateUserEmail(accessToken, payload, () => {
      setUserDetails({ ...userDetails, email: newemail });
    });
  };

  useEffect(() => {
    dispatch(getUserDataAction(sessionStorage.access));
    (async () => {
      const accesstoken = await window.sessionStorage.getItem("access");
      const state = await window.sessionStorage.getItem("state");
      const json = JSON.parse(state);
      if (json.user_details?.data[0]) {
        setUserDetails(json.user_details?.data[0]);
        setAccessToken(accesstoken);
        if (json.user_details?.data[0].profileimage) {
          const imageUrl = `${BASE_URL}${json.user_details?.data[0].profileimage}`;
          setImagePreview(imageUrl);
        }
      }
    })();
  }, []);

  return (
    <div>
      <AdminSidebar activeRoute="myprofile" />
      <NavbarAdminWrapper>
        <div className="container-fluid dashboard-inner-body-container">
          <div className="breadcrumb-content d-sm-flex align-items-center justify-content-between mb-4">
            <div className="section-heading">
              <h2 className="sec__title font-size-24 mb-0">My Profile</h2>
            </div>
            <ul className="list-items bread-list bread-list-2">
              <li>
                <a>Home</a>
              </li>
              <li>Dashboard</li>
              <li>My Profile</li>
            </ul>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="block-card dashboard-card mb-4">
                <div className="block-card-header">
                  <h2 className="widget-title pb-0">Profile Details</h2>
                </div>
                <div className="block-card-body">
                  <div className="edit-profile-photo d-flex align-items-center">
                    <img
                      src={imagePreview ? imagePreview : Team}
                      alt=""
                      className="profile-img"
                    />
                    <div className="d-flex flex-column file-upload-wrap file-upload-wrap-2 ml-4">
                      <input
                        type="file"
                        name="profileimage"
                        className="multi file-upload-input with-preview mb-2"
                        onChange={handleUploadImage}
                      />
                      <span className="file-upload-text">
                        <i className="la la-photo mr-2"></i>Upload Photo
                      </span>
                      <a
                        href="javascript:void(0)"
                        className="btn-gray line-height-30 mb-1"
                        onClick={handleRemoveImage}
                      >
                        <i className="la la-eye-slash mr-2"></i>Remove Photo
                      </a>
                      <p>
                        <small>Maximum file size: 10 MB.</small>
                      </p>
                    </div>
                  </div>
                  <form className="form-box row pt-4">
                    <div className="col-lg-12">
                      <div className="input-box">
                        <label className="label-text">Your Name</label>
                        <div className="form-group">
                          <span className="la la-user form-icon"></span>
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            value={userName}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input-box">
                        <label className="label-text">Your Email</label>
                        <div className="form-group">
                          <span className="la la-envelope-o form-icon"></span>
                          <input
                            className="form-control"
                            type="email"
                            name="email"
                            value={userDetails?.email}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input-box">
                        <label className="label-text">Phone Number</label>
                        <div className="form-group">
                          <span className="la la-phone form-icon"></span>
                          <input
                            className="form-control"
                            type="text"
                            name="text"
                            value={userDetails?.phone_number}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-12">
                      <div className="input-box">
                        <label className="label-text">Address</label>
                        <div className="form-group">
                          <span className="la la-map-marker form-icon"></span>
                          <input
                            className="form-control"
                            type="text"
                            name="text"
                            value="USA 27TH Brooklyn NY"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input-box">
                        <label className="label-text">Website</label>
                        <div className="form-group">
                          <span className="la la-globe form-icon"></span>
                          <input
                            className="form-control"
                            type="text"
                            name="text"
                            value="www.techydevs.com"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input-box">
                        <label className="label-text">Notes</label>
                        <div className="form-group">
                          <span className="la la-pencil form-icon"></span>
                          <textarea
                            className="message-control form-control"
                            name="message"
                          >
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Ipsum, quos?
                          </textarea>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="col-lg-12">
                      <div className="input-box">
                        <label className="label-text">Facebook</label>
                        <div className="form-group">
                          <span className="la la-facebook form-icon"></span>
                          <input
                            className="form-control"
                            type="text"
                            name="name"
                            placeholder="www.facebook.com"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input-box">
                        <label className="label-text">Twitter</label>
                        <div className="form-group">
                          <span className="la la-twitter form-icon"></span>
                          <input
                            className="form-control"
                            type="text"
                            name="text"
                            placeholder="www.twitter.com"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="input-box">
                        <label className="label-text">Instagram</label>
                        <div className="form-group">
                          <span className="la la-instagram form-icon"></span>
                          <input
                            className="form-control"
                            type="text"
                            name="text"
                            placeholder="www.instagram.com"
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="col-lg-12">
                      <div className="btn-box pt-1">
                        <button
                          type="button"
                          className="theme-btn gradient-btn border-0"
                          onClick={handleUserSaveChanges}
                        >
                          Save Changes<i className="la la-arrow-right ml-2"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="block-card dashboard-card mb-4">
                <div className="block-card-header">
                  <h2 className="widget-title pb-0">Change Password</h2>
                </div>
                <div className="block-card-body">
                  <form className="form-box row">
                    <div className="col-lg-12">
                      <FormInput
                        top={30}
                        right={30}
                        label="Current Password"
                        type="password"
                        value={form_inputs.currentPassword}
                        name="currentPassword"
                        onChange={handleChange}
                        placeholder="Current Password"
                      />
                    </div>
                    <div className="col-lg-12">
                      <FormInput
                        top={30}
                        right={30}
                        label="New Password"
                        type="password"
                        value={form_inputs.newPassword}
                        onChange={handleChange}
                        name="newPassword"
                        placeholder="New Password"
                      />
                    </div>
                    <div className="col-lg-12">
                      <FormInput
                        top={30}
                        right={30}
                        label="Confirm New Password"
                        type="password"
                        value={form_inputs.confirmNewPassword}
                        onChange={handleChange}
                        name="confirmNewPassword"
                        placeholder="Confirm New Password"
                      />
                    </div>
                    <div className="col-lg-12">
                      <div className="btn-box pt-1">
                        <button
                          type="button"
                          className="theme-btn  gradient-btn border-0"
                          disabled={buttonDisable}
                          onClick={() => changepassword()}
                        >
                          Change Password<i className="la la-arrow-right ml-2"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="block-card dashboard-card mb-4">
                <div className="block-card-header">
                  <h2 className="widget-title pb-0">Change Email</h2>
                </div>
                <div className="block-card-body">
                  <form className="form-box row">
                    <div className="col-lg-12">
                      <FormInput
                        label="Current Email"
                        name="currentemail"
                        type="text"
                        placeholder="Current Email"
                        onChange={handleChange}
                      />
                      {/* <div className="input-box">
                        <label className="label-text">Current Email</label>
                        <div className="form-group">
                          <span className="la la-envelope form-icon"></span>
                          <input
                            className="form-control"
                            type="email"
                            name="email"
                            placeholder="Current email"
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="col-lg-12">
                      <FormInput
                        label="New Email"
                        name="newemail"
                        type="text"
                        placeholder="New Email"
                        onChange={handleChange}
                      />
                      {/* <div className="input-box">
                        <label className="label-text">New Email</label>
                        <div className="form-group">
                          <span className="la la-envelope form-icon"></span>
                          <input
                            className="form-control"
                            type="email"
                            name="email"
                            placeholder="New email"
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="col-lg-12">
                      <FormInput
                        label="Confirm New Email"
                        name="confirmnewemail"
                        type="text"
                        placeholder="Confirm New Email"
                        onChange={handleChange}
                      />
                      {/* <div className="input-box">
                        <label className="label-text">Confirm New Email</label>
                        <div className="form-group">
                          <span className="la la-envelope form-icon"></span>
                          <input
                            className="form-control"
                            type="email"
                            name="email"
                            placeholder="Confirm new email"
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className="col-lg-12">
                      <div className="btn-box pt-1">
                        <button
                          type="button"
                          className="theme-btn gradient-btn border-0"
                          disabled={buttonDisable}
                          onClick={handleEmailChange}
                        >
                          Change Email<i className="la la-arrow-right ml-2"></i>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NavbarAdminWrapper>
    </div>
  );
};

export default MyProfile;
