import React from "react";
import {
  BASE_URL,
  DUMMY_PRIMARY_IMAGE,
  DUMMY_PROFILE_IMAGE,
} from "../../apis/url_constants";
import { Link } from "react-router-dom";
import { publishApi } from "../../apis/listing_apis/publish";
import { toast } from "react-toastify";
import { useDeleteBookmark } from "../../apis/listing_apis/bookmark";
import { useQueryClient } from "@tanstack/react-query";
import { getUserDetails } from "../../utils/common";

const ListingCard = (props) => {
  const { data, isAdmin } = props;
  let primary_image = DUMMY_PRIMARY_IMAGE;
  let author_image = data?.avatar ? data.avatar : DUMMY_PROFILE_IMAGE;
  const { mutate: deleteBookmark } = useDeleteBookmark();
  const queryClient = useQueryClient();
  const userDetails = getUserDetails();

  if (data.images && data.images.length) {
    primary_image = `${BASE_URL}${data.images[0].path}`;
  }
  const pricing = [1, 1, 1];

  const handleDeletion = (post_id) => {
    deleteBookmark(
      {
        listing_id: post_id,
        user_id: userDetails.id,
      },
      {
        onSuccess: (e) => {
          queryClient.invalidateQueries("get-bookmark");
          toast.success(e?.data?.message);
        },
      }
    );
  };

  const handlePublish = () => {
    const payload = {
      id: data.id,
      is_published: !data.is_published,
      listing_delisting: false,
    };
    publishApi(payload)
      .then(() => {
        props.refreshListing();
      })
      .catch((err) => {
        toast.error("Something went wrong !!");
        console.log(err);
      });
  };

  const getCity = () => {
    if (props?.cities?.length > 0) {
      let obj = props?.cities?.find(
        (city) => data.listing__city_id === city.value
      );

      return `${obj.label}, ${obj.state}`;
    }
  };

  console.log("data", data);

  return (
    <div className="card-item card-item-list card-item--list">
      <div className="card-image">
        <Link className="d-block" to={`/listing-details/${data.id}`}>
          <img
            src={primary_image}
            className="card__img lazy img-fluid"
            alt=""
          />
          <span className="badge">now open</span>
        </Link>
      </div>
      <div className="card-content">
        <div
          className="user-thumb d-inline-block"
          data-toggle="tooltip"
          data-placement="top"
          title="TechyDevs"
        >
          <img src={author_image} alt="author-img" />
        </div>
        <h4 className="card-title pt-3">
          <Link className="nav-link" to={`/property/${data.listing}`}>
            {data?.listing__name ? data?.listing__name : "Anonymous"}
          </Link>
          <i
            className="la la-check-circle ml-1"
            data-toggle="tooltip"
            data-placement="top"
            title="Claimed"
          ></i>
        </h4>
        <h3 className="card-title pt-3">{data?.listing__city_id__name}</h3>
        <p className="card-sub">
          <a>
            <i className="la la-map-marker mr-1 text-color-2"></i>
            {data.listing__city_id && getCity()}
          </a>
        </p>
        <ul className="listing-meta d-flex align-items-center">
          <li className="d-flex align-items-center">
            <span className="rate flex-shrink-0">4.7</span>
            <span className="rate-text">5 Ratings</span>
          </li>
          <li>
            <span
              className="price-range"
              data-toggle="tooltip"
              data-placement="top"
              title="Pricey"
            >
              {pricing.map((value, key) => {
                return (
                  <strong key={key} className="font-weight-medium">
                    $
                  </strong>
                );
              })}
            </span>
          </li>
          <li className="d-flex align-items-center">
            <i className="la la-cutlery mr-1 listing-icon"></i>
            <a className="listing-cat-link">{data?.category}</a>
          </li>
        </ul>
        <ul className="info-list padding-top-20px">
          <li>
            <span className="la la-link icon"></span>
            <a href={data?.web} target="_blank" rel="noreferrer">
              {" "}
              {data?.web}
            </a>
          </li>
          <li>
            <span className="la la-calendar-check-o icon"></span>
            Opened 1 month ago
          </li>
        </ul>
        <div className="action-buttons position-absolute top-0 right-0 mt-3 mr-3">
          {isAdmin ? (
            <>
              <Link
                to={`/listing/${data.id}/edit`}
                className="btn bg-rgb-success font-weight-medium mr-2"
              >
                <i className="la la-edit mr-1"></i>Edit
              </Link>
              <div
                onClick={handlePublish}
                style={{ cursor: "pointer" }}
                className={`btn ${
                  data.is_published ? "bg-rgb-danger" : "bg-rgb-success"
                } font-weight-medium mr-2`}
              >
                <i className="la mr-1"></i>
                {data.is_published ? "Un-Publish" : "Publish"}
              </div>
            </>
          ) : null}
          <div
            className="btn bg-rgb-danger font-weight-medium"
            onClick={() => {
              handleDeletion(data?.listing);
            }}
          >
            <i className="la la-trash mr-1"></i>Delete
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingCard;
