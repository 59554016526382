import { format } from "date-fns";
import { BASE_URL } from "../apis/url_constants";
import { phoneRegEx, webRegEx } from "./constants";

export const getUserDetails = () => {
  const details = JSON.parse(sessionStorage.getItem("state"));
  if (
    details &&
    details.user_details &&
    details.user_details.data &&
    details.user_details.data[0]
  )
    return details.user_details.data[0];
  return details;
};

export const isUserVendor = () => {
  const details = getUserDetails();

  if (!details) return false;

  return details.is_vendor;
};

export const validPhone = (str) => {
  if (!str) return;
  return str.match(phoneRegEx);
};

export const validUrl = (str) => {
  if (!str) return;
  console.log("url", str, str.match(webRegEx));
  return str.match(webRegEx);
};

export const getImageFromPath = (path) => {
  return `${BASE_URL}${path}`;
};

export const formatCurrency = (value) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0,
  }).format(value);
};

export const FormatDate = (date) => {
  return format(new Date(date), "dd-MM-yyyy");
};
