import axios from "axios";
import { BOOKMARK } from "../url_constants";
import { useMutation } from "@tanstack/react-query";

const addBookmark = (data) => {
  const config = {
    method: "POST",
    url: BOOKMARK,
    headers: {
      //   Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjY3OTMwOTM4LCJpYXQiOjE2Njc5MzA2MzgsImp0aSI6IjVjN2JjMzczYTA5YzRiYzFiZWY0ZmM1NWI0YmZiNzI4IiwidXNlcl9pZCI6MX0.UdL9RJv852aJB0hRFBG1CJ-PIEj37DnyPjOrXc4WB7g`,
    },
    data: data,
  };

  return axios(config).then().catch();
};

const deleteBookmark = (data) => {
  const config = {
    method: "DELETE",
    url: BOOKMARK,
    headers: {
      //   Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjY3OTMwOTM4LCJpYXQiOjE2Njc5MzA2MzgsImp0aSI6IjVjN2JjMzczYTA5YzRiYzFiZWY0ZmM1NWI0YmZiNzI4IiwidXNlcl9pZCI6MX0.UdL9RJv852aJB0hRFBG1CJ-PIEj37DnyPjOrXc4WB7g`,
    },
    data: data,
  };

  return axios(config).then().catch();
};

export const useAddBookmark = (options) => {
  return useMutation({
    mutationFn: (v) => addBookmark(v),
    mutationKey: ["add-bookmark"],
    ...options,
  });
};

export const useDeleteBookmark = (options) => {
  return useMutation({
    mutationFn: (v) => deleteBookmark(v),
    mutationKey: ["delete-bookmark"],
    ...options,
  });
};
