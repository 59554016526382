import { toast } from 'react-toastify';
import { getUserData } from '../../../apis/user_apis/user_data';
import * as actionTypes from '../actionTypes';

export const getUserDataSuccess = (data) => {
  return {
    type: actionTypes.GET_USER_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getUserDataStart = () => {
  return {
    type: actionTypes.GET_USER_DETAILS_START,
  };
};

export const getUserDataFail = (err) => {
  return {
    type: actionTypes.GET_USER_DETAILS_FAIL,
    payload: err,
  };
};

export const getUserDataAction = (data, callback) => {
  return function (dispatch) {
    getUserDataStart();
    getUserData(data)
      .then((res) => {
        dispatch(getUserDataSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getUserDataFail(err));
        // toast.error('Something went wrong !!');
      })
      .finally(() => {
        if (callback) callback();
      });
  };
};
