import { combineReducers } from "redux";
import getUserDetailsReducer from "./userReducer/user_data";
import registerReducer from "./userReducer/register";
import loginReducer from "./userReducer/login";
import logoutReducer from "./userReducer/logout";

const appReducer = combineReducers({
  tokens: loginReducer,
  user_details: getUserDetailsReducer,
  register: registerReducer,
  logout: logoutReducer,
});

const rootReducer = (state, action) => {
  //! Change to === LOGOUT_SUCCESS when logout API is fixed
  if (action.type.includes("LOGOUT")) {
    state = undefined;
    window.location.replace("/");
    localStorage.removeItem("store");
  }
  return appReducer(state, action);
};

export default rootReducer;
