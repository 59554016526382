import axios from "axios";
import { FEEDBACK } from "../url_constants";
import { useQuery } from "@tanstack/react-query";

const getFeedback = (id) => {
  const config = {
    url: `${FEEDBACK}?listing_id=${id}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axios(config).then().catch();
};

export const useGetFeedback = (id, options) => {
  return useQuery({
    ...options,
    queryFn: () => getFeedback(id),
    queryKey: ["feedback", id],
  });
};
