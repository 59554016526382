import React , {useState} from "react";
import Footer from "./footer";
import Header from "./header";
import { useLocation, useParams } from "react-router-dom";
function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("./../images", false, /\.(png|jpe?g|svg)$/)
);

const ListDetails = () => {
    const location2 = useLocation();
    const {posts} = location2.state;
    // let { text } = location.state;
    const [first, setfirst] = useState([posts]);

    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [cities, setCities] = useState([]);
    const [openingHours, setOpeningHours] = useState();
    const [amenitiesList, setAmenitiesList] = useState([]);
    const [data, setData] = useState({});

    //console.log(posts);
    //console.log(first);
    // const [posts, setPosts] = useState([]);
    // const updatedPost = async() => {
    //     const url = `${BASE_URL}/search/search?data=${text}`; 
    //     let data = await fetch(url); 
    //     let parsedData = await data.json();
    //     setPosts(parsedData)
    //   } 

    //   useEffect(() => {
    //     updatedPost();
    //   }, []);

    //   console.log(text);

  return (
    <>
      <Header />

      <section className="breadcrumb-area bread-bg bread-overlay overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-content d-flex flex-wrap align-items-center justify-content-between">
                <div className="section-heading">
                  <h2 className="sec__title text-white font-size-40 mb-0">
                    Listing Grid
                  </h2>
                </div>
                <ul className="list-items bread-list">
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>Listings</li>
                  <li>Listing Grid</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bread-svg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="50px"
            viewBox="0 0 1200 150"
            preserveAspectRatio="none"
          >
            <g>
              <path
                fillOpacity="0.2"
                d="M0,150 C600,100 1000,50 1200,-1.13686838e-13 C1200,6.8027294 1200,56.8027294 1200,150 L0,150 Z"
              ></path>
            </g>
            <g
              className="pix-waiting animated"
              data-anim-type="fade-in-up"
              data-anim-delay="300"
            >
              <path
                fill="rgba(255,255,255,0.8)"
                d="M0,150 C600,120 1000,80 1200,30 C1200,36.8027294 1200,76.8027294 1200,150 L0,150 Z"
              ></path>
            </g>
            <path
              fill="#fff"
              d="M0,150 C600,136.666667 1000,106.666667 1200,60 C1200,74 1200,104 1200,150 L0,150 Z"
            ></path>
            <defs></defs>
          </svg>
        </div>
      </section>
      {/* header navbar */}



      {/* <section className="section-padding">
        <div className="container">
          <div className="row">
            {first.map((ele, index)=>{
                return (
                  <>
                    <div className="col-lg-3 responsive-column">
                      <div className="card mb-3">
                        <img src={a} className="card-img-top" alt="..." />

                        <div className="card-body">
                            
                        </div>
                      </div>
                    </div>

                    <div className="col-lg responsive-column">
                      <div className="card mb-3">
                        <div className="card-body">
                          <h5 className="card-title">
                            {ele.name}
                            <i
                              className="la la-check-circle ml-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Claimed"
                            ></i>
                          </h5>

                          <p className="card-sub text-truncate ">
                            <a href="/">
                              <i className=" la la-map-marker mr-1 text-color-2"></i>
                              {ele.address}
                            </a>
                          </p>
                          <p className="card-text">
                            <small className="text-muted">
                              Last updated 3 mins ago
                            </small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }
            )}
          </div>
        </div>
      </section> */}


      <Footer />
    </>
  );
};

export default ListDetails;

