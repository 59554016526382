import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import Thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";

const saveToLocalStorage = (state) => {
  const serializedState = JSON.stringify(state);
  sessionStorage.setItem("state", serializedState);
};

const loadFromLocalStorage = () => {
  const serializedState = localStorage.getItem("state");
  if (serializedState === null) return undefined;
  return JSON.parse(serializedState);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedState = loadFromLocalStorage();

const store = createStore(
  rootReducer,
  persistedState,
  composeEnhancers(applyMiddleware(Thunk))
);

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
