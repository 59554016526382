import axios from "axios";
import { LOGOUT_URL } from "../url_constants";

export const logoutUser = async (token) => {
  const config = {
    url: `${LOGOUT_URL}`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return axios(config).then().catch();
};
