import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { GET_PRICE } from "../url_constants";

const getPrice = async (data) => {
  const result = await axios.post(GET_PRICE, {
    ...data,
  });
  return result.data;
};

export const useGetPrice = (options) => {
  return useMutation({
    mutationFn: getPrice,
    mutationKey: ["get-price"],
    ...options,
  });
};
