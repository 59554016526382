import { CircularProgress } from "@mui/material";
import { getUserDetails } from "../../utils/common";
import { UserBookings } from "./user-bookings";
import { AdminBooking } from "./admin-bookings";

export const BookingMolecule = () => {
  const user = getUserDetails();

  if (!user) {
    return <CircularProgress />;
  }

  if (user.is_vendor) {
    return <AdminBooking />;
  } else {
    return <UserBookings />;
  }
};
