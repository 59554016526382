import Hotel from "../../images/hotel.jpg";
import { FormatDate } from "../../utils/common";
import { Card } from "../card";

const totalRatings = [1, 2, 3, 4, 5];

export const ShowFeedback = ({ feedbacks }) => {
  const isRatingSelected = (rating, feedback) => {
    console.log("ratig", rating, feedback);
    return !(parseInt(feedback?.rating) >= parseInt(rating));
  };

  return (
    <Card title={"Reviews"}>
      <div className="comments-list">
        {feedbacks?.map((feedback, idx) => {
          return (
            <div key={idx} className="comment">
              <div className="user-thumb user-thumb-lg flex-shrink-0">
                <img src={Hotel} alt="author-img" />
              </div>
              <div className="comment-body">
                <div className="meta-data d-flex align-items-center justify-content-between">
                  <div>
                    <h4 className="comment__title">
                      {feedback.first_name} {feedback.last_name}
                    </h4>
                  </div>
                  <div className="star-rating-wrap text-center">
                    <div className="star-rating text-gray font-size-18">
                      {totalRatings.map((t) => {
                        return (
                          <span
                            className={
                              isRatingSelected(t, feedback)
                                ? ""
                                : "text-color-5"
                            }
                          >
                            <i className="la la-star"></i>
                          </span>
                        );
                      })}
                    </div>
                    <p className="font-size-13 font-weight-medium">
                      {FormatDate(feedback.rated_on)}
                    </p>
                  </div>
                </div>
                <p className="comment-desc">{feedback?.comments}</p>
              </div>
            </div>
          );
        })}
      </div>
    </Card>
  );
};
