import React, { useState } from "react";
import Footer from "../../components/footer";
import SvgImage from "../../images/svg-image.svg";
import Hotel from "../../images/hotel.jpg";
import "./ListingDetails.css";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { getListings } from "../../apis/listing_apis/get_listing";
import { getAmenities } from "../../apis/listing_apis/amenities";
import { getCities } from "../../apis/listing_apis/get_cities";
import ReactMap from "../../components/Map/Map";
import { toast } from "react-toastify";
import { Gallery } from "../../components/gallery";
import { Card } from "../../components/card";
import { PriceSelector } from "./price-selector";
import { Feedback } from "../../components/feedback";
import { ShowRatings } from "../../components/feedback/show-ratings";
import { useGetFeedback } from "../../apis/feedback/use-get-feedback";
import { ShowFeedback } from "../../components/feedback/show-feedback";

const ListingDetails = () => {
  const { id } = useParams();
  const [cities, setCities] = useState([]);
  const [openingHours, setOpeningHours] = useState();
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [data, setData] = useState({});

  const { data: feedbacksData, isLoading: loadingFeedbacks } = useGetFeedback(
    parseInt(id),
    {
      enabled: !!id,
    }
  );

  const feedbacks = feedbacksData?.data?.data;

  const fetchList = () => {
    getListings(null, id, null)
      .then((res) => {
        if (res.status === 200) {
          console.log(res?.data[0]);
          setData(res?.data[0]);
          setOpeningHours(JSON.parse(res?.data[0].opening_hours));
        }
      })
      .catch((error) => {
        toast.error("Something went wrong !!");
        console.log(error);
      });
  };

  const getCity = () => {
    if (cities.length > 0) {
      let obj = cities.find((city) => data.city_id === city.value);

      return `${obj?.label}, ${obj?.state}`;
    }
  };

  const getOpeningHoursList = () => {
    const all = openingHours["All"];
    let res;
    const days = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    res = days.map((day) => {
      const value = openingHours[day];
      return (
        <li className="d-flex justify-content-between">
          {day}{" "}
          <span>
            {value[0]} - {value[1]}
          </span>
        </li>
      );
    });

    return res;
  };

  const getOpeningHours = (openingHours) => {
    try {
      const time = JSON.parse(openingHours);
      // const monday = time['Monday'];
      // if (!monday) return '-';
      // return `${monday[0]} - ${monday[1]}`;
    } catch (e) {
      console.log(e);
    } finally {
      return "9 am - 9 pm";
    }
  };

  const getTags = (data) => {
    const tags = JSON.parse(data);
    const arr = tags.map((tag, index) => (
      <li key={index}>
        <a href="">{tag}</a>
      </li>
    ));

    return arr;
  };

  const displayAmenities = () => {
    try {
      const list = JSON.parse(data.amenities);
      console.log(list[0]);

      return list.map((id) => {
        const itemId = parseInt(id, 10);
        const value = amenitiesList.find((item) => item.id === itemId);
        return <li className="col-lg-4">{value.name}</li>;
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchList();
    getAmenities()
      .then((res) => {
        setAmenitiesList(res.data);
      })
      .catch((err) => {
        toast.error("Something went wrong !!");
        console.log(err);
      });
    getCities()
      .then((res) => {
        if (res.status === 200) {
          let structure = [];
          if (res?.data) {
            res.data.map((value) => {
              structure.push({
                label: value?.name,
                value: value?.id,
                state: value?.state__name,
              });
            });
          }
          setCities(structure);
        }
      })
      .catch((err) => {
        toast.error("Something went wrong !!");
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Gallery images={data.images} />
      <section className="breadcrumb-area bg-gradient-gray py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-content breadcrumb-content-2 d-flex flex-wrap align-items-end justify-content-between">
                <div className="section-heading">
                  <div className="d-flex align-items-center pt-1">
                    <h2 className="sec__title mb-0">{data.name}</h2>
                    <div className="hover-tooltip-box ml-2 pt-2">
                      <span className="text-color">
                        <i className="la la-check-circle mr-1 text-color-4"></i>
                        Claimed
                      </span>
                      <div className="hover-tooltip">
                        <p>
                          This business has been claimed by the owner or a
                          representative. <a href="">Learn more</a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <p className="sec__desc py-2 font-size-17">
                    <i className="la la-map-marker mr-1 text-color-2"></i>
                    {getCity()}
                  </p>
                  <p className="pb-2 font-weight-medium">
                    <span
                      className="price-range mr-1 text-color font-size-16"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Moderate"
                    >
                      <strong className="font-weight-medium">$</strong>
                      <strong className="font-weight-medium ml-n1">$</strong>
                    </span>
                    <span className="category-link">
                      <a href="">Burger</a>,
                      <a href="">American (Traditional)</a>
                    </span>
                  </p>
                  <div className="d-flex flex-wrap align-items-center">
                    <div className="star-rating-wrap d-flex align-items-center ">
                      <div className="star-rating text-color-5 font-size-18">
                        <span>
                          <i className="la la-star"></i>
                        </span>
                        <span className="ml-n1">
                          <i className="la la-star"></i>
                        </span>
                        <span className="ml-n1">
                          <i className="la la-star"></i>
                        </span>
                        <span className="ml-n1">
                          <i className="la la-star"></i>
                        </span>
                        <span className="ml-n1">
                          <i className="la la-star"></i>
                        </span>
                      </div>
                      <p className="font-size-14 pl-2 font-weight-medium mb-0">
                        1348 reviews
                      </p>
                    </div>
                    <div className="timestamp font-weight-medium ml-3 pl-3 border-left border-left-color line-height-20">
                      <span className="text-color-4 mr-2">Open:</span>
                      <span>
                        {data.opening_hours &&
                          getOpeningHours(data.opening_hours)}
                      </span>
                    </div>
                  </div>
                  <div className="btn-box pt-3">
                    <a href="#review" className="btn-gray mr-1">
                      <i className="la la-star mr-1"></i>Write a Review
                    </a>
                    <a href="" className="btn-gray mr-1">
                      <i className="la la-bookmark mr-1"></i>Save
                    </a>
                    <a
                      href=""
                      className="btn-gray"
                      data-toggle="modal"
                      data-target="#shareModal"
                    >
                      <i className="la la-external-link mr-1"></i>Share
                    </a>
                  </div>
                </div>
                <div className="btn-box d-flex align-items-center">
                  <span className="btn-gray mr-2">
                    <i className="la la-eye mr-1"></i>Viewed - 255
                  </span>
                  <span className="btn-gray mr-2">
                    <i className="la la-heart mr-1"></i>Saved - 124
                  </span>
                  <div className="dropdown dot-action-wrap">
                    <button
                      className="dot-action-btn dropdown-toggle after-none border-0"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="la la-ellipsis-v"></i>
                    </button>
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a
                        className="dropdown-item"
                        href=""
                        data-toggle="modal"
                        data-target="#reportModal"
                      >
                        <i className="la la-flag mr-1"></i>Report
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* listing detail area */}

      <section className="listing-detail-area padding-top-40px pb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="listing-detail-wrap">
                {data ? <PriceSelector listing={data} /> : null}
                <Card title="Listing Description">
                  <>
                    <p className="pb-3 font-weight-medium line-height-30">
                      {data.description}
                    </p>
                    <div
                      className="collapse collapse-content"
                      id="showMoreOptionCollapse"
                    >
                      <p className="font-weight-medium line-height-30 pb-3">
                        Porpoise uncritical gosh and much and this haughtily
                        broadcast goodness ponderous squid darn in sheepish yet
                        the slapped mildly and adventurously sincere less
                        dalmatian assentingly wherever left ethereal the
                        underneath oh lustily arduously that a groggily some
                        vexedly broadcast sheepish yet the slapped.
                      </p>
                    </div>
                  </>
                </Card>
                <Card title="Amenities">
                  <ul className="list-items row">
                    {data &&
                      !!data.amenities?.length &&
                      !!amenitiesList?.length &&
                      displayAmenities()}
                  </ul>
                </Card>
                <div className="block-card mb-4">
                  <div className="block-card-header">
                    <h2 className="widget-title">Promo Video</h2>
                    <div className="stroke-shape"></div>
                  </div>
                  <div className="block-card-body">
                    <div className="video-box">
                      <img
                        className="lazy"
                        src={Hotel}
                        data-src="images/single-listing-img7.jpg"
                        alt="Hotel"
                      />
                      <div className="video-content">
                        <a
                          className="icon-element icon-element-lg icon-element-white hover-scale mx-auto"
                          href="https://www.youtube.com/watch?v=GlrxcuEDyF8"
                          data-fancybox=""
                          title="Play Video"
                        >
                          <i className="la la-play"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="block-card mb-4">
                  <div className="block-card-header">
                    <h2 className="widget-title">Location / Contact</h2>
                    <div className="stroke-shape"></div>
                  </div>
                  {/* <ErrorBoundary
                    fallback={() => {
                      return <div>Something went wrong !!</div>;
                    }}
                  > */}
                  {/* </ErrorBoundary> */}
                  <div className="block-card-body">
                    <div className="map-container height-400">
                      <div id="map">
                        {data.longitude && (
                          <ReactMap
                            viewOnly={true}
                            longitude={data?.longitude}
                            latitude={data?.latitude}
                          />
                        )}
                      </div>
                    </div>
                    <ul className="list-items list--items list-items-style-2 py-4">
                      <li>
                        <span className="text-color">
                          <i className="la la-map mr-2 text-color-2 font-size-18"></i>
                          Address:
                        </span>{" "}
                        {data.address}
                      </li>
                      <li>
                        <span className="text-color">
                          <i className="la la-phone mr-2 text-color-2 font-size-18"></i>
                          Phone:
                        </span>
                        <a href="">{data.phone_number}</a>
                      </li>
                      {/* <li>
                        <span className="text-color">
                          <i className="la la-envelope mr-2 text-color-2 font-size-18"></i>
                          Email:
                        </span>
                        <a href="">Uniworkpass@gmail.com</a>
                      </li> */}
                      <li>
                        <span className="text-color">
                          <i className="la la-globe mr-2 text-color-2 font-size-18"></i>
                          Website:
                        </span>
                        <a href="">{data.web}</a>
                      </li>
                    </ul>
                    <ul className="social-profile social-profile-styled">
                      <li>
                        <a
                          href={data.facebook_link}
                          className="facebook-bg"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Facebook"
                        >
                          <i className="lab la-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href={data.twitter_link}
                          className="twitter-bg"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Twitter"
                        >
                          <i className="lab la-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href={data.insta_link}
                          className="instagram-bg"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Instagram"
                        >
                          <i className="lab la-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href={data.youtube_link}
                          className="youtube-bg"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Youtube"
                        >
                          <i className="la la-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="block-card mb-4">
                  <div className="block-card-header">
                    <h2 className="widget-title">Questions & Answers</h2>
                    <div className="stroke-shape"></div>
                  </div>
                  <div className="block-card-body">
                    <div className="accordion-item" id="accordion">
                      <div className="card">
                        <div className="card-header" id="headingOne">
                          <h5>
                            <button
                              className="btn btn-link d-flex align-items-center justify-content-between"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Once this list has been developed, the member
                              takes the clients?
                              <i className="la la-minus"></i>
                              <i className="la la-plus"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseOne"
                          className="collapse show"
                          aria-labelledby="headingOne"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <p>
                              Anim pariatur cliche reprehenderit, enim eiusmod
                              high life accusamus terry richardson ad squid. 3
                              wolf moon officia aute, non cupidatat skateboard
                              dolor brunch. Food truck quinoa nesciunt laborum
                              eiusmod.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h5>
                            <button
                              className="btn btn-link d-flex align-items-center justify-content-between"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="false"
                              aria-controls="collapseTwo"
                            >
                              A cooling off period is usually where one party -
                              usually?
                              <i className="la la-minus"></i>
                              <i className="la la-plus"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse"
                          aria-labelledby="headingTwo"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <p>
                              Anim pariatur cliche reprehenderit, enim eiusmod
                              high life accusamus terry richardson ad squid. 3
                              wolf moon officia aute, non cupidatat skateboard
                              dolor brunch. Food truck quinoa nesciunt laborum
                              eiusmod.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-header" id="headingThree">
                          <h5>
                            <button
                              className="btn btn-link d-flex align-items-center justify-content-between"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="false"
                              aria-controls="collapseThree"
                            >
                              It is also import to take note of the prevailing
                              market conditions?
                              <i className="la la-minus"></i>
                              <i className="la la-plus"></i>
                            </button>
                          </h5>
                        </div>
                        <div
                          id="collapseThree"
                          className="collapse"
                          aria-labelledby="headingThree"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <p>
                              Anim pariatur cliche reprehenderit, enim eiusmod
                              high life accusamus terry richardson ad squid. 3
                              wolf moon officia aute, non cupidatat skateboard
                              dolor brunch. Food truck quinoa nesciunt laborum
                              eiusmod.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ShowRatings feedbacks={feedbacks} />
                <ShowFeedback feedbacks={feedbacks} />
                <Feedback feedbacks={feedbacks} />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar mb-0">
                <div className="sidebar-widget">
                  <div className="d-flex align-items-center pb-3">
                    <img src={SvgImage} alt="svgimage" />
                    <h3 className="widget-title pb-0 line-height-26">
                      Only Quality, Fresh Ingredients Go Into Our Food!
                    </h3>
                  </div>
                  <a href="" className="theme-btn gradient-btn w-100">
                    Learn More<i className="la la-arrow-right ml-2"></i>
                  </a>
                </div>
                <div className="sidebar-widget">
                  <h3 className="widget-title">General Information</h3>
                  <div className="stroke-shape mb-4"></div>
                  <ul className="list-items list-items-style-2">
                    <li>
                      <i className="la la-external-link mr-2 text-color-2 font-size-18"></i>
                      <a href="">{data.web}</a>
                    </li>
                    <li>
                      <i className="la la-phone mr-2 text-color-2 font-size-18"></i>
                      <a href="">{data.phone_number}</a>
                    </li>
                    {/* <li>
                      <i className="la la-map-signs mr-2 text-color-2 font-size-18"></i>
                      <a href="">Get Directions</a>
                    </li>
                    <li>
                      <i className="la la-cutlery mr-2 text-color-2 font-size-18"></i>
                      <a href="">
                        Full menu <i className="la la-external-link ml-1"></i>
                      </a>
                    </li>
                    <li>
                      <i className="la la-comment mr-2 text-color-2 font-size-18"></i>
                      <a
                        href=""
                        data-toggle="modal"
                        data-target="#messageModal"
                      >
                        Message the Business
                      </a>
                    </li> */}
                  </ul>
                </div>
                <div className="sidebar-widget">
                  <h3 className="widget-title">Opening Hours</h3>
                  <div className="stroke-shape mb-4"></div>
                  <ul className="list-items">
                    {openingHours && getOpeningHoursList()}
                    {/* <li className="d-flex justify-content-between">
                      Monday <span>9am - 5pm</span>
                    </li>
                    <li className="d-flex justify-content-between">
                      Tuesday <span>9am - 5pm</span>
                    </li>
                    <li className="d-flex justify-content-between">
                      Wednesday <span>9am - 5pm</span>
                    </li>
                    <li className="d-flex justify-content-between">
                      Thursday <span>9am - 5pm</span>
                    </li>
                    <li className="d-flex justify-content-between">
                      Friday <span>9am - 5am</span>
                    </li>
                    <li className="d-flex justify-content-between">
                      Saturday <span>9am - 5am</span>
                    </li>
                    <li className="d-flex justify-content-between">
                      Sunday <span className="text-color-2">Closed</span>
                    </li> */}
                  </ul>
                </div>
                <div className="card-item card-item-layout-4">
                  <a href="" className="card-image d-block">
                    <img src={Hotel} className="card__img" alt="" />
                    <span className="badge gradient-btn font-size-16 font-weight-semi-bold">
                      50% off
                    </span>
                    <div className="card-content">
                      <h4 className="card-title pb-3">
                        Order 1 burger and get 50% off on second!
                      </h4>
                      <span className="bg-white text-color py-1 px-2 rounded font-size-15 font-weight-medium">
                        Expires 14/10/2020
                      </span>
                    </div>
                  </a>
                </div>
                <div className="sidebar-widget">
                  <h3 className="widget-title">Categories</h3>
                  <div className="stroke-shape mb-4"></div>
                  <div className="category-list">
                    <a
                      href=""
                      className="generic-img-card d-block hover-y overflow-hidden mb-3"
                    >
                      <img
                        src={Hotel}
                        height="40"
                        data-src="images/generic-small-img.jpg"
                        alt="image"
                        className="generic-img-card-img lazy"
                      />
                      <div className="generic-img-card-content d-flex align-items-center justify-content-between">
                        <span className="badge">Restaurants</span>
                        <span className="generic-img-card-counter">238</span>
                      </div>
                    </a>
                    <a
                      href=""
                      className="generic-img-card d-block hover-y overflow-hidden mb-3"
                    >
                      <img
                        src={Hotel}
                        height="40"
                        data-src="images/generic-small-img-2.jpg"
                        alt="image"
                        className="generic-img-card-img lazy"
                      />
                      <div className="generic-img-card-content d-flex align-items-center justify-content-between">
                        <span className="badge">Food</span>
                        <span className="generic-img-card-counter">111</span>
                      </div>
                    </a>
                    <a
                      href=""
                      className="generic-img-card d-block hover-y overflow-hidden mb-3"
                    >
                      <img
                        src={Hotel}
                        height="40"
                        data-src="images/generic-small-img-3.jpg"
                        alt="image"
                        className="generic-img-card-img lazy"
                      />
                      <div className="generic-img-card-content d-flex align-items-center justify-content-between">
                        <span className="badge">Hotel</span>
                        <span className="generic-img-card-counter">222</span>
                      </div>
                    </a>
                    <a
                      href=""
                      className="generic-img-card d-block hover-y overflow-hidden mb-3"
                    >
                      <img
                        src={Hotel}
                        height="40"
                        data-src="images/generic-small-img-4.jpg"
                        alt="image"
                        className="generic-img-card-img lazy"
                      />
                      <div className="generic-img-card-content d-flex align-items-center justify-content-between">
                        <span className="badge">Events</span>
                        <span className="generic-img-card-counter">123</span>
                      </div>
                    </a>
                    <div
                      className="collapse collapse-content"
                      id="showMoreOptionCollapse2"
                    >
                      <a
                        href=""
                        className="generic-img-card d-block hover-y overflow-hidden mb-3"
                      >
                        <img
                          src={Hotel}
                          height="40"
                          data-src="images/generic-small-img-5.jpg"
                          alt="image"
                          className="generic-img-card-img lazy"
                        />
                        <div className="generic-img-card-content d-flex align-items-center justify-content-between">
                          <span className="badge">Shopping</span>
                          <span className="generic-img-card-counter">321</span>
                        </div>
                      </a>
                      <a
                        href=""
                        className="generic-img-card d-block hover-y overflow-hidden mb-3"
                      >
                        <img
                          src={Hotel}
                          height="40"
                          data-src="images/generic-small-img-6.jpg"
                          alt="image"
                          className="generic-img-card-img lazy"
                        />
                        <div className="generic-img-card-content d-flex align-items-center justify-content-between">
                          <span className="badge">Travel</span>
                          <span className="generic-img-card-counter">12</span>
                        </div>
                      </a>
                      <a
                        href=""
                        className="generic-img-card d-block hover-y overflow-hidden mb-3"
                      >
                        <img
                          src={Hotel}
                          height="40"
                          data-src="images/generic-small-img-7.jpg"
                          alt="image"
                          className="generic-img-card-img lazy"
                        />
                        <div className="generic-img-card-content d-flex align-items-center justify-content-between">
                          <span className="badge">Jobs</span>
                          <span className="generic-img-card-counter">133</span>
                        </div>
                      </a>
                    </div>
                    <a
                      className="collapse-btn"
                      data-toggle="collapse"
                      href="#showMoreOptionCollapse2"
                      role="button"
                      aria-expanded="false"
                      aria-controls="showMoreOptionCollapse2"
                    >
                      <span className="collapse-btn-hide">
                        Show More <i className="la la-plus ml-1"></i>
                      </span>
                      <span className="collapse-btn-show">
                        Show Less <i className="la la-minus ml-1"></i>
                      </span>
                    </a>
                  </div>
                </div>
                <div className="sidebar-widget">
                  <h3 className="widget-title">Tag Clouds</h3>
                  <div className="stroke-shape mb-4"></div>
                  <ul className="tag-list">
                    {data.tags && getTags(data.tags)}
                  </ul>
                </div>
                <div className="sidebar-widget">
                  <div className="section-tab section-tab-layout-2 mb-4">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          id="popular-tab"
                          data-toggle="tab"
                          href="#popular"
                          role="tab"
                          aria-controls="popular"
                          aria-selected="true"
                        >
                          Popular
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          id="recent-tab"
                          data-toggle="tab"
                          href="#recent"
                          role="tab"
                          aria-controls="recent"
                          aria-selected="false"
                        >
                          Recent
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="popular"
                      role="tabpanel"
                      aria-labelledby="popular-tab"
                    >
                      <div className="mini-list-card">
                        <div className="mini-list-img">
                          <Link to="/listing-details/id" className="d-block">
                            <img src={Hotel} alt="similar listing image" />
                          </Link>
                        </div>
                        <div className="mini-list-body">
                          <h4 className="mini-list-title">
                            <a href="listing-details.html">Mel’s Drive-In</a>
                          </h4>
                          <div className="star-rating-wrap d-flex align-items-center">
                            <div className="star-rating text-color-5 font-size-16">
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                            </div>
                            <p className="font-size-14 pl-2 font-weight-medium">
                              1348 reviews
                            </p>
                          </div>
                          <span className="category-link after-none pl-0 font-size-14 font-weight-medium">
                            <a href="">Dinner</a>,<a href="">Breakfast</a>
                          </span>
                        </div>
                      </div>
                      <div className="mini-list-card">
                        <div className="mini-list-img">
                          <Link to="/listing-details/id" className="d-block">
                            <img src={Hotel} alt="similar listing image" />
                          </Link>
                        </div>
                        <div className="mini-list-body">
                          <h4 className="mini-list-title">
                            <a href="listing-details.html">Soma Pizza</a>
                          </h4>
                          <div className="star-rating-wrap d-flex align-items-center">
                            <div className="star-rating text-color-5 font-size-16">
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                            </div>
                            <p className="font-size-14 pl-2 font-weight-medium">
                              1348 reviews
                            </p>
                          </div>
                          <span className="category-link after-none pl-0 font-size-14 font-weight-medium">
                            <a href="">Pizza</a>,<a href="">Burgers</a>,
                            <a href="">Fast food</a>
                          </span>
                        </div>
                      </div>
                      <div className="mini-list-card">
                        <div className="mini-list-img">
                          <Link to="/listing-details/id" className="d-block">
                            <img src={Hotel} alt="similar listing image" />
                          </Link>
                        </div>
                        <div className="mini-list-body">
                          <h4 className="mini-list-title">
                            <a href="listing-details.html">
                              Pearl’s Deluxe Burgers
                            </a>
                          </h4>
                          <div className="star-rating-wrap d-flex align-items-center">
                            <div className="star-rating text-color-5 font-size-16">
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                            </div>
                            <p className="font-size-14 pl-2 font-weight-medium">
                              1348 reviews
                            </p>
                          </div>
                          <span className="category-link after-none pl-0 font-size-14 font-weight-medium">
                            <a href="">Breakfast</a>
                          </span>
                        </div>
                      </div>
                      <div className="mini-list-card">
                        <div className="mini-list-img">
                          <Link to="/listing-details/id" className="d-block">
                            <img src={Hotel} alt="similar listing image" />
                          </Link>
                        </div>
                        <div className="mini-list-body">
                          <h4 className="mini-list-title">
                            <a href="listing-details.html">Box Kitchen</a>
                          </h4>
                          <div className="star-rating-wrap d-flex align-items-center">
                            <div className="star-rating text-color-5 font-size-16">
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star-o"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star-o"></i>
                              </span>
                            </div>
                            <p className="font-size-14 pl-2 font-weight-medium">
                              1348 reviews
                            </p>
                          </div>
                          <span className="category-link after-none pl-0 font-size-14 font-weight-medium">
                            <a href="">Food Stands</a>,<a href="">Burgers</a>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="recent"
                      role="tabpanel"
                      aria-labelledby="recent-tab"
                    >
                      <div className="mini-list-card">
                        <div className="mini-list-img">
                          <a href="listing-details.html" className="d-block">
                            <img
                              src="images/single-listing-small-img3.jpg"
                              alt="similar listing image"
                            />
                          </a>
                        </div>
                        <div className="mini-list-body">
                          <h4 className="mini-list-title">
                            <a href="listing-details.html">
                              Pearl’s Deluxe Burgers
                            </a>
                          </h4>
                          <div className="star-rating-wrap d-flex align-items-center">
                            <div className="star-rating text-color-5 font-size-16">
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                            </div>
                            <p className="font-size-14 pl-2 font-weight-medium">
                              1348 reviews
                            </p>
                          </div>
                          <span className="category-link after-none pl-0 font-size-14 font-weight-medium">
                            <a href="">Breakfast</a>
                          </span>
                        </div>
                      </div>
                      <div className="mini-list-card">
                        <div className="mini-list-img">
                          <a href="listing-details.html" className="d-block">
                            <img
                              src="images/single-listing-small-img4.jpg"
                              alt="similar listing image"
                            />
                          </a>
                        </div>
                        <div className="mini-list-body">
                          <h4 className="mini-list-title">
                            <a href="listing-details.html">Box Kitchen</a>
                          </h4>
                          <div className="star-rating-wrap d-flex align-items-center">
                            <div className="star-rating text-color-5 font-size-16">
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star-o"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star-o"></i>
                              </span>
                            </div>
                            <p className="font-size-14 pl-2 font-weight-medium">
                              1348 reviews
                            </p>
                          </div>
                          <span className="category-link after-none pl-0 font-size-14 font-weight-medium">
                            <a href="">Food Stands</a>,<a href="">Burgers</a>
                          </span>
                        </div>
                      </div>
                      <div className="mini-list-card">
                        <div className="mini-list-img">
                          <a href="listing-details.html" className="d-block">
                            <img src={Hotel} alt="similar listing image" />
                          </a>
                        </div>
                        <div className="mini-list-body">
                          <h4 className="mini-list-title">
                            <a href="listing-details.html">Mel’s Drive-In</a>
                          </h4>
                          <div className="star-rating-wrap d-flex align-items-center">
                            <div className="star-rating text-color-5 font-size-16">
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                            </div>
                            <p className="font-size-14 pl-2 font-weight-medium">
                              1348 reviews
                            </p>
                          </div>
                          <span className="category-link after-none pl-0 font-size-14 font-weight-medium">
                            <a href="">Dinner</a>,<a href="">Breakfast</a>
                          </span>
                        </div>
                      </div>
                      <div className="mini-list-card">
                        <div className="mini-list-img">
                          <Link to="/listing-details/id" className="d-block">
                            <img src={Hotel} alt="similar listing image" />
                          </Link>
                        </div>
                        <div className="mini-list-body">
                          <h4 className="mini-list-title">
                            <a href="listing-details.html">Soma Pizza</a>
                          </h4>
                          <div className="star-rating-wrap d-flex align-items-center">
                            <div className="star-rating text-color-5 font-size-16">
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                            </div>
                            <p className="font-size-14 pl-2 font-weight-medium">
                              1348 reviews
                            </p>
                          </div>
                          <span className="category-link after-none pl-0 font-size-14 font-weight-medium">
                            <a href="">Pizza</a>,<a href="">Burgers</a>,
                            <a href="">Fast food</a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sidebar-widget">
                  <h3 className="widget-title pb-1">You Might Also Consider</h3>
                  <div className="hover-tooltip-box mb-4 d-inline-block">
                    <span className="text-gray">
                      Sponsored <i className="la la-exclamation-circle"></i>
                    </span>
                    <div className="hover-tooltip">
                      <p>
                        Business owners paid for these ads. For more information
                        visit <a href="">Uniworkpass for Business</a>.
                      </p>
                    </div>
                  </div>
                  <div className="sponsor-list">
                    <div className="sponsor-item mb-4">
                      <div className="mini-list-card">
                        <div className="mini-list-img">
                          <Link to="/listing-details/id" className="d-block">
                            <img src={Hotel} alt="similar listing image" />
                          </Link>
                        </div>
                        <div className="mini-list-body">
                          <h4 className="mini-list-title">
                            <a href="listing-details.html">Lers Ros Thai</a>
                          </h4>
                          <div className="star-rating-wrap d-flex align-items-center">
                            <div className="star-rating text-color-5 font-size-16">
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                            </div>
                            <p className="font-size-14 pl-2 font-weight-medium">
                              1348 reviews
                            </p>
                          </div>
                          <span className="category-link after-none pl-0 font-size-14 font-weight-bold text-color">
                            1.8 miles
                          </span>
                        </div>
                      </div>
                      <p className="font-size-14 font-weight-medium pt-2">
                        "I am taking away one star for terrible service. The
                        rant first: We made a…"
                        <a href="listing-details.html" className="text-color-2">
                          Read more
                        </a>
                      </p>
                    </div>
                    <div className="sponsor-item">
                      <div className="mini-list-card">
                        <div className="mini-list-img">
                          <Link to="/listing-details/id" className="d-block">
                            <img src={Hotel} alt="similar listing image" />
                          </Link>
                        </div>
                        <div className="mini-list-body">
                          <h4 className="mini-list-title">
                            <a href="listing-details.html">
                              Kam Lok Restaurant
                            </a>
                          </h4>
                          <div className="star-rating-wrap d-flex align-items-center">
                            <div className="star-rating text-color-5 font-size-16">
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                            </div>
                            <p className="font-size-14 pl-2 font-weight-medium">
                              1348 reviews
                            </p>
                          </div>
                          <span className="category-link after-none pl-0 font-size-14 font-weight-bold text-color">
                            1.8 miles
                          </span>
                        </div>
                      </div>
                      <p className="font-size-14 font-weight-medium pt-2">
                        "This place seems to be a favorite among locals. I came
                        here twice and was blown…"
                        <a href="listing-details.html" className="text-color-2">
                          Read more
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="sidebar-widget">
                  <h3 className="widget-title">Subscribe</h3>
                  <div className="stroke-shape mb-4"></div>
                  <form method="post" className="form-box">
                    <div className="form-group">
                      <span className="la la-envelope-o form-icon"></span>
                      <input
                        className="form-control form-control-styled"
                        type="text"
                        name="email"
                        placeholder="Enter your email"
                      />
                    </div>
                  </form>
                  <div className="btn-box">
                    <button
                      type="submit"
                      className="theme-btn gradient-btn w-100 border-0"
                    >
                      Subscribe Now<i className="la la-arrow-right ml-2"></i>
                    </button>
                    <span className="font-size-13 font-weight-medium">
                      You can unsubscribe at any time
                    </span>
                  </div>
                </div>
                <div className="sidebar-widget">
                  <h3 className="widget-title">Follow & Connect</h3>
                  <div className="stroke-shape mb-4"></div>
                  <div className="icon-block-wrap d-flex flex-wrap align-items-center ml-n1">
                    <a
                      href={data.facebook_link}
                      className="icon-block d-flex align-items-center flex-grow-1 hover-scale-2"
                    >
                      <span className="icon-element icon-element-sm f-bg-rgb">
                        <i className="la la-facebook"></i>
                      </span>
                      <div className="pl-2 flex-grow-1">
                        <span className="text-color font-weight-bold font-size-17 d-block line-height-20">
                          9,809
                        </span>
                        <span className="text-gray font-weight-medium font-size-13 d-block line-height-20">
                          Followers
                        </span>
                      </div>
                    </a>
                    <a
                      href={data.twitter_link}
                      className="icon-block d-flex align-items-center flex-grow-1 hover-scale-2"
                    >
                      <span className="icon-element icon-element-sm t-bg-rgb">
                        <i className="la la-twitter"></i>
                      </span>
                      <div className="pl-2 flex-grow-1">
                        <span className="text-color font-weight-bold font-size-17 d-block line-height-20">
                          789
                        </span>
                        <span className="text-gray font-weight-medium font-size-13 d-block line-height-20">
                          Followers
                        </span>
                      </div>
                    </a>
                    <a
                      href={data.insta_link}
                      className="icon-block d-flex align-items-center flex-grow-1 hover-scale-2"
                    >
                      <span className="icon-element icon-element-sm i-bg-rgb">
                        <i className="la la-instagram"></i>
                      </span>
                      <div className="pl-2 flex-grow-1">
                        <span className="text-color font-weight-bold font-size-17 d-block line-height-20">
                          12,809
                        </span>
                        <span className="text-gray font-weight-medium font-size-13 d-block line-height-20">
                          Followers
                        </span>
                      </div>
                    </a>
                    <a
                      href={data.youtube_link}
                      className="icon-block d-flex align-items-center flex-grow-1 hover-scale-2"
                    >
                      <span className="icon-element icon-element-sm y-bg-rgb">
                        <i className="la la-youtube"></i>
                      </span>
                      <div className="pl-2 flex-grow-1">
                        <span className="text-color font-weight-bold font-size-17 d-block line-height-20">
                          20,876
                        </span>
                        <span className="text-gray font-weight-medium font-size-13 d-block line-height-20">
                          Subscriber
                        </span>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="sidebar-widget">
                  <h3 className="widget-title">Hosted by</h3>
                  <div className="stroke-shape mb-4"></div>
                  <div className="hosted-by d-flex align-items-center">
                    <a
                      href="user-profile.html"
                      className="user-thumb user-thumb-md flex-shrink-0 mr-3"
                    >
                      <img src="images/avatar-img.jpg" alt="author-img" />
                    </a>
                    <div>
                      <h4 className="font-size-18">
                        <a href="user-profile.html" className="text-color">
                          Mark Hardson
                        </a>
                      </h4>
                      <span className="font-size-13 text-gray font-weight-medium d-block line-height-22">
                        20 listing hosted
                      </span>
                    </div>
                  </div>
                  <ul className="list-items py-4">
                    <li>
                      <i className="la la-phone mr-2 text-color-2 font-size-18"></i>
                      <a href="" className="before-none">
                        + 61 23 8093 3400
                      </a>
                    </li>
                    <li>
                      <i className="la la-envelope mr-2 text-color-2 font-size-18"></i>
                      <a href="" className="before-none">
                        Uniworkpass@gmail.com
                      </a>
                    </li>
                  </ul>
                  <div className="btn-box">
                    <a
                      href="user-profile.html"
                      className="theme-btn gradient-btn w-100"
                    >
                      <i className="la la-user mr-2"></i>View Profile
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* listing detail area */}

      {/* card area  */}

      <section className="card-area bg-gradient-gray py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading">
                <h2 className="sec__title font-size-24 line-height-30">
                  People Also Viewed
                </h2>
              </div>
            </div>
          </div>
          <div className="row pt-1">
            <div className="col-lg-12">
              <div className="card-carousel owl-trigger-action">
                <div className="card-item border border-color">
                  <div className="card-image">
                    <Link to="/listing-details/id" className="d-block">
                      <img src={Hotel} className="card__img" alt="" />
                      <span className="badge">now open</span>
                    </Link>
                    <span
                      className="bookmark-btn"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Save"
                    >
                      <i className="la la-bookmark"></i>
                    </span>
                  </div>
                  <div className="card-content">
                    <a
                      href=""
                      className="user-thumb d-inline-block"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="TechyDevs"
                    >
                      <img src={Hotel} alt="author-img" />
                    </a>
                    <h4 className="card-title pt-3">
                      <a href="listing-details.html">
                        Favorite Place Food Bank
                      </a>
                      <i
                        className="la la-check-circle ml-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Claimed"
                      ></i>
                    </h4>
                    <p className="card-sub">
                      <a href="">
                        <i className="la la-map-marker mr-1 text-color-2"></i>
                        Bishop Avenue, New York
                      </a>
                    </p>
                    <ul className="listing-meta d-flex align-items-center">
                      <li className="d-flex align-items-center">
                        <span className="rate flex-shrink-0">4.7</span>
                        <span className="rate-text">5 Ratings</span>
                      </li>
                      <li>
                        <span
                          className="price-range"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Pricey"
                        >
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                        </span>
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="la la-cutlery mr-1 listing-icon"></i>
                        <a href="" className="listing-cat-link">
                          Restaurant
                        </a>
                      </li>
                    </ul>
                    <ul className="info-list padding-top-20px">
                      <li>
                        <span className="la la-link icon"></span>
                        <a href=""> www.techydevs.com</a>
                      </li>
                      <li>
                        <span className="la la-calendar-check-o icon"></span>
                        Opened 1 month ago
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-item border border-color">
                  <div className="card-image">
                    <Link to="/listing-details/id" className="d-block">
                      <img src={Hotel} className="card__img" alt="" />
                      <span className="badge">now open</span>
                    </Link>
                    <span
                      className="bookmark-btn"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Save"
                    >
                      <i className="la la-bookmark"></i>
                    </span>
                  </div>
                  <div className="card-content">
                    <a
                      href=""
                      className="user-thumb d-inline-block"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="TechyDevs"
                    >
                      <img src={Hotel} alt="author-img" />
                    </a>
                    <h4 className="card-title pt-3">
                      <a href="listing-details.html">
                        Favorite Place Food Bank
                      </a>
                      <i
                        className="la la-check-circle ml-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Claimed"
                      ></i>
                    </h4>
                    <p className="card-sub">
                      <a href="">
                        <i className="la la-map-marker mr-1 text-color-2"></i>
                        Bishop Avenue, New York
                      </a>
                    </p>
                    <ul className="listing-meta d-flex align-items-center">
                      <li className="d-flex align-items-center">
                        <span className="rate flex-shrink-0">4.7</span>
                        <span className="rate-text">5 Ratings</span>
                      </li>
                      <li>
                        <span
                          className="price-range"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Pricey"
                        >
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                        </span>
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="la la-cutlery mr-1 listing-icon"></i>
                        <a href="" className="listing-cat-link">
                          Restaurant
                        </a>
                      </li>
                    </ul>
                    <ul className="info-list padding-top-20px">
                      <li>
                        <span className="la la-link icon"></span>
                        <a href=""> www.techydevs.com</a>
                      </li>
                      <li>
                        <span className="la la-calendar-check-o icon"></span>
                        Opened 1 month ago
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-item border border-color">
                  <div className="card-image">
                    <Link to="/listing-details/id" className="d-block">
                      <img src={Hotel} className="card__img" alt="" />
                      <span className="badge">now open</span>
                    </Link>
                    <span
                      className="bookmark-btn"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Save"
                    >
                      <i className="la la-bookmark"></i>
                    </span>
                  </div>
                  <div className="card-content">
                    <a
                      href=""
                      className="user-thumb d-inline-block"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="TechyDevs"
                    >
                      <img src={Hotel} alt="author-img" />
                    </a>
                    <h4 className="card-title pt-3">
                      <a href="listing-details.html">
                        Favorite Place Food Bank
                      </a>
                      <i
                        className="la la-check-circle ml-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Claimed"
                      ></i>
                    </h4>
                    <p className="card-sub">
                      <a href="">
                        <i className="la la-map-marker mr-1 text-color-2"></i>
                        Bishop Avenue, New York
                      </a>
                    </p>
                    <ul className="listing-meta d-flex align-items-center">
                      <li className="d-flex align-items-center">
                        <span className="rate flex-shrink-0">4.7</span>
                        <span className="rate-text">5 Ratings</span>
                      </li>
                      <li>
                        <span
                          className="price-range"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Pricey"
                        >
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                        </span>
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="la la-cutlery mr-1 listing-icon"></i>
                        <a href="" className="listing-cat-link">
                          Restaurant
                        </a>
                      </li>
                    </ul>
                    <ul className="info-list padding-top-20px">
                      <li>
                        <span className="la la-link icon"></span>
                        <a href=""> www.techydevs.com</a>
                      </li>
                      <li>
                        <span className="la la-calendar-check-o icon"></span>
                        Opened 1 month ago
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="card-item border border-color">
                  <div className="card-image">
                    <Link to="/listing-details/id" className="d-block">
                      <img src={Hotel} className="card__img" alt="" />
                      <span className="badge">now open</span>
                    </Link>
                    <span
                      className="bookmark-btn"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Save"
                    >
                      <i className="la la-bookmark"></i>
                    </span>
                  </div>
                  <div className="card-content">
                    <a
                      href=""
                      className="user-thumb d-inline-block"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="TechyDevs"
                    >
                      <img src={Hotel} alt="author-img" />
                    </a>
                    <h4 className="card-title pt-3">
                      <a href="listing-details.html">
                        Favorite Place Food Bank
                      </a>
                      <i
                        className="la la-check-circle ml-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Claimed"
                      ></i>
                    </h4>
                    <p className="card-sub">
                      <a href="">
                        <i className="la la-map-marker mr-1 text-color-2"></i>
                        Bishop Avenue, New York
                      </a>
                    </p>
                    <ul className="listing-meta d-flex align-items-center">
                      <li className="d-flex align-items-center">
                        <span className="rate flex-shrink-0">4.7</span>
                        <span className="rate-text">5 Ratings</span>
                      </li>
                      <li>
                        <span
                          className="price-range"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Pricey"
                        >
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                          <strong className="font-weight-medium">$</strong>
                        </span>
                      </li>
                      <li className="d-flex align-items-center">
                        <i className="la la-cutlery mr-1 listing-icon"></i>
                        <a href="" className="listing-cat-link">
                          Restaurant
                        </a>
                      </li>
                    </ul>
                    <ul className="info-list padding-top-20px">
                      <li>
                        <span className="la la-link icon"></span>
                        <a href=""> www.techydevs.com</a>
                      </li>
                      <li>
                        <span className="la la-calendar-check-o icon"></span>
                        Opened 1 month ago
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* card area  */}

      <Footer />

      {/* modals */}

      <div
        className="modal fade modal-container login-form"
        id="loginModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="loginModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header align-items-center mh-bg">
              <h5 className="modal-title" id="loginModalTitle">
                Hey, Welcome back!
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" className="la la-times-circle"></span>
              </button>
            </div>
            <div className="modal-body">
              <form method="post" className="form-box">
                <div className="input-box">
                  <label className="label-text">Username or email</label>
                  <div className="form-group">
                    <span className="la la-user form-icon"></span>
                    <input
                      className="form-control form-control-styled"
                      type="text"
                      name="text"
                      placeholder="Username or email address"
                    />
                  </div>
                </div>
                <div className="input-box">
                  <label className="label-text">Password</label>
                  <div className="form-group">
                    <span className="la la-lock form-icon"></span>
                    <input
                      className="form-control form-control-styled"
                      type="text"
                      name="text"
                      placeholder="Enter password"
                    />
                  </div>
                </div>
                <div className="input-box d-flex align-items-center justify-content-between pb-4 user-action-meta">
                  <div className="custom-checkbox">
                    <input type="checkbox" id="keepMeSignedChb" />
                    <label htmlFor="keepMeSignedChb" className="font-size-14">
                      Keep me signed in
                    </label>
                  </div>
                  <a
                    href="javascript:void(0)"
                    className="margin-bottom-10px lost-pass-btn font-size-14"
                  >
                    Lost Password?
                  </a>
                </div>
                <div className="btn-box">
                  <button
                    type="submit"
                    className="theme-btn gradient-btn w-100"
                  >
                    <i className="la la-sign-in mr-1"></i> Login to Account
                  </button>
                  <p className="sub-text-box text-right pt-1 font-weight-medium font-size-14">
                    New to Uniworkpass?{" "}
                    <a
                      className="text-color-2 signup-btn"
                      href="javascript:void(0)"
                    >
                      Create account
                    </a>
                  </p>
                </div>
                <div className="icon-element font-size-16 font-weight-semi-bold mt-5 mb-4 mx-auto">
                  OR
                </div>
                <div className="text-center">
                  <p className="font-size-15 font-weight-medium">
                    Login with your social network
                  </p>
                  <ul className="social-profile social-profile-colored py-3">
                    <li>
                      <a
                        href=""
                        className="google-bg mx-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Google"
                      >
                        <i className="lab la-google"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        className="facebook-bg mx-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Facebook"
                      >
                        <i className="lab la-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        className="twitter-bg mx-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Twitter"
                      >
                        <i className="lab la-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        className="instagram-bg mx-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Instagram"
                      >
                        <i className="lab la-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade modal-container signup-form"
        id="signUpModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="signUpModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header align-items-center mh-bg-2">
              <h5 className="modal-title" id="signUpModalTitle">
                Welcome! create your Uniworkpass account
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" className="la la-times-circle"></span>
              </button>
            </div>
            <div className="modal-body">
              <form method="post" className="form-box">
                <div className="input-box">
                  <label className="label-text">Username</label>
                  <div className="form-group">
                    <span className="la la-user form-icon"></span>
                    <input
                      className="form-control form-control-styled"
                      type="text"
                      name="text"
                      placeholder="Username"
                    />
                  </div>
                </div>
                <div className="input-box">
                  <label className="label-text">Email</label>
                  <div className="form-group">
                    <span className="la la-envelope form-icon"></span>
                    <input
                      className="form-control form-control-styled"
                      type="email"
                      name="text"
                      placeholder="Email address"
                    />
                  </div>
                </div>
                <div className="input-box">
                  <label className="label-text">Password</label>
                  <div className="form-group">
                    <span className="la la-lock form-icon"></span>
                    <input
                      className="form-control form-control-styled"
                      type="text"
                      name="text"
                      placeholder="Enter password"
                    />
                  </div>
                  <p className="font-size-14 mt-n2">
                    Your password must be at least 6 characters long and must
                    contain letters, numbers and special characters. Cannot
                    contain whitespace.
                  </p>
                </div>
                <div className="input-box py-4 user-action-meta">
                  <div className="custom-checkbox">
                    <input type="checkbox" id="agreeChb" />
                    <label htmlFor="agreeChb" className="font-size-14">
                      By signing up, you agree to our{" "}
                      <a href="privacy-policy.html" className="text-color-2">
                        Privacy Policy.
                      </a>
                    </label>
                  </div>
                </div>
                <div className="btn-box">
                  <button
                    type="submit"
                    className="theme-btn gradient-btn w-100"
                  >
                    <i className="la la-user-plus mr-1"></i> Register Account
                  </button>
                  <p className="sub-text-box text-right pt-1 font-weight-medium font-size-14">
                    Already on Uniworkpass?{" "}
                    <a
                      className="text-color-2 login-btn"
                      href="javascript:void(0)"
                    >
                      Log in
                    </a>
                  </p>
                </div>
                <div className="icon-element font-size-16 font-weight-semi-bold mt-5 mb-4 mx-auto">
                  OR
                </div>
                <div className="text-center">
                  <p className="font-size-15 font-weight-medium">
                    Connect with social network
                  </p>
                  <ul className="social-profile social-profile-colored py-3">
                    <li>
                      <a
                        href=""
                        className="google-bg mx-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Google"
                      >
                        <i className="lab la-google"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        className="facebook-bg mx-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Facebook"
                      >
                        <i className="lab la-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        className="twitter-bg mx-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Twitter"
                      >
                        <i className="lab la-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href=""
                        className="instagram-bg mx-1"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Instagram"
                      >
                        <i className="lab la-instagram"></i>
                      </a>
                    </li>
                  </ul>
                  <p className="font-size-15 pb-3">
                    Don't worry, we never any post to your social profile.
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade modal-container recover-form"
        id="recoverModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="recoverModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header align-items-center mh-bg">
              <h5 className="modal-title" id="recoverModalTitle">
                Reset password
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" className="la la-times-circle"></span>
              </button>
            </div>
            <div className="modal-body">
              <p className="font-size-15 font-weight-medium pb-3">
                Enter your username or email to reset your password. You will
                receive an email with instructions on how to reset your
                password. If you are experiencing problems resetting your
                password{" "}
                <a href="contact.html" className="text-color-2">
                  contact us
                </a>{" "}
                or{" "}
                <a href="" className="text-color-2">
                  send us an email
                </a>
              </p>
              <form method="post" className="form-box">
                <div className="input-box">
                  <label className="label-text">Username or email</label>
                  <div className="form-group">
                    <span className="la la-user form-icon"></span>
                    <input
                      className="form-control form-control-styled"
                      type="text"
                      name="text"
                      placeholder="Username or email address"
                    />
                  </div>
                </div>
                <div className="btn-box">
                  <button
                    type="submit"
                    className="theme-btn gradient-btn w-100"
                  >
                    Get New Password <i className="la la-arrow-right ml-1"></i>
                  </button>
                  <p className="sub-text-box text-right pt-1 font-weight-medium font-size-14">
                    Not a member?{" "}
                    <a
                      className="text-color-2 signup-btn"
                      href="javascript:void(0)"
                    >
                      Create account
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade modal-container"
        id="replayModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="replayModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header align-items-center mh-bg-2">
              <h5 className="modal-title" id="replayModalTitle">
                Replay to this comment
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" className="la la-times-circle"></span>
              </button>
            </div>
            <div className="modal-body">
              <form method="post" className="form-box">
                <div className="input-box">
                  <label className="label-text">Message</label>
                  <div className="form-group">
                    <span className="la la-pencil form-icon"></span>
                    <textarea
                      className="message-control form-control"
                      name="message"
                      placeholder="Write Message"
                    ></textarea>
                  </div>
                </div>
                <div className="btn-box">
                  <button
                    type="submit"
                    className="theme-btn gradient-btn w-100"
                  >
                    Replay<i className="la la-arrow-right ml-2"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade modal-container"
        id="shareModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="shareModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header align-items-center mh-bg">
              <h5 className="modal-title" id="shareModalTitle">
                Share this listing
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" className="la la-times-circle"></span>
              </button>
            </div>
            <div className="modal-body">
              <div className="copy-to-clipboard mb-3">
                <form action="#" className="form-box d-flex align-items-center">
                  <span className="text-success-message">Link Copied!</span>
                  <input
                    type="text"
                    className="form-control copy-input pl-3"
                    value="https://www.dirto.com/share/101WxMB0oac1hVQQ==/"
                  />
                  <div className="btn-box pl-2">
                    <button
                      type="button"
                      className="theme-btn gradient-btn copy-text"
                    >
                      Copy
                    </button>
                  </div>
                </form>
              </div>
              <h3 className="widget-title">Share on</h3>
              <ul className="social-profile social-profile-colored">
                <li>
                  <a href="" className="facebook-bg">
                    <i className="la la-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="" className="twitter-bg">
                    <i className="la la-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="" className="instagram-bg">
                    <i className="la la-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="" className="dribbble-bg">
                    <i className="la la-dribbble"></i>
                  </a>
                </li>
                <li>
                  <a href="" className="behance-bg">
                    <i className="la la-behance"></i>
                  </a>
                </li>
                <li>
                  <a href="" className="youtube-bg">
                    <i className="la la-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade modal-container"
        id="reportModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="reportModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header align-items-center mh-bg-2">
              <h5 className="modal-title" id="reportModalTitle">
                Report this listing
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" className="la la-times-circle"></span>
              </button>
            </div>
            <div className="modal-body">
              <p className="font-size-15 font-weight-medium pb-3">
                Flagged content is reviewed by Dirto staff to determine whether
                it violates Terms of Service or Community Guidelines. If you
                have a question or technical issue, please contact our
                <a href="contact.html" className="text-color-2">
                  Support team here
                </a>
              </p>
              <form method="post" className="form-box">
                <div className="input-box">
                  <label className="label-text">Issue type</label>
                  <div className="form-group user-chosen-select-container">
                    <select className="user-chosen-select">
                      <option value="0">Select an issue</option>
                      <option value="1">Inappropriate listing Content</option>
                      <option value="2">Inappropriate Behavior</option>
                      <option value="3">Uniworkpass Policy Violation</option>
                      <option value="4">Spammy Content</option>
                      <option value="5">Rude behavior with customer</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="input-box">
                  <label className="label-text">Issue details</label>
                  <div className="form-group">
                    <span className="la la-pencil form-icon"></span>
                    <textarea
                      className="message-control form-control"
                      name="message"
                      placeholder="Explain report issue as clear as possible"
                    ></textarea>
                  </div>
                </div>
                <div className="btn-box">
                  <button
                    type="submit"
                    className="theme-btn gradient-btn w-100"
                  >
                    Report Listing<i className="la la-arrow-right ml-2"></i>
                  </button>
                  <p className="font-size-13 font-weight-medium pt-1 text-center">
                    <span className="text-warning">Warning:</span> You can be
                    banned for violent messages.
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade modal-container message-form"
        id="messageModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="messageModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header align-items-center mh-bg-2">
              <h5 className="modal-title" id="messageModalTitle">
                Send a Message
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" className="la la-times-circle"></span>
              </button>
            </div>
            <div className="modal-body">
              <p className="font-size-15 font-weight-medium pb-2">
                <span className="text-color">To:</span> TechyDevs
              </p>
              <form method="post" className="form-box">
                <div className="input-box">
                  <label className="label-text">
                    Share a few details so we can get you in touch with the
                    business
                  </label>
                  <div className="form-group">
                    <span className="la la-pencil form-icon"></span>
                    <textarea
                      className="message-control form-control"
                      name="message"
                      placeholder="Can you tell me about your services?"
                    ></textarea>
                  </div>
                </div>
                <div className="input-box">
                  <label className="label-text d-block">
                    Include your information
                    <span className="text-gray">or</span>{" "}
                    <a
                      className="text-color-2 login-btn"
                      href="javascript:void(0)"
                    >
                      Log in
                    </a>
                  </label>
                  <label className="label-text">Email Address</label>
                  <div className="form-group">
                    <span className="la la-envelope-o form-icon"></span>
                    <input
                      className="form-control form-control-styled"
                      type="email"
                      name="email"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="input-box">
                  <label className="label-text">First Name</label>
                  <div className="form-group">
                    <span className="la la-user form-icon"></span>
                    <input
                      className="form-control form-control-styled"
                      type="text"
                      name="text"
                      placeholder="First name"
                    />
                  </div>
                </div>
                <div className="btn-box">
                  <p className="font-size-13 font-weight-medium pb-2 line-height-16">
                    We will send your information to the business to help get
                    you a response.
                  </p>
                  <button
                    type="submit"
                    className="theme-btn gradient-btn w-100"
                  >
                    Send a Message<i className="la la-arrow-right ml-2"></i>
                  </button>
                  <p className="font-size-13 font-weight-medium pt-1">
                    By proceeding, you agree to our
                    <a
                      href="terms-and-conditions.html"
                      className="text-color-2"
                    >
                      Terms of Service
                    </a>{" "}
                    and
                    <a href="privacy-policy.html" className="text-color-2">
                      Privacy Policy
                    </a>
                    .
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingDetails;
