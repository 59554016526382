import React, { useState, useEffect } from "react";
import Footer from "./footer";
import Header from "./header";
import { Link, useLocation } from "react-router-dom";
import { BASE_URL } from "../apis/url_constants";
import Pagination from "./Pagination";
import { Box } from "@mui/material";
import {
  useAddBookmark,
  useDeleteBookmark,
} from "../apis/listing_apis/bookmark";
import { getUserDetails } from "../utils/common";
import { toast } from "react-toastify";
import { useGetBookmark } from "../apis/listing_apis/get-bookmark";
import { useQueryClient } from "@tanstack/react-query";
import { useScrollTop } from "../lib/use-scroll-top";

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
}

const images = importAll(
  require.context("./../images", false, /\.(png|jpe?g|svg)$/)
);

let PageSize = 6;

const ListingGrid = () => {
  const location = useLocation();
  const { text } = location.state;
  const [posts, setPosts] = useState([]);
  const [pageDetail, setPageDetail] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const userDetails = getUserDetails();
  const { mutate: addBookmark } = useAddBookmark();
  const { mutate: deleteBookmark } = useDeleteBookmark();
  const queryClient = useQueryClient();
  useScrollTop();

  const { data: myBookmarksData } = useGetBookmark(
    {
      id: userDetails?.id,
    },
    {
      enabled: !!userDetails?.id,
    }
  );

  const updatedPost = async () => {
    const url = `${BASE_URL}/search/search?data=${text}&page=${currentPage}`;
    let data = await fetch(url);
    let parsedData = await data.json();
    setPosts(parsedData.data);
    setPageDetail(parsedData.page);
  };

  useEffect(() => {
    updatedPost();
  }, [currentPage]);

  const handleBookMark = (propertyId, bookmarked) => {
    if (bookmarked) {
      deleteBookmark(
        {
          listing_id: propertyId,
          user_id: userDetails.id,
        },
        {
          onSuccess: (e) => {
            queryClient.invalidateQueries("get-bookmark");
            toast.success(e?.data?.message);
          },
        }
      );
    } else {
      addBookmark(
        {
          listing_id: propertyId,
          user_id: userDetails.id,
        },
        {
          onSuccess: (e) => {
            queryClient.invalidateQueries("get-bookmark");
            toast.success(e?.data?.message);
          },
        }
      );
    }
  };

  const myBookmarks = myBookmarksData?.data?.data?.map((d) => d.listing__id);

  return (
    <>
      <Header />

      <section className="overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-content d-flex flex-wrap align-items-center justify-content-between">
                <div className="section-heading">
                  <h2 className="sec__title text-white font-size-40 mb-0">
                    Listing Grid
                  </h2>
                </div>
                <ul className="list-items bread-list">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>Listings</li>
                  <li>Listing Grid</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bread-svg">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="50px"
            viewBox="0 0 1200 150"
            preserveAspectRatio="none"
          >
            <g>
              <path
                fill-opacity="0.2"
                d="M0,150 C600,100 1000,50 1200,-1.13686838e-13 C1200,6.8027294 1200,56.8027294 1200,150 L0,150 Z"
              ></path>
            </g>
            <g
              className="pix-waiting animated"
              data-anim-type="fade-in-up"
              data-anim-delay="300"
            >
              <path
                fill="rgba(255,255,255,0.8)"
                d="M0,150 C600,120 1000,80 1200,30 C1200,36.8027294 1200,76.8027294 1200,150 L0,150 Z"
              ></path>
            </g>
            <path
              fill="#fff"
              d="M0,150 C600,136.666667 1000,106.666667 1200,60 C1200,74 1200,104 1200,150 L0,150 Z"
            ></path>
            <defs></defs>
          </svg>
        </div>
      </section>
      {/* header navbar */}
      <section className="card-area section-padding">
        <div className="container">
          {/* SORTING MENU */}
          <div className="row">
            <div className="col-lg-12">
              <div className="filter-bar d-flex flex-wrap justify-content-between align-items-center margin-bottom-30px">
                <p className="result-text font-weight-medium">
                  Showing 1 to {posts.length} of {posts.length} entries
                </p>
                <div className="filter-bar-action d-flex flex-wrap align-items-center">
                  <a
                    href="/"
                    className="search-filter"
                    data-toggle="modal"
                    data-target="#searchFilterModal"
                  >
                    <i className="la la-sliders mr-1"></i>Detailed Search
                  </a>
                  {/* <div className="user-chosen-select-container ml-3">
                    <select className="user-chosen-select">
                      <option value="newest-Listing">Newest Listing</option>
                      <option value="older-Listing">Older Listing</option>
                    </select>
                  </div>
                  <ul className="filter-nav ml-1">
                    <li>
                      <a
                        href="listing-grid.html"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Grid View"
                        className="active"
                      >
                        <span className="la la-th-large"></span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="listing-list.html"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="List View"
                      >
                        <span className="la la-list"></span>
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          </div>

          {/* CARDS DISPLAYING DATA */}
          <div className="row">
            {posts.map((ele) => {
              return (
                <div key={ele.id} className="col-lg-4 responsive-column">
                  <div className="card-item">
                    <div className="card-image">
                      <Link className="d-block" to={`/property/${ele.id}`}>
                        <img
                          src={`${BASE_URL}${ele.images[0].path}`}
                          className="card__img"
                          alt=""
                        />
                        <span className="badge">now open</span>
                      </Link>
                      <Box
                        onClick={() =>
                          handleBookMark(ele.id, myBookmarks?.includes(ele?.id))
                        }
                        sx={{
                          position: "absolute",
                          right: 10,
                          top: 10,
                          p: 1,
                          height: 30,
                          width: 30,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          borderRadius: "50%",
                          background: "#fff",
                          zIndex: 10,
                          cursor: "pointer",
                          "&:hover": {
                            opacity: 0.8,
                          },
                        }}
                      >
                        {myBookmarks?.includes(ele?.id) ? (
                          <i className="la la-check"></i>
                        ) : (
                          <i className="la la-bookmark"></i>
                        )}
                      </Box>
                    </div>
                    <div className="card-content">
                      <a
                        href="/listdetails"
                        className="user-thumb d-inline-block"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="TechyDevs"
                      >
                        <img
                          src={images["listing-logo.jpg"]}
                          alt="author-img"
                        />
                      </a>
                      <h4 className="card-title pt-3">
                        <Link to={`/property/${ele.id}`}>{ele.name}</Link>
                        <i
                          className="la la-check-circle ml-1"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Claimed"
                        ></i>
                      </h4>
                      <p className="card-sub text-truncate ">
                        <a href="/">
                          <i className=" la la-map-marker mr-1 text-color-2"></i>
                          {ele.address}
                        </a>
                      </p>
                      <ul className="listing-meta d-flex align-items-center">
                        <li className="d-flex align-items-center">
                          <span className="rate flex-shrink-0">4.7</span>
                          <span className="rate-text">Rating</span>
                        </li>
                        <li>
                          <span
                            className="price-range"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Pricey"
                          >
                            {/* <strong className="font-weight-medium">$</strong>
                            <strong className="font-weight-medium">$</strong>
                            <strong className="font-weight-medium">$</strong> */}
                          </span>
                        </li>
                        <li className="d-flex align-items-center">
                          <i className="la la-cutlery mr-1 listing-icon"></i>
                          <a
                            href="/listinggrid"
                            className="listing-cat-link text-center"
                          >
                            {ele.category_id__name}
                          </a>
                        </li>
                      </ul>
                      <ul className="info-list padding-top-20px">
                        <li className="d-flex">
                          <span className="la la-link icon "></span>
                          <a href="/" className="ml-2">
                            {ele.web}
                          </a>
                        </li>
                        <li>
                          <span className="la la-calendar-check-o icon"></span>
                          <span className="ml-2">Opened 1 month ago</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <Pagination
            className="pagination-bar"
            pageDetail={pageDetail}
            currentPage={pageDetail.current}
            totalCount={posts.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ListingGrid;
