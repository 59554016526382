import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import FallBackSpinner from "./components/fallback_spinner";
import store from "./redux/store";
import { Provider } from "react-redux";
import "./styles/style.css";
import "./styles/bootstrap.min.css";
import "./styles/line-awesome.min.css";
import "./styles/owl.carousel.min.css";
import "./styles/owl.theme.default.min.css";
import "./styles/animated-headline.css";
import "./styles/jquery-ui.css";
import "./styles/jquery.fancybox.css";
import "./styles/chosen.min.css";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import "../node_modules/slick-carousel/slick/slick.min.js";
import "../node_modules/react-slick/dist/react-slick.min.js";
// import "../node_modules/jquery/dist/jquery.min.js"

// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <Suspense fallback={<FallBackSpinner />}>
//         <App />
//         <ToastContainer
//           position="bottom-center"
//           autoClose={1000}
//           hideProgressBar={false}
//           newestOnTop
//           closeOnClick
//           rtl={false}
//           pauseOnFocusLoss
//           draggable
//           pauseOnHover
//         />
//       </Suspense>
//     </Provider>
//   </React.StrictMode>

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<FallBackSpinner />}>
      <App />
      <ToastContainer
        position="bottom-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
