import axios from "axios";
import { ChangePassword_URL } from "../url_constants";

export const changePassword = (data) => {
  let payload = JSON.stringify(data);
  const config = {
    url: `${ChangePassword_URL}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + data.token,
    },
    data: payload,
  };
  return axios(config).then().catch();
};
