import React, { useState, useRef, useEffect } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
import { keyCodes } from '../../helpers/constants';
import '../../styles/style.css';
import './style.css';

const CategoryImageHolder = ({
  storeImages,
  storeCategories,
  custom_styles = {},
  custom_class = '',
  placeholder = 'Add an image',
}) => {
  const imageField = useRef(null);
  const imagePreview = useRef(null);
  const [hasImage, setHasImage] = useState(false);
  const [categories, setCategories] = useState([]);
  const delimiters = [keyCodes.comma, keyCodes.enter];

  const handleDelete = (i) => {
    setCategories(categories.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    if (categories.length === 1) return;
    setCategories([...categories, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newcategories = categories.slice();

    newcategories.splice(currPos, 1);
    newcategories.splice(newPos, 0, tag);

    // re-render
    setCategories(newcategories);
  };

  const handleTagClick = (index) => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  const handleSave = (e) => {
    e.preventDefault();
    const final_image = imageField.current.files[0];
    const structure = {};
    // let structure = {final_image['name'] : categories,}
    let temp = [];
    categories.forEach((value, index) => {
      temp.push(value.id);
    });
    console.log(temp, 'abhisek');
    storeImages(final_image);
    structure[final_image.name] = temp;
    storeCategories(structure);
    console.log('Saving', categories, final_image.name);
  };

  const handleClear = (e) => {
    e.preventDefault();
    imagePreview.current.src = null;
    imageField.current.files = null;
    setCategories([]);
    setHasImage(false);
  };

  return (
    <div
      className={`holder-card-container col-sm-12 col-md-3 col-lg-3 ${custom_class}`}
      style={custom_styles}
    >
      <input
        ref={imageField}
        type={'file'}
        placeholder={placeholder}
        onInput={(e) => {
          imagePreview.current.src = window.URL.createObjectURL(
            e.target.files[0]
          );
          setHasImage(true);
        }}
        style={{ display: 'none' }}
      />

      <div style={hasImage ? { display: 'block' } : { display: 'none' }}>
        <div className="image-preview">
          <i
            className="fas fa-times image-preview-close"
            style={{ cursor: 'pointer', userSelect: 'none' }}
            onClick={() => {
              imagePreview.current.src = null;
              imageField.current.files = null;
              setHasImage(false);
            }}
          />
          <img
            ref={imagePreview}
            height="150px"
            width="150px"
            style={{ paddingBottom: '0.4rem' }}
          />
        </div>
      </div>
      <div style={hasImage ? { display: 'none' } : { display: 'block' }}>
        <div
          className="container text-center"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            imageField.current.click();
          }}
        >
          <i className="add-image-button fa fa-plus fa-3x pb-2" />
          <h6>Add an image</h6>
        </div>
      </div>
      <div className="text-center">
        <ReactTags
          autofocus={false}
          classNames={{
            tags: 'tagsClass',
            tagInput: 'tagInputClass',
            tagInputField: 'tagInput form-control',
            selected: 'selectedClass',
            tag: 'tag',
            remove: 'delete-button',
            suggestions: 'suggestionsClass',
            activeSuggestion: 'activeSuggestionClass',
            editTagInput: 'editTagInputClass',
            editTagInputField: 'editTagInputField',
            clearAll: 'clearAllClass',
          }}
          placeholder="Enter a title"
          tags={categories}
          delimiters={delimiters}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          handleTagClick={handleTagClick}
          inputFieldPosition="bottom"
          autocomplete
        />
        <div className="d-flex mt-3 justify-content-between">
          <button onClick={handleClear} className="btn clear-button btn-danger">
            CLEAR
          </button>
          <button
            onClick={handleSave}
            className="btn text-white theme-btn gradient-btn"
          >
            SAVE
          </button>
        </div>
      </div>
    </div>
  );
};

export default CategoryImageHolder;
