import { useMemo } from "react";
import { Card } from "../card";

export const ShowRatings = ({ feedbacks = [] }) => {
  const getAverageRatings = useMemo(() => {
    let average = "N/A";
    const totalFeedbacks = feedbacks.length;
    let totalRating = 0;
    for (const feedback of feedbacks) {
      totalRating += feedback.rating;
    }
    if (totalRating > 0) {
      average = parseFloat(totalRating / totalFeedbacks).toFixed(1);
    }
    return average;
  }, [feedbacks]);
  return (
    <div>
      <Card title={"Rating Stars"}>
        <div className="review-content d-flex flex-wrap align-items-center">
          <div className="review-rating-summary">
            <span className="stats-average__count">{getAverageRatings}</span>
            <div className="star-rating-wrap">
              <p className="font-size-14 font-weight-medium">out of 5.0</p>
              <div className="star-rating text-color-5 font-size-18">
                <span>
                  <i className="la la-star"></i>
                </span>
                <span className="ml-n1">
                  <i className="la la-star"></i>
                </span>
                <span className="ml-n1">
                  <i className="la la-star"></i>
                </span>
                <span className="ml-n1">
                  <i className="la la-star"></i>
                </span>
                <span className="ml-n1">
                  <i className="la la-star"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="review-bars d-flex flex-row flex-wrap flex-grow-1 align-items-center">
            <div className="review-bars-item">
              <span className="review-bars-name">Overall Rating</span>
              <div className="review-bars-inner d-flex w-100 align-items-center">
                <span
                  className="review-bars-review"
                  data-rating={getAverageRatings}
                >
                  <span className="review-bars-review-inner"></span>
                </span>
                <span className="pill">{getAverageRatings}</span>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};
