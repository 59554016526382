import axios from "axios";
import { CHANGE_EMAIL, USER_DETAILS_URL } from "../url_constants";
import { useQuery } from "@tanstack/react-query";

export const getUserData = (token) => {
  const config = {
    url: `${USER_DETAILS_URL}`,
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return axios(config).then().catch();
};

export const useGetUser = () => {
  return useQuery({
    queryFn: () => getUserData(sessionStorage.access),
    queryKey: ["get-user"],
  });
};

export const updateUserEmail = (token, payload) => {
  const config = {
    url: `${CHANGE_EMAIL}`,
    method: "POST",
    data: payload,
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  return axios(config).then().catch();
};
