import { CircularProgress, IconButton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useBookingStore } from "../../states/use-booking-store";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useGetPrice } from "../../apis/booking/use-get-price";
import { formatCurrency, getUserDetails } from "../../utils/common";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";

export const Booking = ({ listing }) => {
  const navigate = useNavigate();
  const { setInfo } = useBookingStore();
  const [guests, setGuests] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { mutate: getPrice, data: priceData, isLoading } = useGetPrice();

  const userData = getUserDetails();

  const handleSetEndDate = (date) => {
    setEndDate(date);
  };

  useEffect(() => {
    console.log(
      "startDate && endDate && guests && guests > 0",
      startDate && endDate && guests && guests > 0,
      startDate,
      endDate,
      guests
    );
    if (startDate && endDate && guests && guests > 0) {
      getPrice({
        listing_id: listing.listing_id,
        per_hour: listing.hourly_rate,
        half_day: listing.half_day_rate,
        full_day: listing.full_day_rate,
        week_day: listing.weekly_rate,
        start_time: format(new Date(startDate), "yyyy-MM-dd HH:mm"),
        end_time: format(new Date(endDate), "yyyy-MM-dd HH:mm"),
        guests: guests,
      });
    }
  }, [startDate, endDate, guests]);

  const handleCreateBooking = () => {
    if (!startDate) {
      toast.error("Please select a date");
      return;
    }
    if (!guests) {
      toast.error("Please select number of seats");
      return;
    }
    getPrice(
      {
        listing_id: listing.listing_id,
        per_hour: listing.hourly_rate,
        half_day: listing.half_day_rate,
        full_day: listing.full_day_rate,
        week_day: listing.weekly_rate,
        start_time: format(new Date(startDate), "yyyy-MM-dd HH:mm"),
        end_time: format(new Date(endDate), "yyyy-MM-dd HH:mm"),
        guests: guests,
      },
      {
        onSuccess: (res) => {
          setInfo({
            guests: guests,
            full_price: res.data.calculated,
            start_date: startDate,
            end_date: endDate,
          });
          navigate("/payment");
        },
        onError: (err) => {
          toast.error(err?.message);
        },
      }
    );
  };

  const incrementGuests = () => {
    setGuests((s) => ++s);
  };

  const decrementGuests = () => {
    setGuests((s) => (s > 1 ? --s : s));
  };

  return (
    <div>
      <div className="form-group">
        <label style={{ width: "100%" }}>
          Start Date
          <input
            className="date-dropper-input form-control"
            type="datetime-local"
            onChange={(e) => setStartDate(e?.target?.value)}
            step={60}
            min={new Date()
              .toISOString()
              .slice(0, new Date().toISOString().lastIndexOf(":"))}
            value={startDate}
            placeholder="Check in"
          />
        </label>
        <label style={{ width: "100%" }}>
          End Date
          <input
            className="date-dropper-input form-control"
            type="datetime-local"
            disabled={!startDate}
            onChange={(e) => handleSetEndDate(e?.target?.value)}
            min={
              startDate
                ? new Date(startDate)
                    .toISOString()
                    .slice(0, new Date().toISOString().lastIndexOf(":"))
                : new Date()
                    .toISOString()
                    .slice(0, new Date().toISOString().lastIndexOf(":"))
            }
            value={endDate}
            placeholder="Check in"
          />
        </label>
      </div>
      <div className="quantity-wrap d-flex align-items-center justify-content-between py-4">
        <span className="font-weight-semi-bold">
          <i className="la la-user-plus mr-1"></i>No. of Seats:
        </span>
        <div className="quantity-item d-flex align-items-center justify-content-end">
          <IconButton onClick={decrementGuests}>
            <RemoveIcon />
          </IconButton>
          <div className="px-2">{guests}</div>
          <IconButton onClick={incrementGuests}>
            <AddIcon />
          </IconButton>
        </div>
      </div>
      <div className="quantity-wrap d-flex align-items-center justify-content-between py-4">
        <span className="font-weight-semi-bold">
          <i className="fa fa-money-bill mr-1"></i>Total Amount:
        </span>
        <div className="quantity-item d-flex align-items-center justify-content-end">
          <div className="px-2">
            {isLoading ? (
              <CircularProgress size={5} />
            ) : priceData?.data.calculated ? (
              formatCurrency(priceData?.data.calculated)
            ) : (
              formatCurrency(0)
            )}
          </div>
        </div>
      </div>
      <div className="py-2 d-flex">
        <LoadingButton
          disabled={!userData || !priceData?.data.calculated || isLoading}
          // loading={isLoading}
          onClick={handleCreateBooking}
          variant="outlined"
          className="theme-btn gradient-btn border-0 text-white"
        >
          Request for booking
        </LoadingButton>
      </div>
    </div>
  );
};
