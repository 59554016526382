export const ENV = "staging";
// export const ENV = "dev";
export const BASE_URL =
  ENV === "dev" ? "http://127.0.0.1:8000" : "http://54.238.205.15:8000"; //TODO: Change this to production URL

//* USER URLS
export const REGISTER_URL = BASE_URL + "/users/register/";
export const LOGIN_URL = BASE_URL + "/api/token/";
export const ChangePassword_URL = BASE_URL + "/users/changepassword/";
export const ChangeEmail_URL = BASE_URL + "/users/changeemail/";
export const FORGOT_URL = BASE_URL + "/users/forgot/";
export const LOGOUT_URL = BASE_URL + "/users/logout/";
export const USER_DETAILS_URL = BASE_URL + "/users/detail/";
export const SEND_OTP = BASE_URL + "/users/verifyotp/"; //*Takes in query parameters as mobile_number = 9309109327
export const VERIFY_OTP = BASE_URL + "/users/verifyotp/";
export const CHECK_USER = BASE_URL + "/users/checkuser/";
export const CHANGE_EMAIL = BASE_URL + "/users/changeemail/";
// ----Search
// export const SEARCH_CITY = BASE_URL + `/search/search?data=data`;
export const SEARCH_CITY = BASE_URL + `/search/`;
// export const SEARCH_CITY = BASE_URL + ``;

//* VENDOR URLS
export const ADD_LISTING = BASE_URL + "/vendors/listing/";
export const EDIT_LISTING = BASE_URL + "/vendors/listing/";
export const GET_LISTING = BASE_URL + "/vendors/listing";
export const DELETE_LISTING = BASE_URL + "/vendors/listing/";
export const DELETE_SUB_CAT_RECORD = BASE_URL + "/vendors/pricing/";
export const DUMMY_PRIMARY_IMAGE =
  "https://images.unsplash.com/photo-1668000819543-ec8a570939ac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80";
export const DUMMY_PROFILE_IMAGE =
  "https://randomuser.me/api/portraits/men/1.jpg";
export const GET_CITIES = BASE_URL + "/vendors/city/";
export const GET_LISTING_TYPES =
  BASE_URL + "/vendors/master/?category=categories";
export const AMENITIES = BASE_URL + "/vendors/amenities";
export const GET_SUB_CATEGORIES =
  BASE_URL + "/vendors/master/?category=subcategories";
export const CREATE_BOOKING = BASE_URL + "/booking/booking/";
export const GET_PRICE = BASE_URL + "/booking/get_price";
export const GET_INVOICE = BASE_URL + "/booking/invoice";
export const CONFIRM_BOOKING = BASE_URL + "/booking/confirmation/";
export const BOOKMARK = BASE_URL + "/bookmark/addbookmark/";
export const GET_BOOKMARK = BASE_URL + "/bookmark/getbookmark/";
export const MY_BOOKINGS = BASE_URL + "/booking/my-invoice/";
export const FEEDBACK = BASE_URL + "/feedback/";
export const USER_CART = BASE_URL + "/users/cart/";
