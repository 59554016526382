import React, { useState } from 'react';
import Map, { Marker } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import GeocoderControl from './GeocoderControl';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import ReactMapGL from "react-map-gl";
import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const mapboxAccessToken =
  'pk.eyJ1Ijoic2hpdmFuc2hwYW5kZXkiLCJhIjoiY2w0ZHI0eGg2MGI1cDNibmE5dzhqd3JidSJ9.v2H2wPCkL19fLesTgZuIRQ';

const ReactMap = ({
  handleCoordinates,
  latitude,
  longitude,
  viewOnly,
  isEdit,
}) => {
  const [edit, setEdit] = useState(isEdit);
  const [viewport, setViewport] = useState({
    latitude: latitude || 28.7041,
    longitude: longitude || 77.1025,
    zoom: 10,
  });
  const [coord, setCoord] = useState({
    lng: viewport.longitude,
    lat: viewport.latitude,
  });

  return (
    <Map
      mapboxAccessToken={mapboxAccessToken}
      style={{ height: '500px', width: '100%' }}
      {...viewport}
      mapStyle="mapbox://styles/mapbox/streets-v9"
      onMove={(e) => setViewport(e.viewState)}
    >
      {!viewOnly && (
        <GeocoderControl
          onSelect={() => setEdit(false)}
          onDragEnd={(lngLat) => {
            setCoord(lngLat);
            handleCoordinates(lngLat);
          }}
          mapboxAccessToken={mapboxAccessToken}
          position="top-left"
        />
      )}
      {(viewOnly || edit) && (
        <Marker
          onClick={(e) => console.log(e)}
          onDragEnd={(e) => {
            setCoord(e.lngLat);
            handleCoordinates(e.lngLat);
          }}
          longitude={longitude}
          latitude={latitude}
          anchor="center"
          draggable={edit}
        />
      )}
    </Map>
  );
};

export default ReactMap;
