import axios from "axios";
import { BOOKMARK, GET_BOOKMARK } from "../url_constants";
import { useQuery } from "@tanstack/react-query";

const getBookmarks = (data) => {
  const config = {
    method: "GET",
    // ID is UserID
    url: `${GET_BOOKMARK}?id=${data.id}`,
    headers: {
      //   Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjY3OTMwOTM4LCJpYXQiOjE2Njc5MzA2MzgsImp0aSI6IjVjN2JjMzczYTA5YzRiYzFiZWY0ZmM1NWI0YmZiNzI4IiwidXNlcl9pZCI6MX0.UdL9RJv852aJB0hRFBG1CJ-PIEj37DnyPjOrXc4WB7g`,
    },
  };

  return axios(config).then().catch();
};

export const useGetBookmark = (d, options) => {
  return useQuery(["get-bookmark"], {
    queryFn: () => getBookmarks(d),
    ...options,
  });
};
