import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { CREATE_BOOKING } from "../url_constants";

const createBooking = async (data) => {
  const result = await axios.post(CREATE_BOOKING, {
    ...data,
  });
  return result.data;
};

export const useCreateBooking = (options) => {
  return useMutation({
    mutationFn: createBooking,
    mutationKey: ["create-booking"],
    ...options,
  });
};
