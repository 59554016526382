import React, { useEffect } from "react";
import { getUserDataAction } from "../../redux/actions/user_actions/user_data";
import { Link, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../components/loader";
import ListingDetails from "./ListingDetails";
import "./index.css";
import { MiniHeader } from "../../components/mini-header";

const HandleRender = ({ post_id }) => {
  return (
    <>
      <MiniHeader />
      <ListingDetails />
    </>
  );
};

const UserListing = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user_details.data);
  const loading = useSelector((state) => state.user_details.loading);
  const { id } = useParams();

  useEffect(() => {
    if (userData == null) {
      dispatch(getUserDataAction(sessionStorage.access));
    }
  }, [userData]);

  return (
    <section
      className="container-fluid"
      style={{ alignItems: "center", width: "100%" }}
    >
      {loading ? (
        <Loader />
      ) : (
        <div className="row">
          <div
            className="col-sm-9 col-md-12 col-lg-12"
            style={{ padding: "0px", margin: "0px" }}
          >
            <HandleRender
              post_id={id}
              firstName={userData ? userData[0]?.first_name : ""}
              lastName={userData ? userData[0]?.last_name : ""}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default UserListing;
