import { useEffect } from "react";
import { useCreateBooking } from "../apis/booking/use-create-booking";
import { useGetUser } from "../apis/user_apis/user_data";
import { useCheckoutStore } from "../states/use-checkout-store";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.type = "text/javascript";
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

export const useCheckout = () => {
  const { data: userData, isLoading } = useGetUser();
  const { checkout } = useCheckoutStore();

  useEffect(() => {
    const loadRazorPay = async () => {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/razorpay.js"
      );
      if (!res) {
        console.log("RazorPay not loaded");
      }
    };
    loadRazorPay();
  }, []);

  const { mutateAsync, isLoading: creatingBooking } = useCreateBooking({});

  const createBooking = async (bookingData, paymentData) => {
    const guestInfo = checkout?.guestInfo;
    const bookingDataWithGuests = {
      ...bookingData,
      guests_details: JSON.stringify(
        guestInfo.map((g) => ({
          name: g.name,
          phone: g.phoneNumber,
          email: g.email,
        }))
      ),
    };
    await mutateAsync(bookingDataWithGuests, {
      onSuccess: async (data) => {
        handlePay(data.razorpay_detail, paymentData);
      },
    });
  };

  const handlePay = (data, paymentMethod) => {
    const { order_id, key, amount } = data;
    const email = userData?.data?.[0]?.email;
    const phoneNumber = userData?.data?.[0]?.phone_number;
    let orderId = order_id;
    var newCard = {
      order_id: orderId,
      amount: amount,
      email: email,
      contact: phoneNumber,
      method: paymentMethod.methodType,
      ...paymentMethod.method,
    };

    const razorPay = new window.Razorpay({
      key: key,
    });

    razorPay.on("payment.success", function (response) {
      window.location.replace(
        `/booking-confirmation?oi=${response.razorpay_order_id}&s=${response.razorpay_signature}&pi=${response.razorpay_payment_id}`
      );
    }); // will pass payment ID, order ID, and Razorpay signature to success handler.
    razorPay.on("payment.error", function (cardLessEMI) {
      console.log("Erropr", cardLessEMI);
    });

    razorPay.createPayment(newCard);
  };

  return {
    createBooking,
    creatingBooking,
    isLoading,
  };
};
