import axios from "axios";
import { GET_LISTING_TYPES } from "../url_constants";

export const getListingTypes = () => {
  const config = {
    method: "GET",
    url: GET_LISTING_TYPES,
    headers: {
      "Content-Type": "application/json",
      // 'Authorization': `Bearer ${token}`
    },
  };
  return axios(config).then().catch();
};
