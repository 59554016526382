import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import Hero from "../components/hero";
import Loader from "../components/loader";
import PopularListings from "../components/popular_listings";

const Home = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const load = sessionStorage.getItem("state");
    if (load) {
      return;
    } else {
      setLoading(true);
      const load = sessionStorage.getItem("Loader");
      if (load) {
        setTimeout(() => {
          setLoading(false);
          sessionStorage.clear();
        }, 700);
      } else {
        setLoading(false);
        //   return;
      }
    }
    // if (load) {
    //   setLoading(true);
    //   sessionStorage.setItem("Loader", false);
    //   const load2 = sessionStorage.getItem("Loader");
    //   if (load2) {
    //     console.log(load2);
    //     alert("Loading..");
    //     setLoading(false);
    //     sessionStorage.clear();
    //     return;
    //   }
    // }
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <>
      <Header />
      <Hero />
      <PopularListings />
      {/* <TrendingDestinations /> */}
      <Footer />
    </>
  );
};

export default Home;
