import React from 'react';

const SendMessageModal = () => {
  return (
    <div
      className="modal fade modal-container"
      id="sendMessageModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="sendMessageModalTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header align-items-center mh-bg-2">
            <h5 className="modal-title" id="sendMessageModalTitle">
              Send Message
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true" className="la la-times-circle"></span>
            </button>
          </div>
          <div className="modal-body">
            <form method="post" className="form-box">
              <div className="input-box">
                <label className="label-text">Message</label>
                <div className="form-group">
                  <span className="la la-pencil form-icon"></span>
                  <textarea
                    className="message-control form-control"
                    name="message"
                    placeholder="Write Message"
                  ></textarea>
                </div>
              </div>
              <div className="btn-box">
                <button type="submit" className="theme-btn gradient-btn w-100">
                  Send Message<i className="la la-arrow-right ml-2"></i>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendMessageModal;
