import axios from 'axios';
import { DELETE_SUB_CAT_RECORD, EDIT_LISTING } from '../url_constants';

export const editListingAPI = (listing_data) => {
  //* data has the entire form data
  let data = new FormData();
  data.append('id', listing_data?.id);
  data.append('name', listing_data?.name);
  data.append('user_id', listing_data?.user_id);
  data.append(
    'category',
    listing_data?.category ? listing_data?.category : '2'
  );
  data.append('longitude', listing_data?.longitude);
  data.append('latitude', listing_data?.latitude);
  data.append('tags', JSON.stringify(listing_data?.tags));
  data.append('description', listing_data?.description);
  data.append('web', listing_data?.web);
  data.append('address', listing_data?.address);
  data.append('city', listing_data?.city ? listing_data?.city : '2');
  data.append('opening_hours', JSON.stringify(listing_data?.opening_hours));
  data.append('phone_number', listing_data?.phone_number);
  // data.append(
  //   'image_categories',
  //   JSON.stringify(listing_data?.image_categories)
  // );
  data.append('telephone_number', listing_data?.telephone_number);
  data.append('twitter_link', listing_data?.twitter_link);
  data.append('facebook_link', listing_data?.facebook_link);
  data.append('youtube_link', listing_data?.youtube_link);
  data.append('insta_link', listing_data?.insta_link);
  data.append('amenities', JSON.stringify(listing_data?.amenities));
  data.append('subcategory', JSON.stringify(listing_data.subCategories));
  // data.append('images', listing_data?.images);
  // console.log("LISTING", ...data);
  // //*Adding images
  // if (listing_data?.images?.length > 0) {
  //   listing_data?.images?.forEach((value, index) => {
  //     if (index !== 0) {
  //       data.append('images', listing_data.images[index]);
  //     }
  //   });
  // }

  const config = {
    method: 'PUT',
    url: EDIT_LISTING,
    headers: {
      'Content-Type': 'multipart/form-data',
      //   Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjY3OTMwOTM4LCJpYXQiOjE2Njc5MzA2MzgsImp0aSI6IjVjN2JjMzczYTA5YzRiYzFiZWY0ZmM1NWI0YmZiNzI4IiwidXNlcl9pZCI6MX0.UdL9RJv852aJB0hRFBG1CJ-PIEj37DnyPjOrXc4WB7g`,
    },
    data: data,
  };

  return axios(config).then().catch();
};

export const deleteSubCatRecord = (payload) => {
  const config = {
    method: 'DELETE',
    url: DELETE_SUB_CAT_RECORD,
    headers: {
      'Content-Type': 'multipart/form-data',
      //   Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjY3OTMwOTM4LCJpYXQiOjE2Njc5MzA2MzgsImp0aSI6IjVjN2JjMzczYTA5YzRiYzFiZWY0ZmM1NWI0YmZiNzI4IiwidXNlcl9pZCI6MX0.UdL9RJv852aJB0hRFBG1CJ-PIEj37DnyPjOrXc4WB7g`,
    },
    data: payload,
  };

  return axios(config);
};
