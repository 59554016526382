import { useForm } from "react-hook-form";
import { Card } from "../card";
import { useCreateFeedback } from "../../apis/feedback/use-create-feedback";
import { useGetUser } from "../../apis/user_apis/user_data";
import Loader from "../loader";
import { useParams } from "react-router-dom";
import { LoadingButton, Rating } from "@mui/lab";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

export const Feedback = ({ feedbacks }) => {
  const { id } = useParams();
  const { register, handleSubmit, reset } = useForm();
  const { data: userDetails, isLoading } = useGetUser();
  const client = useQueryClient();

  const userData = userDetails?.data?.[0];

  const { mutate: createFeedback, isLoading: creatingFeedback } =
    useCreateFeedback();

  const handleCreateFeedback = (data) => {
    createFeedback(
      {
        user: userData?.id,
        ratings: parseInt(data.rating),
        comments: data.message,
        venue: parseInt(id),
      },
      {
        onSuccess: (e) => {
          reset();
          client.invalidateQueries({
            queryKey: "feedback",
          });
          toast.success(e?.data?.message);
        },
      }
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  if (
    feedbacks &&
    feedbacks.find((f) => f.user.toString() === userData?.id?.toString())
  ) {
    return null;
  }

  return (
    <Card title={"Add a Review"}>
      <form onSubmit={handleSubmit(handleCreateFeedback)}>
        <div className="add-rating-bars review-bars d-flex flex-row flex-wrap flex-grow-1 align-items-center justify-content-between">
          <div className="review-bars-item mx-0 mt-0">
            <span className="review-bars-name">Ratings</span>
            <div className="review-bars-inner pt-1">
              <Rating name="simple-controlled" {...register("rating")} />
            </div>
          </div>
        </div>
        <div className="form-box row pt-3">
          <div className="col-lg-12">
            <div className="input-box">
              <label className="label-text">Review</label>
              <div className="form-group">
                <span className="la la-pencil form-icon"></span>
                <textarea
                  className="message-control form-control"
                  placeholder="Tell about your experience or leave a tip for others"
                  {...register("message")}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="btn-box pt-1">
              <LoadingButton
                loading={creatingFeedback}
                type="submit"
                className="theme-btn gradient-btn border-0 text-white"
              >
                Submit Review
                <i className="la la-arrow-right ml-2"></i>
              </LoadingButton>
            </div>
          </div>
        </div>
      </form>
    </Card>
  );
};
