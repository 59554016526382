import React from "react";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import Home from "./pages/Home";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./styles/style.css";
import "./styles/bootstrap.min.css";
import "./styles/line-awesome.min.css";
import "./styles/owl.carousel.min.css";
import "./styles/owl.theme.default.min.css";
import "./styles/animated-headline.css";
import "./styles/jquery-ui.css";
import "./styles/jquery.fancybox.css";
import "./styles/chosen.min.css";
import ComingSoon from "./pages/ComingSoon";
import Contact from "./components/Contact";
import About from "./components/About";
import ListingGrid from "./components/ListingGrid";
import Invoice from "./components/Invoice";
import ListDetails from "./components/ListDetails";
import UserListing from "./pages/Listing/user-listing";
import { BookingConfirmation } from "./pages/booking-confirmation";
import { PriceDetails } from "./pages/Listing/price-details";
import { Payment } from "./components/book-now/payment";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import { AdminRoutesConstant } from "./routers/admin-routes";
import ProtectedRoute from "./components/ProtectedRoute";
import Reset from "./pages/Reset/Reset";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Routes>
          {Object.keys(AdminRoutesConstant).map((r, k) => {
            const R = AdminRoutesConstant[r];
            return (
              <Route
                key={k}
                path={R.path}
                element={
                  R.isProtected ? (
                    <ProtectedRoute
                      isVendor={false}
                      component={<R.Component />}
                    />
                  ) : (
                    <R.Component />
                  )
                }
              />
            );
          })}
          <Route path="/" element={<Home />}></Route>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/home" element={<Home />}></Route>

          <Route path="/property/:id" element={<UserListing />}></Route>
          <Route path="/property/:id/detail" element={<PriceDetails />}></Route>
          <Route path="/payment" element={<Payment />}></Route>
          <Route path="/comingSoon" element={<ComingSoon origin="/" />}></Route>
          <Route path="/contact" element={<Contact />}></Route>
          <Route path="/about" element={<About />}></Route>
          <Route path="/listinggrid" element={<ListingGrid />}></Route>
          <Route path="/listdetails" element={<ListDetails />}></Route>
          <Route
            path="/booking-confirmation"
            element={<BookingConfirmation />}
          ></Route>
          <Route path="/invoice" element={<Invoice />}></Route>
          <Route
            path="*"
            element={<h2 className="text-center">404 Page Not Found</h2>}
          />
        </Routes>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
