/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingButton } from "@mui/lab";
import { Card } from "../card";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Box } from "@mui/material";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.in";
import { useCheckoutStore } from "../../states/use-checkout-store";
import { useBookingStore } from "../../states/use-booking-store";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorField } from "../error";

const schema = yup.object().shape({
  guestInfo: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Name is required"),
      phoneNumber: yup.string().required("Phone number is required"),
      email: yup.string().required("Email is required"),
    })
  ),
});

export const PersonalInfo = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { setCheckoutData, checkout } = useCheckoutStore();
  const {
    booking: { info },
  } = useBookingStore();

  const onSubmit = (data) => {
    setCheckoutData(data, 2);
  };

  const { fields, append } = useFieldArray({
    control,
    name: "guestInfo",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (fields.length !== info.guests) {
      if (
        checkout.guestInfo.length > 0 &&
        info.guests === checkout.guestInfo.length
      ) {
        checkout.guestInfo.map((g) => {
          append({
            name: g.name,
            phoneNumber: g.phoneNumber,
            email: g.email,
          });
        });
      } else if (info.guests) {
        Array.from(Array(info.guests)).map((g) => {
          append({
            name: undefined,
            phoneNumber: undefined,
            email: undefined,
          });
        });
      }
    }
  }, [info.guests, checkout]);

  return (
    <Card title={"Guest Info"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="block-card-body">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
            className="payment-option-wrap"
          >
            {fields.map((field, index) => {
              return (
                <Box
                  key={field.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <div>Guest {index + 1}</div>
                  <input
                    className="form-control form-control-styled"
                    placeholder="Name"
                    type="text"
                    {...register(`guestInfo.${index}.name`)}
                  />
                  <ErrorField>
                    {errors?.guestInfo?.[index]?.name?.message}
                  </ErrorField>
                  <Controller
                    control={control}
                    name={`guestInfo.${index}.phoneNumber`}
                    render={({ field }) => {
                      return (
                        <Cleave
                          className="form-control form-control-styled"
                          placeholder="Phone No"
                          options={{
                            phone: true,
                            phoneRegionCode: "IN",
                          }}
                          {...field}
                        />
                      );
                    }}
                  />
                  <ErrorField>
                    {errors?.guestInfo?.[index]?.phoneNumber?.message}
                  </ErrorField>
                  <Controller
                    control={control}
                    name={`guestInfo.${index}.email`}
                    render={({ field }) => {
                      return (
                        <Cleave
                          className="form-control form-control-styled"
                          placeholder="Email"
                          type="email"
                          {...field}
                        />
                      );
                    }}
                  />
                  <ErrorField>
                    {errors?.guestInfo?.[index]?.email?.message}
                  </ErrorField>
                </Box>
              );
            })}
            {/* <div className="payment-tab"> */}
            {/* </div> */}
          </Box>
          <div className="btn-box pt-3">
            {checkout?.step > 1 ? null : (
              <LoadingButton
                variant="outlined"
                type="submit"
                className="theme-btn gradient-btn border-0 text-white"
              >
                Proceed to pay
              </LoadingButton>
            )}
          </div>
        </div>
      </form>
    </Card>
  );
};
