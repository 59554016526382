import React from 'react';

const DeleteBookmarkModal = () => {
  return (
    <div>
      <div
        className="modal fade modal-container"
        id="deleteModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="deleteModalTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-body text-center">
              <i className="la la-exclamation-circle font-size-70 text-warning"></i>
              <h4
                className="modal-title mt-2 mb-1 text-color font-size-20"
                id="deleteModalTitle"
              >
                Your item ill be deleted permanently!
              </h4>
              <p className="modal-sub font-weight-medium">
                Are you sure to proceed.
              </p>
            </div>
            <div className="modal-footer border-top-color">
              <button
                type="button"
                className="btn font-weight-medium line-height-20 bg-rgb-success"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn font-weight-medium line-height-20 bg-rgb-danger"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteBookmarkModal;
