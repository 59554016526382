import axios from 'axios';
import { EDIT_LISTING } from '../url_constants';

export const publishApi = (data) => {
  const config = {
    method: 'PUT',
    url: EDIT_LISTING,
    headers: {
      'Content-Type': 'multipart/form-data',
      //   Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjY3OTMwOTM4LCJpYXQiOjE2Njc5MzA2MzgsImp0aSI6IjVjN2JjMzczYTA5YzRiYzFiZWY0ZmM1NWI0YmZiNzI4IiwidXNlcl9pZCI6MX0.UdL9RJv852aJB0hRFBG1CJ-PIEj37DnyPjOrXc4WB7g`,
    },
    data: data,
  };

  return axios(config).then().catch();
};
