import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getListings } from "../../apis/listing_apis/get_listing";
import "../../styles/style.css";
import "../../styles/bootstrap.min.css";
import "../../styles/line-awesome.min.css";
import "../../styles/owl.carousel.min.css";
import "../../styles/owl.theme.default.min.css";
import "../../styles/animated-headline.css";
import "../../styles/jquery-ui.css";
import "../../styles/jquery.fancybox.css";
import "../../styles/chosen.min.css";

import ListingCard from "../../components/listing_card";
import { removeListings } from "../../apis/listing_apis/remove_listing";
import { getCities } from "../../apis/listing_apis/get_cities";
import { toast } from "react-toastify";

const NoData = () => {
  return (
    <div className="container text-center py-5 my-5">
      <h1 className="display-4">Nothing to show!</h1>
    </div>
  );
};

const MyListings = () => {
  const [listings, setListings] = useState([]);
  const userData = useSelector((state) => state.user_details.data);
  const [cities, setCities] = useState([]);

  const removeListing = (post_id) => {
    removeListings(post_id)
      .then((res) => {
        if (res?.status === 200) {
          fetchListings();
        }
      })
      .catch((error) => {
        console.log("Error while deleting listing..", error);
        toast.error("Something went wrong !!");
      });
  };
  const fetchListings = () => {
    let userId = null;
    if (userData && userData.length > 0) {
      userId = userData[0]?.id;
    }
    getListings(null, null, userId)
      .then((res) => {
        if (res.status === 200) {
          setListings(res?.data);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong !!");
      });
  };

  const refreshListing = () => {
    fetchListings();
  };

  useEffect(() => {
    fetchListings();
    getCities()
      .then((res) => {
        if (res.status === 200) {
          let structure = [];
          if (res?.data) {
            res.data.map((value) => {
              structure.push({
                label: value?.name,
                value: value?.id,
                state: value?.state__name,
              });
            });
          }
          setCities(structure);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Something went wrong !!");
      });
  }, []);
  return (
    <div className="container-fluid dashboard-inner-body-container">
      <div className="breadcrumb-content d-sm-flex align-items-center justify-content-between mb-4">
        <div className="section-heading">
          <h2 className="sec__title font-size-24 mb-0">My listings</h2>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="block-card dashboard-card mb-4">
              <div className="block-card-header">
                <h2 className="widget-title pb-0">Active Listings</h2>
              </div>
              <div className="block-card-body">
                {listings?.length > 0 && listings ? (
                  listings.map((value, key) => {
                    return (
                      <ListingCard
                        key={key}
                        data={value}
                        refreshListing={refreshListing}
                        removeListing={removeListing}
                        cities={cities}
                        isAdmin
                      />
                    );
                  })
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyListings;
