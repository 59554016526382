import React, { useEffect } from 'react';
import AdminNavbar from '../../components/AdminNavbar';
import AdminSidebar from '../../components/admin_sidebar';
import { getUserDataAction } from '../../redux/actions/user_actions/user_data';
import AddListing from './add-listing';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../components/loader';
import MyListings from './my-listings';
import SingleListing from './single-listing.js';
import EditListing from './EditListing';
import ListingDetails from './ListingDetails';
import NavbarAdminWrapper from '../../components/NavbarAdminWrapper';
import './index.css';

const HandleRender = ({ post_id, firstName, lastName }) => {
  if (window.location.pathname.includes('add')) {
    return (
      <>
        <NavbarAdminWrapper>
        <AddListing />
        </NavbarAdminWrapper>
      </>
    );
  } else if (window.location.pathname.includes('edit')) {
    return (
      <>
        <NavbarAdminWrapper>
        <EditListing />
        </NavbarAdminWrapper>
      </>
    );
  } else if (post_id) {
    return (
      <NavbarAdminWrapper>
        <ListingDetails />
      </NavbarAdminWrapper>
    );
  } else {
    return (
      <>
        <NavbarAdminWrapper>
        <MyListings />
        </NavbarAdminWrapper>
      </>
    );
  }
};

const Listing = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user_details.data);
  const loading = useSelector((state) => state.user_details.loading);
  const error = useSelector((state) => state.user_details.error);
  const { id } = useParams();
  let sidebarActiveRoute = 'my-listings';
  if (window.location.pathname.includes('add')) {
    sidebarActiveRoute = 'add-listing';
  }
  // console.log(id);

  useEffect(() => {
    if (userData == null) {
      dispatch(getUserDataAction(sessionStorage.access));
    }
  }, [userData]);

  return (
    <section
      className="container-fluid"
      style={{ alignItems: 'center', width: '100%' }}
    >
      {loading ? (
        <Loader />
      ) : (
        <div className="row">
          <div className="col-sm-12 col-md-2 col-lg-2">
            <AdminSidebar activeRoute={sidebarActiveRoute} />
          </div>
          <div
            className="col-sm-9 col-md-12 col-lg-12"
            style={{ padding: '0px', margin: '0px' }}
          >
            {/* <AdminNavbar
              firstName={userData ? userData[0]?.first_name : ''}
              lastName={userData ? userData[0]?.last_name : ''}
            /> */}
            <HandleRender
              post_id={id}
              firstName={userData ? userData[0]?.first_name : ''}
              lastName={userData ? userData[0]?.last_name : ''}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default Listing;
