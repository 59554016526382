import React from 'react';
import AdminSidebar from '../../components/admin_sidebar';
import NavbarAdminWrapper from '../../components/NavbarAdminWrapper';
import Avatar from '../../images/avatar-img.jpg';
import Hotel from '../../images/hotel.jpg';

const Reviews = () => {
  return (
    <div>
      <AdminSidebar activeRoute="reviews" />
      <NavbarAdminWrapper>
        <div className="container-fluid dashboard-inner-body-container">
          <div className="breadcrumb-content d-sm-flex align-items-center justify-content-between mb-4">
            <div className="section-heading">
              <h2 className="sec__title font-size-24 mb-0">Reviews</h2>
            </div>
            <ul className="list-items bread-list bread-list-2">
              <li>
                <a href="">Home</a>
              </li>
              <li>Dashboard</li>
              <li>Reviews</li>
            </ul>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="block-card dashboard-card mb-4">
                <div className="block-card-header">
                  <h2 className="widget-title pb-0">Your Reviews</h2>
                </div>
                <div className="block-card-body">
                  <div className="comments-list reviews-list">
                    <div className="comment">
                      <div className="user-thumb user-thumb-lg flex-shrink-0">
                        <img src={Hotel} alt="author-img" />
                      </div>
                      <div className="comment-body">
                        <div className="meta-data d-flex align-items-center justify-content-between">
                          <div>
                            <h4 className="comment__title">
                              <a href="listing-details.html">
                                Kam Lok Restaurant
                              </a>
                            </h4>
                            <span className="comment__meta">Alex Smith</span>
                          </div>
                          <div className="star-rating-wrap text-center">
                            <div className="star-rating text-color-5 font-size-18">
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                            </div>
                            <p className="font-size-13 font-weight-medium">
                              04/10/2020
                            </p>
                          </div>
                        </div>
                        <p className="comment-desc">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Aliquid cupiditate distinctio earum enim et
                          laboriosam molestias necessitatibus quod quos. A ab
                          alias aliquam amet animi aspernatur corporis culpa
                          deserunt dolorem ea eum, iste laudantium maxime minus
                          mollitia nam neque nobis non nostrum odio pariatur
                          perspiciatis placeat quae quasi, quia quod reiciendis
                          repellat repellendus saepe suscipit tenetur veritatis
                          vero voluptas voluptatum.
                        </p>
                        <div className="review-photos review-photos-2 d-flex flex-wrap align-items-center ml-n1 mb-3">
                          <a
                            href={Hotel}
                            className="d-inline-block"
                            data-fancybox="gallery"
                          >
                            <img
                              className="lazy"
                              src={Hotel}
                              data-src="images/single-listing-img1.jpg"
                              alt="review image"
                            />
                          </a>
                          <a
                            href={Hotel}
                            className="d-inline-block"
                            data-fancybox="gallery"
                          >
                            <img
                              className="lazy"
                              src={Hotel}
                              data-src="images/single-listing-img2.jpg"
                              alt="review image"
                            />
                          </a>
                        </div>
                        <div className="comment-action">
                          <a
                            className="btn-gray btn-gray-sm"
                            data-toggle="modal"
                            data-target="#replayModal"
                          >
                            <i className="la la-reply mr-1"></i>Reply to this review
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="comment">
                      <div className="user-thumb user-thumb-lg flex-shrink-0">
                        <img src={Hotel} alt="author-img" />
                      </div>
                      <div className="comment-body">
                        <div className="meta-data d-flex align-items-center justify-content-between">
                          <div>
                            <h4 className="comment__title">
                              <a href="listing-details.html">Mel’s Drive-In</a>
                            </h4>
                            <span className="comment__meta">Alex Smith</span>
                          </div>
                          <div className="star-rating-wrap text-center">
                            <div className="star-rating text-color-5 font-size-18">
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                            </div>
                            <p className="font-size-13 font-weight-medium">
                              04/10/2020
                            </p>
                          </div>
                        </div>
                        <p className="comment-desc">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Aliquid cupiditate distinctio earum enim et
                          laboriosam molestias necessitatibus quod quos. A ab
                          alias aliquam amet animi aspernatur corporis culpa
                          deserunt dolorem ea eum, iste laudantium maxime minus
                          mollitia nam neque nobis non nostrum odio pariatur
                          perspiciatis placeat quae quasi, quia quod reiciendis
                          repellat repellendus saepe suscipit tenetur veritatis
                          vero voluptas voluptatum.
                        </p>
                        <div className="comment-action mt-3">
                          <a
                            className="btn-gray btn-gray-sm"
                            data-toggle="modal"
                            data-target="#replayModal"
                          >
                            <i className="la la-reply mr-1"></i>Reply to this review
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="comment">
                      <div className="user-thumb user-thumb-lg flex-shrink-0">
                        <img src={Hotel} alt="author-img" />
                      </div>
                      <div className="comment-body">
                        <div className="meta-data d-flex align-items-center justify-content-between">
                          <div>
                            <h4 className="comment__title">
                              <a href="listing-details.html">
                                Pearl’s Deluxe Burgers
                              </a>
                            </h4>
                            <span className="comment__meta">Alex Smith</span>
                          </div>
                          <div className="star-rating-wrap text-center">
                            <div className="star-rating text-color-5 font-size-18">
                              <span>
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star"></i>
                              </span>
                              <span className="ml-n1">
                                <i className="la la-star-o"></i>
                              </span>
                            </div>
                            <p className="font-size-13 font-weight-medium">
                              04/10/2020
                            </p>
                          </div>
                        </div>
                        <p className="comment-desc">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Aliquid cupiditate distinctio earum enim et
                          laboriosam molestias necessitatibus quod quos. A ab
                          alias aliquam amet animi aspernatur corporis culpa
                          deserunt dolorem ea eum, iste laudantium maxime minus
                          mollitia nam neque nobis non nostrum odio pariatur
                          perspiciatis placeat quae quasi, quia quod reiciendis
                          repellat repellendus saepe suscipit tenetur veritatis
                          vero voluptas voluptatum.
                        </p>
                        <div className="review-photos review-photos-2 d-flex flex-wrap align-items-center ml-n1 mb-3">
                          <a
                            href="images/single-listing-img1.jpg"
                            className="d-inline-block"
                            data-fancybox="gallery"
                          >
                            <img
                              className="lazy"
                              src={Hotel}
                              data-src="images/single-listing-img1.jpg"
                              alt="review image"
                            />
                          </a>
                          <a
                            href={Hotel}
                            className="d-inline-block"
                            data-fancybox="gallery"
                          >
                            <img
                              className="lazy"
                              src={Hotel}
                              data-src="images/single-listing-img2.jpg"
                              alt="review image"
                            />
                          </a>
                          <a
                            href={Hotel}
                            className="d-inline-block"
                            data-fancybox="gallery"
                          >
                            <img
                              className="lazy"
                              src={Hotel}
                              data-src="images/single-listing-img3.jpg"
                              alt="review image"
                            />
                          </a>
                        </div>
                        <div className="comment-action">
                          <a
                            className="btn-gray btn-gray-sm"
                            data-toggle="modal"
                            data-target="#replayModal"
                          >
                            <i className="la la-reply mr-1"></i>Reply to this review
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 pb-4">
              <div className="pagination-wrapper d-inline-block">
                <div className="section-pagination">
                  <nav aria-label="Page navigation">
                    <ul className="pagination flex-wrap justify-content-center">
                      <li className="page-item">
                        <a className="page-link" aria-label="Previous">
                          <span aria-hidden="true">
                            <i className="la la-angle-left"></i>
                          </span>
                          <span className="sr-only">Previous</span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link">1</a>
                      </li>
                      <li className="page-item">
                        <a className="page-link page-link-active">2</a>
                      </li>
                      <li className="page-item">
                        <a className="page-link">3</a>
                      </li>
                      <li className="page-item">
                        <a className="page-link">4</a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" aria-label="Next">
                          <span aria-hidden="true">
                            <i className="la la-angle-right"></i>
                          </span>
                          <span className="sr-only">Next</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <ul className="pagination-simple d-flex align-items-center justify-content-center pt-3">
                  <li className="mr-4">
                    <a className="pagination-simple-nav first-nav">
                      <i className="la la-long-arrow-left mr-1"></i>First
                    </a>
                  </li>
                  <li>
                    <a className="pagination-simple-nav last-nav active">
                      Last<i className="la la-long-arrow-right ml-1"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </NavbarAdminWrapper>
      <div
        className="modal fade modal-container"
        id="replayModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="replayModalTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header align-items-center mh-bg-2">
              <h5 className="modal-title" id="replayModalTitle">
                Replay to this review
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true" className="la la-times-circle"></span>
              </button>
            </div>
            <div className="modal-body">
              <form method="post" className="form-box">
                <div className="input-box">
                  <label className="label-text">Message</label>
                  <div className="form-group">
                    <span className="la la-pencil form-icon"></span>
                    <textarea
                      className="message-control form-control"
                      name="message"
                      placeholder="Write Message"
                    ></textarea>
                  </div>
                </div>
                <div className="btn-box">
                  <button type="submit" className="theme-btn gradient-btn w-100">
                    Replay<i className="la la-arrow-right ml-2"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
