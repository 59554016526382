import { Card } from "../../components/card";
import { Box, Button, Chip, Menu, MenuItem, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Unstable_Grid2";
import StickyBox from "react-sticky-box";
import { Link, useNavigate } from "react-router-dom";
import { formatCurrency } from "../../utils/common";
import { useBookingStore } from "../../states/use-booking-store";
import { useCheckoutStore } from "../../states/use-checkout-store";
import { useGetUser } from "../../apis/user_apis/user_data";

const amenitiesKey = {
  ac: "AC",
  conf_call: "Conference call",
  projector: "Projector",
  television: "Television",
  white_board: "White Board",
};

export const PriceSelector = ({ listing }) => {
  const subcategories = listing.subcategory ? listing.subcategory : null;
  const { data: userData } = useGetUser();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const { setVenue } = useBookingStore();
  const { clearCheckout } = useCheckoutStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!subcategories) return;
    setSelectedSubcategory(subcategories[0]);
  }, [subcategories]);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectType = (subcategory) => {
    setSelectedSubcategory(subcategory);
    handleClose();
  };

  const handleGoToDetail = (venue, subCat, data) => {
    if (!userData) {
      navigate(`/login`);
      return;
    }
    setVenue(venue, subCat);
    clearCheckout();
    navigate(`/property/${venue.id}/detail?subId=${subCat.subcat_id}`, {
      state: {
        subCatData: data,
      },
    });
  };

  const renderAmenities = (category) => {
    if (!category) {
      return null;
    }
    return (
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexWrap: "wrap",
        }}
      >
        {Object.keys(amenitiesKey).map((a, k) => {
          return category[a] ? (
            <Chip variant="outlined" label={amenitiesKey[a]} />
          ) : null;
        })}
      </Box>
    );
  };

  return (
    <div>
      {subcategories ? (
        <Card
          sideAction={
            <div>
              <Button
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                disableRipple
                variant="contained"
                disableElevation
                sx={{
                  borderRadius: "15px",
                  px: 5,
                  fontSize: "0.8rem",
                  color: "white",
                  background: "linear-gradient(270deg, #9f8fff, #7b68ee)",
                  "&:focus": {
                    outline: "none",
                  },
                }}
              >
                {selectedSubcategory
                  ? selectedSubcategory.subCategory
                  : "Property Type"}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{
                  borderRadius: 5,
                  p: 3,
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                {subcategories.map((s) => {
                  return (
                    <MenuItem
                      sx={{
                        minWidth: 200,
                      }}
                      onClick={() => handleSelectType(s)}
                    >
                      {s.subCategory}
                    </MenuItem>
                  );
                })}
              </Menu>
            </div>
          }
          title={"Pricing Plans"}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            {selectedSubcategory?.data?.map((d, idx) => {
              return (
                <Paper
                  elevation={2}
                  sx={{
                    padding: 5,
                  }}
                >
                  <Grid container key={idx}>
                    <Grid md={6}>
                      <StickyBox offsetTop={20} offsetBottom={20}>
                        {renderAmenities(d)}
                      </StickyBox>
                    </Grid>
                    <Grid md={6}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          gap: "10px",
                        }}
                        width={"100%"}
                        marginTop={1}
                      >
                        <div>Starting @ {formatCurrency(d.hourly_rate)}</div>

                        <Button
                          onClick={() =>
                            handleGoToDetail(listing, selectedSubcategory, d)
                          }
                          type="button"
                          className="theme-btn gradient-btn border-0 text-white"
                        >
                          Book Now
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
          </Box>
        </Card>
      ) : null}
    </div>
  );
};
