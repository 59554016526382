import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { keywords, timings } from '../../helpers/constants';
import CategoryImageHolder from '../../components/category_image_holder';
import { useSelector } from 'react-redux';
import { addListingAPI } from '../../apis/listing_apis/add_listing';
import { getCities } from '../../apis/listing_apis/get_cities';
import { getListingTypes } from '../../apis/listing_apis/get_listing_types';
import { useNavigate } from 'react-router-dom';
import TagInput from '../../components/tag_input';
import { getAmenities } from '../../apis/listing_apis/amenities';
import { validPhone, validUrl } from '../../utils/common';
import { toast } from 'react-toastify';
import ReactMap from '../../components/Map/Map';
import SubCategoryDetails from '../../components/SubCategoryDetails';
import { getSubcategoriesList } from '../../apis/listing_apis/listing';
const AddListing = () => {
  const [form_inputs, setFormInputs] = useState({});
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [cat_list, setCatList] = useState(null);
  const [keywords_list, setTagsList] = useState([]);
  const [city, setCity] = useState();
  const [amenities, setAmenities] = useState([]);
  const [amenitiesList, setAmenitiesList] = useState([]);
  const [subCategoriesList, setSubCategriesList] = useState([]);
  const [images, setImages] = useState([1]); //* [Image object1, Image object2]
  const [image_categories, setImageCategories] = useState({}); //* {"image_name": ['cat1', 'cat2', 'cat3']}

  const userData = useSelector((state) => state.user_details.data);
  const history = useNavigate();
  console.log(form_inputs);

  useEffect(() => {
    getCities()
      .then((res) => {
        if (res.status === 200) {
          let structure = [];
          if (res?.data) {
            res.data.map((value) => {
              structure.push({ label: value?.name, value: value?.id });
            });
          }
          setCities(structure);
        }
      })
      .catch((err) => {
        toast.error('Something went wrong !!');
        console.log(err);
      });

    getListingTypes()
      .then((res) => {
        if (res.status === 200) {
          let structure = [];
          if (res?.data) {
            res.data.map((value) => {
              structure.push({ label: value?.name, value: value?.id });
            });
          }
          setCategories(structure);
        }
      })
      .catch((err) => {
        toast.error('Something went wrong !!');
        console.log(err);
      });

    getAmenities()
      .then((res) => {
        console.log(res);
        setAmenitiesList(res.data);
      })
      .catch((err) => {
        toast.error('Something went wrong !!');
        console.log(err);
      });

    getSubcategoriesList()
      .then((response) => {
        const list = response.data;
        setSubCategriesList(
          list.map((item) => ({ value: item.id, label: item.name }))
        );
      })
      .catch();
  }, []);

  const [timing_list, setTimings] = useState({
    Monday: [0, 0],
    Tuesday: [0, 0],
    Wednesday: [0, 0],
    Thursday: [0, 0],
    Friday: [0, 0],
    Saturday: [0, 0],
    Sunday: [0, 0],
  });

  Array.prototype.insert = function (index, ...items) {
    this.splice(index, 0, ...items);
  };

  const handleHours = (type, day, time) => {
    let temp = { ...timing_list };
    if (temp[day] === undefined) {
      temp[day] = time.value;
      setTimings(temp);
    } else {
      if (type === 'opening') {
        temp[day][0] = time.value;
      } else {
        temp[day][1] = time.value;
      }
      setTimings(temp);
    }
    console.log(timing_list);
  };

  const handleAllHours = (type, time) => {
    let temp = { ...timing_list };
    const insertIndex = type === 'opening' ? 0 : 1;

    for (const i in temp) {
      temp[i][insertIndex] = time.value;
    }

    setTimings(temp);
  };

  const getSelectValue = (day, type) => {
    const searchIndex = type === 'opening' ? 0 : 1;
    if (!timing_list[day][searchIndex]) {
      return null;
    }

    return {
      value: timing_list[day][searchIndex],
      label: timing_list[day][searchIndex],
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(keywords_list);
    if (!validPhone(form_inputs?.phone)) {
      console.log('phone error');
      toast.error('Invalid Phone number');
      return;
    }

    // if (
    //   (form_inputs?.facebook_link && !validUrl(form_inputs?.facebook_link)) ||
    //   (form_inputs?.insta_link && !validUrl(form_inputs?.insta_link)) ||
    //   (form_inputs?.twitter_link && !validUrl(form_inputs?.twitter_link)) ||
    //   (form_inputs?.youtube_link && !validUrl(form_inputs?.youtube_link)) ||
    //   !validUrl(form_inputs?.web)
    // ) {
    //   console.log("url error");
    //   toast.error("Please enter correct URL");
    //   return;
    // }

    let form_data = {
      ...form_inputs,
      user_id: userData[0]?.id,
      tags: keywords_list.map((value, key) => {
        return value['text'];
      }),
      subCategories: subCategories,
      city: city?.value,
      opening_hours: timing_list,
      category: cat_list.value,
      images: images,
      image_categories: image_categories,
      amenities: amenities,
    };
    console.log('form_data', form_inputs);
    addListingAPI(form_data)
      .then((res) => {
        if (res.status === 200 || res.status === 201 || res.status === 204) {
          console.log(res.data);
          history('/listing/', { replace: true });
          toast.success('Added successfully !! :)');
        }
      })
      .catch((err) => {
        toast.error('Something went wrong !!');
        console.log(err);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormInputs({ ...form_inputs, [name]: value });
  };

  const handleAmenities = (id) => {
    if (amenities.includes(id)) {
      setAmenities((prev) => prev.filter((value) => id !== value));
    } else {
      setAmenities((prev) => [...prev, id]);
    }
  };

  const containsValue = (arr, prop, propValue) => {
    for (const ele of arr) {
      if (ele[prop] === propValue) return true;
    }
    return false;
  };

  const handleSubCategories = (e) => {
    const id = +e.target.id.split('-')[0]; // concatenated label and value as we were facing problem with value of label
    const name = e.target.value;
    if (containsValue(subCategories, 'subcat_id', id)) {
      setSubCategories((prev) =>
        prev.filter((value) => id !== value.subcat_id)
      );
    } else {
      setSubCategories((prev) => [
        ...prev,
        { subcat_id: id, subCategory: name, data: [] },
      ]);
    }
    console.log(subCategories);
  };

  // useEffect(() => {
  //   fetchAmenities();
  // }, []);

  return (
    <>
      <section className="add-listing-area mb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <form onSubmit={handleSubmit}>
                <div className="block-card mb-4">
                  <div className="block-card-header">
                    <h2 className="widget-title">General Information</h2>
                    <div className="stroke-shape"></div>
                  </div>
                  <div className="block-card-body">
                    <form method="post" className="form-box row">
                      <div className="col-lg-12">
                        <div className="input-box">
                          <label className="label-text d-flex align-items-center ">
                            Listing Title
                            <i
                              className="la la-question tip ml-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Put your listing title here and tell the name of your business to the world."
                            ></i>
                          </label>
                          <div className="form-group">
                            <span className="la la-briefcase form-icon"></span>
                            <input
                              className="form-control"
                              type="text"
                              name="name"
                              onChange={handleChange}
                              autoFocus
                              placeholder="Example: Super Duper Burgers"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-box">
                          <label className="label-text">Category</label>
                          <div className="form-group user-chosen-select-container">
                            <Select
                              options={categories}
                              name="categories"
                              onChange={(cat) => {
                                setCatList(cat);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      {cat_list && (
                        <div className="col-lg-12">
                          <div className="input-box">
                            <label className="label-text">Sub-category</label>
                            <div
                              className="block-card-body row"
                              style={{ marginTop: '-18px' }}
                            >
                              {!!subCategoriesList?.length &&
                                subCategoriesList.map((item) => {
                                  return (
                                    <div
                                      key={item.value + '-' + item.label}
                                      className="col-lg-4 custom-checkbox"
                                    >
                                      <input
                                        type="checkbox"
                                        id={item.value + '-' + item.label}
                                        value={item.label}
                                        onChange={handleSubCategories}
                                      />
                                      <label
                                        for={item.value + '-' + item.label}
                                      >
                                        {item.label}
                                      </label>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      )}
                      {!!subCategories?.length && (
                        <div className="col-lg-12">
                          <div className="input-box">
                            <label className="label-text">
                              Sub-category Details
                            </label>
                            <div
                              className="block-card-body row"
                              style={{ marginTop: '-18px' }}
                            >
                              {!!subCategories?.length &&
                                subCategories.map((item) => {
                                  return (
                                    <SubCategoryDetails
                                      subCategory={item}
                                      setSubCategories={setSubCategories}
                                      editMode
                                    />
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-lg-12">
                        <div className="input-box">
                          <label className="label-text d-flex align-items-center">
                            Keywords or Tags
                            <i
                              className="la la-question tip ml-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="These keywords or tags will help your listing to find in search.Maximum of 3 keywords related with your business"
                            ></i>
                          </label>
                          <div className="form-group user-chosen-select-container">
                            <TagInput
                              list_of_tags={keywords_list}
                              setListOfTags={setTagsList}
                            />
                            {/* <Select
                              options={keywords}
                              name="keywords"
                              onChange={(tag) => {
                                setTagsList(tag);
                              }}
                            /> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-box">
                          <label className="label-text">Description</label>
                          <div className="form-group">
                            <textarea
                              className="message-control form-control user-text-editor"
                              name="description"
                              onChange={handleChange}
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-box">
                          <label className="label-text d-flex align-items-center">
                            Web Address
                          </label>
                          <div className="form-group">
                            <span className="la la-globe form-icon"></span>
                            <input
                              className="form-control"
                              type="text"
                              name="web"
                              onChange={handleChange}
                              placeholder="http://www.companyaddress.com"
                            />
                            {!validUrl(form_inputs?.web) &&
                              form_inputs?.web?.length > 0 && (
                                <span
                                  className="error-text"
                                  style={{
                                    color: 'red',
                                  }}
                                >
                                  Web Address is Invalid
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-12">
                      <div className="input-box">
                        <label className="label-text d-flex align-items-center ">
                          Menu Web Address
                        </label>
                        <div className="form-group mb-0">
                          <span className="la la-globe form-icon"></span>
                          <input
                            className="form-control"
                            type="text"
                            name="menu"
                            onChange={handleChange}
                            placeholder="http://www.companyaddress.com/menu.html"
                          />
                        </div>
                      </div>
                    </div> */}
                    </form>
                  </div>
                </div>
                <div className="block-card mb-4">
                  <div className="block-card-header">
                    <h2 className="widget-title">Location/Contact</h2>
                    <div className="stroke-shape"></div>
                  </div>
                  <div>
                    <ReactMap
                      handleCoordinates={(coord) => {
                        setFormInputs((prev) => ({
                          ...prev,
                          longitude: coord.lng,
                          latitude: coord.lat,
                        }));
                        console.log(coord);
                      }}
                    />
                  </div>
                  <div className="block-card-body">
                    <form method="post" className="form-box row">
                      <div className="col-lg-6">
                        <div className="input-box">
                          <label className="label-text">Longitude</label>
                          <div className="form-group">
                            <span className="la la-map form-icon"></span>
                            <input
                              className="form-control"
                              id="longitude"
                              type="number"
                              name="longitude"
                              onChange={handleChange}
                              placeholder="Map Longitude"
                              value={form_inputs.longitude}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-box">
                          <label className="label-text">Latitude</label>
                          <div className="form-group">
                            <span className="la la-map form-icon"></span>
                            <input
                              className="form-control"
                              id="latitude"
                              name="latitude"
                              onChange={handleChange}
                              type="number"
                              placeholder="Map Latitude"
                              value={form_inputs.latitude}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-box">
                          {/* <label className="label-text">
                            For precise location, drag the red marker on the
                            map.
                          </label> */}
                          {/* <div className="form-group map-container">
                            <div
                              id="singleMap"
                              className="drag-map height-400"
                              data-latitude="40.7427837"
                              data-longitude="-73.11445617675781"
                            >
                              <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3766.5114120644125!2d72.8607642!3d19.260113999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b160eebd91c5%3A0x772aa60ab818317d!2sPinkal%20IT%20Solutions!5e0!3m2!1sen!2sin!4v1597749947931!5m2!1sen!2sin"
                                frameborder="0"
                                style={{ border: "0" }}
                                width="100%"
                                height="100%"
                                allowFullScreen
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        {/* <label className="label-text">
                          Or enter Coordinates (longitude and latitude)
                          Manually.
                        </label> */}
                      </div>
                      <div className="col-lg-12">
                        <div className="input-box">
                          <label className="label-text">
                            Add Custom Address
                          </label>
                          <div className="form-group">
                            <span className="la la-map-marker form-icon"></span>
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Add address here"
                              name="address"
                              required
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-6">
                      <div className="input-box">
                        <label className="label-text">Longitude</label>
                        <div className="form-group">
                          <span className="la la-map form-icon"></span>
                          <input
                            className="form-control"
                            type="text"
                            required
                            placeholder="40.731444531797315"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="input-box">
                        <label className="label-text">Latitude</label>
                        <div className="form-group">
                          <span className="la la-map form-icon"></span>
                          <input
                            className="form-control"
                            type="text"
                            required
                            placeholder="40.731444531797315"
                          />
                        </div>
                      </div>
                    </div> */}
                      {/* <div className="col-lg-6">
                        <div className="input-box">
                          <label className="label-text d-flex align-items-center">
                            City
                            <i
                              className="la la-question tip ml-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Provide your city name for your business to show up on the map and your customer can get direction. "
                            ></i>
                          </label>
                          <div className="form-group user-chosen-select-container">
                            <Select
                              className="user-chosen-select"
                              options={cities}
                              name="city"
                              required
                              onChange={(city) => {
                                setCity(city);
                              }}
                            />
                          </div>
                        </div>
                      </div> */}
                      <div className="col-lg-6">
                        <div className="input-box">
                          <label className="label-text">Telephone</label>
                          <div className="form-group">
                            <span className="la la-phone form-icon"></span>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="+91"
                              name="telephone_number"
                              required
                              onChange={handleChange}
                            />
                          </div>
                          {!validPhone(form_inputs?.telephone_number) &&
                            form_inputs?.telephone_number?.length > 0 && (
                              <span
                                className="error-text"
                                style={{
                                  color: 'red',
                                }}
                              >
                                Telephone Number Invalid
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-box">
                          <label className="label-text">Phone</label>
                          <div className="form-group">
                            <span className="la la-phone form-icon"></span>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="+91"
                              name="phone"
                              required
                              onChange={handleChange}
                            />
                          </div>
                          {!validPhone(form_inputs?.phone) &&
                            form_inputs?.phone?.length > 0 && (
                              <span
                                className="error-text"
                                style={{
                                  color: 'red',
                                }}
                              >
                                Phone Number Invalid
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-box">
                          <label className="label-text d-flex align-items-center">
                            City
                            <i
                              className="la la-question tip ml-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Provide your city name for your business to show up on the map and your customer can get direction. "
                            ></i>
                          </label>
                          <div className="form-group user-chosen-select-container">
                            <Select
                              className="user-chosen-select"
                              options={cities}
                              name="city"
                              required
                              onChange={(city) => {
                                setCity(city);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                {/* <div className="block-card mb-4">
                <div className="block-card-header">
                  <h2 className="widget-title">Price Details</h2>
                  <div className="stroke-shape"></div>
                </div>
                <div className="block-card-body">
                  <form className="form-box row">
                    <div className="col-lg-4">
                      <div className="input-box">
                        <label className="label-text">Price Range</label>
                        <div className="form-group user-chosen-select-container">
                          <Select
                            options={prices}
                            onChange={(price) => {
                              setPriceRange(price);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="input-box">
                        <label className="label-text">Price min</label>
                        <div className="form-group">
                          <input
                            className="form-control pl-3"
                            type="text"
                            name="price_min"
                            onChange={handleChange}
                            placeholder="Price min"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="input-box">
                        <label className="label-text">Price Max</label>
                        <div className="form-group">
                          <input
                            className="form-control pl-3"
                            type="text"
                            name="price_max"
                            onChange={handleChange}
                            placeholder="Price max"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div> */}
                <div className="block-card mb-4">
                  <div className="block-card-header">
                    <h2 className="widget-title">Opening Hours</h2>
                    <div className="stroke-shape"></div>
                  </div>
                  <div className="block-card-body">
                    <div className="form-box table-responsive">
                      <table className="table time-list mb-0">
                        <thead>
                          <tr>
                            <th className="w-50">Days</th>
                            <th>Open</th>
                            <th>Close</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="business-opening-wrap">
                            <td className="business-day">All</td>
                            <td className="user-chosen-select-container">
                              <Select
                                options={timings}
                                maxMenuHeight={'10rem'}
                                menuPortalTarget={document.querySelector(
                                  'body'
                                )}
                                onChange={(time) => {
                                  // setMonday(time);
                                  handleAllHours('opening', time);
                                }}
                              />
                            </td>
                            <td className="user-chosen-select-container">
                              <Select
                                options={timings}
                                maxMenuHeight={'10rem'}
                                menuPortalTarget={document.querySelector(
                                  'body'
                                )}
                                onChange={(time) => {
                                  // setMonday(time);
                                  handleAllHours('close', time);
                                }}
                              />
                            </td>
                          </tr>
                          <tr className="business-opening-wrap">
                            <td className="business-day">Monday</td>
                            <td className="user-chosen-select-container">
                              <Select
                                options={timings}
                                maxMenuHeight={'10rem'}
                                menuPortalTarget={document.querySelector(
                                  'body'
                                )}
                                value={getSelectValue('Monday', 'opening')}
                                onChange={(time) => {
                                  // setMonday(time);
                                  handleHours('opening', 'Monday', time);
                                }}
                              />
                            </td>
                            <td className="user-chosen-select-container">
                              <Select
                                options={timings}
                                maxMenuHeight={'10rem'}
                                menuPortalTarget={document.querySelector(
                                  'body'
                                )}
                                value={getSelectValue('Monday', 'close')}
                                onChange={(time) => {
                                  // setMonday(time);
                                  handleHours('close', 'Monday', time);
                                }}
                              />
                            </td>
                          </tr>
                          <tr className="business-opening-wrap">
                            <td className="business-day">Tuesday</td>
                            <td className="user-chosen-select-container">
                              <Select
                                options={timings}
                                maxMenuHeight={'10rem'}
                                value={getSelectValue('Tuesday', 'opening')}
                                menuPortalTarget={document.querySelector(
                                  'body'
                                )}
                                onChange={(time) => {
                                  // setTuesday(time);
                                  handleHours('opening', 'Tuesday', time);
                                }}
                              />
                            </td>
                            <td className="user-chosen-select-container">
                              <Select
                                options={timings}
                                maxMenuHeight={'10rem'}
                                value={getSelectValue('Tuesday', 'close')}
                                menuPortalTarget={document.querySelector(
                                  'body'
                                )}
                                onChange={(time) => {
                                  // setTuesday(time);
                                  handleHours('close', 'Tuesday', time);
                                }}
                              />
                            </td>
                          </tr>
                          <tr className="business-opening-wrap">
                            <td className="business-day">Wednesday</td>
                            <td className="user-chosen-select-container">
                              <Select
                                options={timings}
                                maxMenuHeight={'10rem'}
                                value={getSelectValue('Wednesday', 'opening')}
                                menuPortalTarget={document.querySelector(
                                  'body'
                                )}
                                onChange={(time) => {
                                  // setWednesday(time);
                                  handleHours('opening', 'Wednesday', time);
                                }}
                              />
                            </td>
                            <td className="user-chosen-select-container">
                              <Select
                                options={timings}
                                maxMenuHeight={'10rem'}
                                value={getSelectValue('Wednesday', 'close')}
                                menuPortalTarget={document.querySelector(
                                  'body'
                                )}
                                onChange={(time) => {
                                  // setWednesday(time);
                                  handleHours('close', 'Wednesday', time);
                                }}
                              />
                            </td>
                          </tr>
                          <tr className="business-opening-wrap">
                            <td className="business-day">Thursday</td>
                            <td className="user-chosen-select-container">
                              <Select
                                options={timings}
                                maxMenuHeight={'10rem'}
                                value={getSelectValue('Thursday', 'opening')}
                                menuPortalTarget={document.querySelector(
                                  'body'
                                )}
                                onChange={(time) => {
                                  // setThursday(time);
                                  handleHours('opening', 'Thursday', time);
                                }}
                              />
                            </td>
                            <td className="user-chosen-select-container">
                              <Select
                                options={timings}
                                maxMenuHeight={'10rem'}
                                value={getSelectValue('Thursday', 'close')}
                                menuPortalTarget={document.querySelector(
                                  'body'
                                )}
                                onChange={(time) => {
                                  // setThursday(time);
                                  handleHours('close', 'Thursday', time);
                                }}
                              />
                            </td>
                          </tr>
                          <tr className="business-opening-wrap">
                            <td className="business-day">Friday</td>
                            <td className="user-chosen-select-container">
                              <Select
                                options={timings}
                                maxMenuHeight={'10rem'}
                                value={getSelectValue('Friday', 'opening')}
                                menuPortalTarget={document.querySelector(
                                  'body'
                                )}
                                onChange={(time) => {
                                  // setFriday(time);
                                  handleHours('opening', 'Friday', time);
                                }}
                              />
                            </td>
                            <td className="user-chosen-select-container">
                              <Select
                                options={timings}
                                maxMenuHeight={'10rem'}
                                value={getSelectValue('Friday', 'close')}
                                menuPortalTarget={document.querySelector(
                                  'body'
                                )}
                                onChange={(time) => {
                                  // setFriday(time);
                                  handleHours('close', 'Friday', time);
                                }}
                              />
                            </td>
                          </tr>
                          <tr className="business-opening-wrap">
                            <td className="business-day">Saturday</td>
                            <td className="user-chosen-select-container">
                              <Select
                                options={timings}
                                maxMenuHeight={'10rem'}
                                value={getSelectValue('Saturday', 'opening')}
                                menuPortalTarget={document.querySelector(
                                  'body'
                                )}
                                onChange={(time) => {
                                  // setSaturday(time);
                                  handleHours('opening', 'Saturday', time);
                                }}
                              />
                            </td>
                            <td className="user-chosen-select-container">
                              <Select
                                options={timings}
                                maxMenuHeight={'10rem'}
                                value={getSelectValue('Saturday', 'close')}
                                menuPortalTarget={document.querySelector(
                                  'body'
                                )}
                                onChange={(time) => {
                                  // setSaturday(time);
                                  handleHours('close', 'Saturday', time);
                                }}
                              />
                            </td>
                          </tr>
                          <tr className="business-opening-wrap">
                            <td className="business-day">Sunday</td>
                            <td className="user-chosen-select-container">
                              <Select
                                options={timings}
                                maxMenuHeight={'10rem'}
                                value={getSelectValue('Sunday', 'opening')}
                                menuPortalTarget={document.querySelector(
                                  'body'
                                )}
                                onChange={(time) => {
                                  // setSunday(time);
                                  handleHours('opening', 'Sunday', time);
                                }}
                              />
                            </td>
                            <td className="user-chosen-select-container">
                              <Select
                                options={timings}
                                maxMenuHeight={'10rem'}
                                value={getSelectValue('Sunday', 'close')}
                                menuPortalTarget={document.querySelector(
                                  'body'
                                )}
                                onChange={(time) => {
                                  // setSunday(time);
                                  handleHours('close', 'Sunday', time);
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="block-card mb-4">
                  <div className="block-card-header">
                    <h2 className="widget-title">Social Media</h2>
                    <div className="stroke-shape"></div>
                  </div>
                  <div className="block-card-body">
                    <form method="post" className="form-box row">
                      <div className="col-lg-12">
                        <div className="input-box">
                          <label className="label-text">
                            Facebook Link{' '}
                            <span className="text-gray font-size-12">
                              (optional)
                            </span>
                          </label>
                          <div className="form-group">
                            <span className="la la-facebook-f form-icon"></span>
                            <input
                              className="form-control"
                              type="text"
                              name="facebook_link"
                              onChange={handleChange}
                              placeholder="https://"
                            />
                            {!validUrl(form_inputs?.facebook_link) &&
                              form_inputs?.facebook_link?.length > 0 && (
                                <span
                                  className="error-text"
                                  style={{
                                    color: 'red',
                                  }}
                                >
                                  Facebook Address is Invalid
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-box">
                          <label className="label-text">
                            Twitter Link{' '}
                            <span className="text-gray font-size-12">
                              (optional)
                            </span>
                          </label>
                          <div className="form-group">
                            <span className="la la-twitter form-icon"></span>
                            <input
                              className="form-control"
                              type="text"
                              name="twitter_link"
                              onChange={handleChange}
                              placeholder="https://"
                            />
                            {!validUrl(form_inputs?.twitter_link) &&
                              form_inputs?.twitter_link?.length > 0 && (
                                <span
                                  className="error-text"
                                  style={{
                                    color: 'red',
                                  }}
                                >
                                  Twitter Address is Invalid
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-box">
                          <label className="label-text">
                            Instagram Link{' '}
                            <span className="text-gray font-size-12">
                              (optional)
                            </span>
                          </label>
                          <div className="form-group">
                            <span className="la la-instagram form-icon"></span>
                            <input
                              className="form-control"
                              type="text"
                              name="insta_link"
                              onChange={handleChange}
                              placeholder="https://"
                            />
                            {!validUrl(form_inputs?.insta_link) &&
                              form_inputs?.insta_link?.length > 0 && (
                                <span
                                  className="error-text"
                                  style={{
                                    color: 'red',
                                  }}
                                >
                                  Instagram Address is Invalid
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="input-box">
                          <label className="label-text">
                            Video{' '}
                            <span className="text-gray font-size-12">
                              (optional)
                            </span>
                          </label>
                          <div className="form-group mb-0">
                            <span className="la la-video form-icon"></span>
                            <input
                              className="form-control"
                              type="text"
                              name="youtube_link"
                              onChange={handleChange}
                              placeholder="URL to video"
                            />
                            {!validUrl(form_inputs?.youtube_link) &&
                              form_inputs?.youtube_link?.length > 0 && (
                                <span
                                  className="error-text"
                                  style={{
                                    color: 'red',
                                  }}
                                >
                                  Youtube Address is Invalid
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="block-card mb-4">
                  <div className="block-card-header">
                    <h2 className="widget-title">Media</h2>
                    <div className="stroke-shape"></div>
                  </div>
                  {/* <ImageControl /> */}

                  <div className="row">
                    {images.map((key, value) => {
                      return (
                        <CategoryImageHolder
                          storeImages={(new_image) => {
                            setImages([...images, new_image]);
                          }}
                          storeCategories={(image_cat_data) => {
                            let temp = { ...image_categories };
                            const temp_key = Object.keys(image_cat_data)[0];
                            temp[temp_key] = image_cat_data[temp_key];

                            setImageCategories(temp);
                          }}
                        />
                      );
                    })}
                  </div>

                  {/* <div className="block-card-body">
                  <label className="label-text">Gallery Images</label>
                  <div className="file-upload-wrap">
                    <input
                      type="file"
                      name="files[]"
                      className="multi file-upload-input with-preview"
                      multiple
                    />
                    <span className="file-upload-text">
                      <i className="la la-upload mr-2"></i>Drop files here or
                      click to upload
                    </span>
                  </div>
                </div> */}
                </div>
                <div className="block-card mb-4">
                  <div className="block-card-header">
                    <h3 className="widget-title">Amenities</h3>
                    <div className="stroke-shape"></div>
                  </div>
                  <div className="block-card-body row">
                    {!!amenitiesList.length &&
                      amenitiesList.map((item) => {
                        return (
                          <div className="col-lg-4 custom-checkbox">
                            <input
                              type="checkbox"
                              id={item.id}
                              onChange={(e) => {
                                handleAmenities(e.target.id);
                                console.log(amenities);
                              }}
                            />
                            <label for={item.id}>{item.name}</label>
                          </div>
                        );
                      })}
                    {/* <div className="col-lg-4 custom-checkbox">
                      <input
                        type="checkbox"
                        id="securityCameras"
                        onChange={(e) => {
                          handleAmenities(e.target.id);
                        }}
                      />
                      <label htmlFor="securityCameras">Security Cameras</label>
                    </div>
                    <div className="col-lg-4 custom-checkbox">
                      <input
                        type="checkbox"
                        id="elevatorInBuilding"
                        onChange={(e) => {
                          handleAmenities(e.target.id);
                        }}
                      />
                      <label htmlFor="elevatorInBuilding">
                        Elevator in Building
                      </label>
                    </div>
                    <div className="col-lg-4 custom-checkbox">
                      <input
                        type="checkbox"
                        id="wirelessInternet"
                        onChange={(e) => {
                          handleAmenities(e.target.id);
                        }}
                      />
                      <label htmlFor="wirelessInternet">Wireless Internet</label>
                    </div>
                    <div className="col-lg-4 custom-checkbox">
                      <input
                        type="checkbox"
                        id="WheelchairAccessible"
                        onChange={(e) => {
                          handleAmenities(e.target.id);
                        }}
                      />
                      <label htmlFor="WheelchairAccessible">
                        Wheelchair Accessible
                      </label>
                    </div>
                    <div className="col-lg-4 custom-checkbox">
                      <input
                        type="checkbox"
                        id="LaundryRoomInBuilding"
                        onChange={(e) => {
                          handleAmenities(e.target.id);
                        }}
                      />
                      <label htmlFor="LaundryRoomInBuilding">
                        Laundry Room in Building
                      </label>
                    </div>
                    <div className="col-lg-4 custom-checkbox">
                      <input
                        type="checkbox"
                        id="AlarmSystem"
                        onChange={(e) => {
                          handleAmenities(e.target.id);
                        }}
                      />
                      <label htmlFor="AlarmSystem">Alarm System</label>
                    </div>
                    <div className="col-lg-4 custom-checkbox">
                      <input
                        type="checkbox"
                        id="SmokingAllowed"
                        onChange={(e) => {
                          handleAmenities(e.target.id);
                        }}
                      />
                      <label htmlFor="SmokingAllowed">Smoking Allowed</label>
                    </div>
                    <div className="col-lg-4 custom-checkbox">
                      <input
                        type="checkbox"
                        id="FreeParkingOnStreet"
                        onChange={(e) => {
                          handleAmenities(e.target.id);
                        }}
                      />
                      <label htmlFor="FreeParkingOnStreet">
                        Free Parking on Street
                      </label>
                    </div>
                    <div className="col-lg-4 custom-checkbox">
                      <input
                        type="checkbox"
                        id="FriendlyWorkspace"
                        onChange={(e) => {
                          handleAmenities(e.target.id);
                        }}
                      />
                      <label htmlFor="FriendlyWorkspace">Friendly Workspace</label>
                    </div>
                    <div className="col-lg-4 custom-checkbox">
                      <input
                        type="checkbox"
                        id="Electricity"
                        onChange={(e) => {
                          handleAmenities(e.target.id);
                        }}
                      />
                      <label htmlFor="Electricity">Electricity</label>
                    </div>
                    <div className="col-lg-4 custom-checkbox">
                      <input
                        type="checkbox"
                        id="AttachedGarage"
                        onChange={(e) => {
                          handleAmenities(e.target.id);
                        }}
                      />
                      <label htmlFor="AttachedGarage">Attached Garage</label>
                    </div>
                    <div className="col-lg-4 custom-checkbox">
                      <input
                        type="checkbox"
                        id="BikeParking"
                        onChange={(e) => {
                          handleAmenities(e.target.id);
                        }}
                      />
                      <label htmlFor="BikeParking">Bike Parking</label>
                    </div>
                    <div className="col-lg-4 custom-checkbox">
                      <input
                        type="checkbox"
                        id="Telephone"
                        onChange={(e) => {
                          handleAmenities(e.target.id);
                        }}
                      />
                      <label htmlFor="Telephone">Telephone</label>
                    </div> */}
                  </div>
                </div>
                <div className="submit-wrap pt-4">
                  {/* <div className="custom-checkbox">
                    <input
                      type="checkbox"
                      id="agreeChb2"
                      onChange={(e) => {
                        e.preventDefault();
                        setAgree(!has_agree);
                      }}
                    />
                    <label htmlFor="agreeChb2" className="text-gray">
                      By continuing, you agree to UniWork's{" "}
                      <a
                        href="terms-and-conditions.html"
                        className="text-color-2"
                      >
                        Terms of Service
                      </a>{" "}
                      and acknowledge our{" "}
                      <a href="privacy-policy.html" className="text-color-2">
                        Privacy Policy
                      </a>
                      .
                    </label>
                  </div> */}
                  <div className="btn-box mt-4">
                    <button
                      type="submit"
                      // style={!has_agree ? { cursor: "not-allowed" } : {}}
                      className="theme-btn gradient-btn border-0"
                      // disabled={has_agree}
                    >
                      Save & Preview
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddListing;
