import { Paper, colors } from "@mui/material";
import { Card } from "../card";
import { useCheckoutStore } from "../../states/use-checkout-store";

const AvailableMethods = [
  {
    name: "Card",
    code: "card",
    img: "/logos/card.png",
  },
  {
    name: "UPI",
    code: "vpa",
    img: "/logos/upi.png",
  },
];

export const SelectPaymentMethod = () => {
  const { setCheckoutData, checkout } = useCheckoutStore();

  const handleSelectPaymentMethod = (m) => {
    setCheckoutData({ paymentMethod: m }, 3);
  };

  const isSelected = (m) => {
    return checkout?.paymentMethod?.code === m?.code;
  };

  console.log("checlut", checkout);

  return (
    <Card title={"Select Payment Method"}>
      <div className="container">
        <div className="row justify-content-around">
          {AvailableMethods.map((m, idx) => {
            return (
              <Paper
                key={idx}
                elevation={isSelected(m) ? 5 : 2}
                onClick={() => handleSelectPaymentMethod(m)}
                sx={{
                  border: isSelected(m) ? `1px solid ${colors.blue[200]}` : "",
                }}
                className="col-5 p-4 d-flex flex-column text-center"
              >
                <img
                  style={{
                    height: "6rem",
                    objectFit: "contain",
                  }}
                  src={m.img}
                  alt={m.name}
                />
                <span>{m.name}</span>
              </Paper>
            );
          })}
        </div>
      </div>
    </Card>
  );
};
