import React, { useEffect, useState } from "react";
import AdminNavbar from "../components/admin_navbar";
import AdminSidebar from "../components/admin_sidebar";
// import Breadcrumbs from "../components/breadcumbs";

const Dashboard = () => {
  const [isVendor, setIsVendor] = useState("");

  const getVendorDetails = () => {
    const vDetails = sessionStorage.getItem("state")
      ? JSON.parse(sessionStorage.getItem("state"))
      : [];

    setIsVendor(vDetails.user_details.data[0].is_vendor);
  };

  useEffect(() => {
    getVendorDetails();
  }, []);

  return (
    <section className="dashboard-wrap d-flex">
      <AdminSidebar activeRoute={isVendor ? "dashboard" : "bookings"} />
      {/* <AdminNavbar firstName="Abhishek" lastName="Iyengar" /> */}
      <AdminNavbar />
    </section>
  );
};

export default Dashboard;
