import axios from 'axios';
import { GET_SUB_CATEGORIES } from '../url_constants';

export const getSubcategoriesList = () => {
  const config = {
    method: 'GET',
    url: GET_SUB_CATEGORIES,
    headers: {
      'Content-Type': 'application/json',
      // 'Authorization': `Bearer ${token}`
    },
  };
  return axios(config).then().catch();
};
