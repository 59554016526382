import { Link } from "react-router-dom";
import logo_white from "../images/logo-white.png";
import styled from "@emotion/styled";

export const MiniHeader = () => {
  return (
    <StyledHeaderBG className="header-menu-wrapper mb-4">
      <div className="container ">
        <div className="row">
          <div className="col-lg-12">
            <div className="menu-full-width">
              <div className="logo">
                <a href="/home">
                  <img src={logo_white} alt="logo" />
                </a>
                <div className="d-flex align-items-center">
                  <Link
                    to="/listing/add"
                    className="btn-gray add-listing-btn-show font-size-24 mr-2 flex-shrink-0"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Add Listing"
                  >
                    <i className="la la-plus"></i>
                  </Link>
                  <div className="menu-toggle">
                    <span className="menu__bar"></span>
                    <span className="menu__bar"></span>
                    <span className="menu__bar"></span>
                  </div>
                </div>
              </div>
              <div className="main-menu-content main-menu-content-2">
                <nav className="main-menu">
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledHeaderBG>
  );
};

export const StyledHeaderBG = styled.div`
  background: linear-gradient(270deg, #9f8fff, #7b68ee);
  margin-bottom: 50px;
`;
