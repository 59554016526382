import React, { useEffect } from "react";
import { useState } from "react";
import AdminSidebar from "../../components/admin_sidebar";
import NavbarAdminWrapper from "../../components/NavbarAdminWrapper";
import Image370 from "../../images/img1.jpg";
import Hotel from "../../images/hotel.jpg";
import ListingLogo from "../../images/listing-logo.jpg";
import ListingLogo2 from "../../images/listing-logo2.jpg";
import ListingLogo3 from "../../images/listing-logo3.jpg";
import DeleteBookmarkModal from "./DeleteBookmarkModal";
import { useGetBookmark } from "../../apis/listing_apis/get-bookmark";
import { getUserDetails } from "../../utils/common";
import ListingCard from "../../components/listing_card";
import { useGetCitiesExtended } from "../../apis/listing_apis/get_cities";

const Bookmarks = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [isVendor, setIsVendor] = useState("");
  const userDetail = getUserDetails();

  const { data } = useGetCitiesExtended();

  const { data: bookmarksData, isLoading: loadingBookmarks } = useGetBookmark(
    {
      id: userDetail.id,
    },
    {
      enabled: !!userDetail?.id,
    }
  );

  const bookmarks = bookmarksData?.data?.data

  const getVendorDetails = () => {
    const vDetails = sessionStorage.getItem("state")
      ? JSON.parse(sessionStorage.getItem("state"))
      : [];
    setIsVendor(vDetails.user_details.data[0].is_vendor);
  };
  useEffect(() => {
    getVendorDetails();
  }, []);

  return (
    <div>
      {deleteModal && <DeleteBookmarkModal />}
      <AdminSidebar activeRoute="bookmarks" />
      <NavbarAdminWrapper>
        <div className="container-fluid dashboard-inner-body-container">
          <div className="breadcrumb-content d-sm-flex align-items-center justify-content-between mb-4">
            <div className="section-heading">
              <h2 className="sec__title font-size-24 mb-0">Bookmarks</h2>
            </div>
            <ul className="list-items bread-list bread-list-2">
              <li>
                <a href="">Home</a>
              </li>
              {isVendor ? <li>Dashboard</li> : null}
              <li>Bookmarks</li>
            </ul>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="block-card dashboard-card mb-4">
                <div className="block-card-header">
                  <h2 className="widget-title pb-0">Bookmarked Listings</h2>
                </div>
                <div className="block-card-body">
                  {bookmarks?.length > 0 && bookmarks ? (
                    bookmarks.map((value, key) => {
                      return (
                        <ListingCard
                          key={key}
                          data={value}
                          // refreshListing={refreshListing}
                          // removeListing={removeListing}
                          cities={data}
                        />
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="col-lg-12 pb-4">
              <div className="pagination-wrapper d-inline-block">
                <div className="section-pagination">
                  <nav aria-label="Page navigation">
                    <ul className="pagination flex-wrap justify-content-center">
                      <li className="page-item">
                        <a className="page-link" aria-label="Previous">
                          <span aria-hidden="true">
                            <i className="la la-angle-left"></i>
                          </span>
                          <span className="sr-only">Previous</span>
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link">1</a>
                      </li>
                      <li className="page-item">
                        <a className="page-link page-link-active">2</a>
                      </li>
                      <li className="page-item">
                        <a className="page-link">3</a>
                      </li>
                      <li className="page-item">
                        <a className="page-link">4</a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" aria-label="Next">
                          <span aria-hidden="true">
                            <i className="la la-angle-right"></i>
                          </span>
                          <span className="sr-only">Next</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <ul className="pagination-simple d-flex align-items-center justify-content-center pt-3">
                  <li className="mr-4">
                    <a className="pagination-simple-nav first-nav">
                      <i className="la la-long-arrow-left mr-1"></i>First
                    </a>
                  </li>
                  <li>
                    <a className="pagination-simple-nav last-nav active">
                      Last<i className="la la-long-arrow-right ml-1"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </NavbarAdminWrapper>
    </div>
  );
};

export default Bookmarks;
