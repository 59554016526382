import React from "react";
import { Link } from "react-router-dom";

const ComingSoon = ({ origin = "/listing" }) => {
  return (
    <section className="container text-center mt-5 pt-5">
      <h1 className="display-4">COMING SOON!</h1>
      <Link to={origin}>
        <i className="fas fa-arrow-left" />
        Take Me Back
      </Link>
    </section>
  );
};

export default ComingSoon;
