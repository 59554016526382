import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logoutUser } from "../apis/user_apis/logout";
import { getListings } from "../apis/listing_apis/get_listing";
import { BASE_URL } from "../apis/url_constants";
import author_image from "../images/avatar-img.jpg";
import { getUserDetails } from "../utils/common";
import LineChart from "./LineChart";
import Loader from "./loader";

const AdminNavbar = () => {
  const userData = getUserDetails();
  const [activeListing, setActiveListing] = useState();
  const [loading, setLoading] = useState(false);
  const imageUrl = `${BASE_URL}${userData.profileimage}`;
  const history = useNavigate();

  const fetchListings = () => {
    let userId = null;
    if (userData) {
      userId = userData.id;
    }
    getListings(null, null, userId)
      .then((res) => {
        if (res.status === 200) {
          setActiveListing(res.data.length);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong !!");
      });
  };

  useEffect(() => {
    fetchListings();
  }, []);

  const handleLogout = async () => {
    try {
      logoutUser(sessionStorage?.access)
        .then((res) => {
          if (res.status === 200) {
            setLoading(false);
            console.log("logged out!");
          } else {
            console.log("Error logging out: ", res);
          }
        })
        .catch((e) => {
          console.log("Error logging out: ", e);
          toast.error("Something went wrong !!");
        });
    } catch (e) {
      console.log("Error logging out: ", e);
      toast.error("Something went wrong !!");
    } finally {
      localStorage.clear();
      sessionStorage.clear();
      console.log("THIS is");
      history("/", { replace: true }); //* Takes you back to home page in any case
      sessionStorage.setItem("LOADER", true);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="dashboard-body d-flex flex-column">
      <div className="dashboard-inner-body flex-grow-1">
        <nav className="navbar navbar-expand bg-navbar dashboard-topbar mb-4">
          <button id="sidebarToggleTop" className="btn rounded-circle mr-3">
            <i className="la la-bars"></i>
          </button>
          <ul className="navbar-nav ml-auto">
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle after-none"
                id="searchDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="la la-search"></i>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right p-3 animated--grow-in"
                aria-labelledby="searchDropdown"
              >
                <form className="search-box">
                  <div className="input-group">
                    <label className="input-label mb-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                      />
                    </label>
                    <div className="input-group-append">
                      <button className="btn btn-primary" type="button">
                        <i className="la la-search"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </li> */}
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle after-none"
                id="alertsDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="la la-bell"></i>
                <span className="badge badge-danger badge-counter">3+</span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right animated--grow-in"
                aria-labelledby="alertsDropdown"
              >
                <h6 className="generic-list-header">Alerts Center</h6>
                <div className="generic-list scrollable-content scrollbar-hidden">
                  <a className="generic-list-item d-flex align-items-center">
                    <div className="icon-element flex-shrink-0 bg-1">
                      <i className="la la-file-alt text-white"></i>
                    </div>
                    <div className="ml-2">
                      <p className="small text-gray">December 12, 2019</p>
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        A new monthly report is ready to download!
                      </p>
                    </div>
                  </a>
                  <a className="generic-list-item d-flex align-items-center">
                    <div className="icon-element flex-shrink-0 bg-2">
                      <i className="la la-donate"></i>
                    </div>
                    <div className="ml-2">
                      <p className="small text-gray">December 7, 2019</p>
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        $290.29 has been deposited into your account!
                      </p>
                    </div>
                  </a>
                  <a className="generic-list-item d-flex align-items-center">
                    <div className="icon-element flex-shrink-0 bg-3">
                      <i className="la la-exclamation-triangle"></i>
                    </div>
                    <div className="ml-2">
                      <p className="small text-gray">December 4, 2019</p>
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        Spending Alert: We've noticed unusually high spending
                        for your account.
                      </p>
                    </div>
                  </a>
                  <a className="generic-list-item d-flex align-items-center">
                    <div className="icon-element flex-shrink-0 bg-4">
                      <i className="la la-check-circle"></i>
                    </div>
                    <div className="ml-2">
                      <p className="small text-gray">December 1, 2019</p>
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        Your listing Hotel Govendor has been approved!{' '}
                      </p>
                    </div>
                  </a>
                  <a className="generic-list-item d-flex align-items-center">
                    <div className="icon-element flex-shrink-0 bg-5">
                      <i className="la la-check-circle"></i>
                    </div>
                    <div className="ml-2">
                      <p className="small text-gray">December 1, 2019</p>
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        Your listing Burger House has been approved!{' '}
                      </p>
                    </div>
                  </a>
                </div>
                <a className="dropdown-item text-center small text-gray font-weight-medium py-2">
                  Show All Alerts
                </a>
              </div>
            </li> */}
            {/* <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle after-none"
                id="messagesDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="la la-envelope"></i>
                <span className="badge badge-warning badge-counter">2</span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-right animated--grow-in"
                aria-labelledby="messagesDropdown"
              >
                <h6 className="generic-list-header">Messages Center</h6>
                <div className="generic-list scrollable-content scrollbar-hidden">
                  <a
                    className="generic-list-item d-flex align-items-center"
                    href="dashboard-messages.html"
                  >
                    <div className="user-thumb user-thumb-sm flex-shrink-0 position-relative">
                      <img src={author_image} />
                      <div className="status-indicator bg-success"></div>
                    </div>
                    <div className="ml-2">
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        Hi there! I am wondering if you can help me with a
                        problem I've been having.
                      </p>
                      <p className="small text-gray">Udin Cilok · 1m</p>
                    </div>
                  </a>
                  <a
                    className="generic-list-item d-flex align-items-center"
                    href="dashboard-messages.html"
                  >
                    <div className="user-thumb user-thumb-sm flex-shrink-0 position-relative">
                      <img src={author_image} />
                      <div className="status-indicator"></div>
                    </div>
                    <div className="ml-2">
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        Am I a good boy? The reason I ask is because someone
                        told me that people say this to all dogs, even if they
                        aren't good
                      </p>
                      <p className="small text-gray">Joynal Ali · 4m</p>
                    </div>
                  </a>
                  <a
                    className="generic-list-item d-flex align-items-center"
                    href="dashboard-messages.html"
                  >
                    <div className="user-thumb user-thumb-sm flex-shrink-0 position-relative">
                      <img src={author_image} />
                      <div className="status-indicator bg-success"></div>
                    </div>
                    <div className="ml-2">
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        Am I a good boy? The reason I ask is because someone
                        told me that people say this to all dogs, even if they
                        aren't good
                      </p>
                      <p className="small text-gray">Colin Smith · 10m</p>
                    </div>
                  </a>
                  <a
                    className="generic-list-item d-flex align-items-center"
                    href="dashboard-messages.html"
                  >
                    <div className="user-thumb user-thumb-sm flex-shrink-0 position-relative">
                      <img src={author_image} />
                      <div className="status-indicator bg-success"></div>
                    </div>
                    <div className="ml-2">
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        Am I a good boy? The reason I ask is because someone
                        told me that people say this to all dogs, even if they
                        aren't good
                      </p>
                      <p className="small text-gray">Alex Smith · 1h</p>
                    </div>
                  </a>
                  <a
                    className="generic-list-item d-flex align-items-center"
                    href="dashboard-messages.html"
                  >
                    <div className="user-thumb user-thumb-sm flex-shrink-0 position-relative">
                      <img src={author_image} />
                      <div className="status-indicator"></div>
                    </div>
                    <div className="ml-2">
                      <p className="text-truncate text-color font-size-14 font-weight-medium">
                        Am I a good boy? The reason I ask is because someone
                        told me that people say this to all dogs, even if they
                        aren't good
                      </p>
                      <p className="small text-gray">Kamran adi · Yesterday</p>
                    </div>
                  </a>
                </div>
                <a className="dropdown-item text-center small text-gray font-weight-medium py-2">
                  Read More Messages
                </a>
              </div>
            </li> */}
            <li className="nav-item dropdown border-left pl-3 ml-4">
              <a
                className="nav-link dropdown-toggle after-none"
                id="userDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="user-thumb user-thumb-sm position-relative">
                  <img
                    src={userData.profileimage ? imageUrl : author_image}
                    alt="profile"
                  />
                  <div className="status-indicator bg-success"></div>
                </div>
                <span className="ml-2 small font-weight-medium d-none d-lg-inline">
                  {userData.first_name} {userData.last_name}
                </span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-sm dropdown-menu-right animated--grow-in py-2"
                aria-labelledby="userDropdown"
              >
                <Link className="dropdown-item text-color font-size-15" to="/myProfile">
                  <i className="la la-user mr-2 text-gray font-size-18"></i>
                  Profile
                </Link>
                {/* <a
                  className="dropdown-item text-color font-size-15"
                  href="dashboard-messages.html"
                >
                  <i className="la la-envelope mr-2 text-gray font-size-18"></i>
                  Messages
                </a> */}
                <Link className="dropdown-item text-color font-size-15" to="/bookings">
                  <i className="la la-shopping-bag mr-2 text-gray font-size-18"></i>
                  Bookings
                </Link>
                <Link className="dropdown-item text-color font-size-15" to="/bookmarks">
                  <i className="la la-bookmark mr-2 text-gray font-size-18"></i>
                  Bookmarks
                </Link>
                <Link className="dropdown-item text-color font-size-15" to="/listing/add">
                  <i className="la la-plus-circle mr-2 text-gray font-size-18"></i>
                  Add Listing
                </Link>
                <div
                  className="dropdown-item text-color font-size-15"
                  onClick={async () => {
                    handleLogout();
                  }}
                >
                  {/* <Link to="/"> */}
                  <i className="la la-power-off mr-2 text-gray font-size-18"></i>
                  Logout
                  {/* </Link> */}
                </div>
              </div>
            </li>
          </ul>
        </nav>
        <div className="container-fluid dashboard-inner-body-container">
          <div className="breadcrumb-content d-sm-flex align-items-center justify-content-between mb-4">
            <div className="section-heading">
              <h2 className="sec__title font-size-24 mb-0">
                Howdy, {userData.first_name} {userData.last_name}!
              </h2>
            </div>
            <ul className="list-items bread-list bread-list-2">
              <li>
                <a>Home</a>
              </li>
              {/* <li>Dashboard</li> */}
            </ul>
          </div>
          <div className="row">
            <div className="col-lg-12 mb-4">
              <div
                className="alert alert-success alert-dismissible fade show p-3"
                role="alert"
              >
                Congratulations! Your listing <strong>Hotel Govendor</strong> has been
                approved!
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card-item dashboard-stat">
                <div className="card-content">
                  <div className="row align-items-center">
                    <div className="col">
                      <h2 className="card-title font-size-40">{activeListing}</h2>
                      <p className="card-sub font-size-18 line-height-24">
                        Active Listings
                      </p>
                    </div>
                    <div className="col-auto font-size-60">
                      <i className="la la-map-marked text-primary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card-item dashboard-stat">
                <div className="card-content">
                  <div className="row align-items-center">
                    <div className="col">
                      <h2 className="card-title font-size-40">1,020</h2>
                      <p className="card-sub font-size-18 line-height-24">Total Views</p>
                    </div>
                    <div className="col-auto font-size-60">
                      <i className="la la-line-chart text-success"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card-item dashboard-stat">
                <div className="card-content">
                  <div className="row align-items-center">
                    <div className="col">
                      <h2 className="card-title font-size-40">200</h2>
                      <p className="card-sub font-size-18 line-height-24">
                        Total Reviews
                      </p>
                    </div>
                    <div className="col-auto font-size-60">
                      <i className="la la-star-o text-info"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="card-item dashboard-stat">
                <div className="card-content">
                  <div className="row align-items-center">
                    <div className="col">
                      <h2 className="card-title font-size-40">120</h2>
                      <p className="card-sub font-size-18 line-height-24">Bookmarked</p>
                    </div>
                    <div className="col-auto font-size-60">
                      <i className="la la-bookmark text-warning"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <LineChart />
            </div>
            <div className="col-lg-5">
              <div className="block-card dashboard-card mb-4">
                <div className="block-card-header">
                  <h2 className="widget-title pb-0">Recent Activities</h2>
                </div>
                <div className="block-card-body">
                  <div className="generic-list-card d-flex align-items-center justify-content-between">
                    <div className="generic-list-card-content d-flex align-items-center mr-2">
                      <span className="icon-element bg-1 mr-2 after-none">
                        <i className="la la-check"></i>
                      </span>
                      <span className="font-weight-medium font-size-15">
                        Your listing <a className="generic-link">Hotel Gulshan</a> has
                        been approved!
                      </span>
                    </div>
                    <a href="javascript:void(0)" className="generic-close">
                      <i className="la la-times"></i>
                    </a>
                  </div>
                  <div className="generic-list-card d-flex align-items-center justify-content-between">
                    <div className="generic-list-card-content d-flex align-items-center mr-2">
                      <span className="icon-element bg-2 mr-2 after-none">
                        <i className="la la-check"></i>
                      </span>
                      <span className="font-weight-medium font-size-15">
                        Your listing <a className="generic-link">Burger House</a> has been
                        approved!
                      </span>
                    </div>
                    <a href="javascript:void(0)" className="generic-close">
                      <i className="la la-times"></i>
                    </a>
                  </div>
                  <div className="generic-list-card d-flex align-items-center justify-content-between">
                    <div className="generic-list-card-content d-flex align-items-center mr-2">
                      <span className="icon-element bg-3 mr-2 after-none">
                        <i className="la la-star-o"></i>
                      </span>
                      <span className="font-weight-medium font-size-15">
                        Pitter Parker left a review{" "}
                        <span className="badge badge-warning text-white">3.4</span> on{" "}
                        <a className="generic-link">John's Coffee Shop</a>
                      </span>
                    </div>
                    <a href="javascript:void(0)" className="generic-close">
                      <i className="la la-times"></i>
                    </a>
                  </div>
                  <div className="generic-list-card d-flex align-items-center justify-content-between">
                    <div className="generic-list-card-content d-flex align-items-center mr-2">
                      <span className="icon-element bg-4 mr-2 after-none">
                        <i className="la la-bookmark"></i>
                      </span>
                      <span className="font-weight-medium font-size-15">
                        Someone bookmarked your{" "}
                        <a className="generic-link">Super Duper Burgers</a>
                      </span>
                    </div>
                    <a href="javascript:void(0)" className="generic-close">
                      <i className="la la-times"></i>
                    </a>
                  </div>
                  <div className="generic-list-card d-flex align-items-center justify-content-between">
                    <div className="generic-list-card-content d-flex align-items-center mr-2">
                      <span className="icon-element bg-5 mr-2 after-none">
                        <i className="la la-star-o"></i>
                      </span>
                      <span className="font-weight-medium font-size-15">
                        Alex Smith left a review{" "}
                        <span className="badge badge-warning text-white">4.4</span> on{" "}
                        <a className="generic-link">Tom's Restaurant</a>
                      </span>
                    </div>
                    <a href="javascript:void(0)" className="generic-close">
                      <i className="la la-times"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="block-card dashboard-card mb-4 px-0">
                <div className="block-card-header d-flex flex-wrap align-items-center justify-content-between px-4 border-bottom-0 pb-0">
                  <h2 className="widget-title pb-0">Invoices</h2>
                  <a className="btn-gray">
                    View More <i className="la la-arrow-right ml-1"></i>
                  </a>
                </div>
                <div className="block-card-body">
                  <div className="my-table table-responsive">
                    <table className="table align-items-center table-flush mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th>Order ID</th>
                          <th>Customer</th>
                          <th>Item</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <a className="order-id">RA0449</a>
                          </td>
                          <td>Udin Wayang</td>
                          <td>Nasi Padang</td>
                          <td>20/02/2020</td>
                          <td>
                            <span className="badge badge-success">Paid</span>
                          </td>
                          <td>
                            <a
                              href="invoice.html"
                              className="btn btn-sm theme-btn-primary"
                            >
                              Invoice
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a className="order-id">RA5324</a>
                          </td>
                          <td>Jaenab Bajigur</td>
                          <td>Gundam 90' Edition</td>
                          <td>20/02/2020</td>
                          <td>
                            <span className="badge badge-warning text-white">
                              Shipping
                            </span>
                          </td>
                          <td>
                            <a
                              href="invoice.html"
                              className="btn btn-sm theme-btn-primary"
                            >
                              Invoice
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a className="order-id">RA8568</a>
                          </td>
                          <td>Rivat Mahesa</td>
                          <td>Oblong T-Shirt</td>
                          <td>20/02/2020</td>
                          <td>
                            <span className="badge badge-danger">Unpaid</span>
                          </td>
                          <td>
                            <a
                              href="invoice.html"
                              className="btn btn-sm theme-btn-primary"
                            >
                              Invoice
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a className="order-id">RA1453</a>
                          </td>
                          <td>Indri Junanda</td>
                          <td>Hat Rounded</td>
                          <td>20/02/2020</td>
                          <td>
                            <span className="badge badge-info">Processing</span>
                          </td>
                          <td>
                            <a
                              href="invoice.html"
                              className="btn btn-sm theme-btn-primary"
                            >
                              Invoice
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a className="order-id">RA1998</a>
                          </td>
                          <td>Udin Cilok</td>
                          <td>Baby Powder</td>
                          <td>20/02/2020</td>
                          <td>
                            <span className="badge badge-success">Paid</span>
                          </td>
                          <td>
                            <a
                              href="invoice.html"
                              className="btn btn-sm theme-btn-primary"
                            >
                              Invoice
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="block-card dashboard-card mb-4 px-0 pb-0">
                <div className="block-card-header px-4">
                  <h2 className="widget-title pb-0">Message From Customer</h2>
                </div>
                <div className="block-card-body pt-0">
                  <div className="generic-list msg-from-customer">
                    <a
                      className="generic-list-item d-flex align-items-center"
                      href="dashboard-messages.html"
                    >
                      <div className="user-thumb user-thumb-sm flex-shrink-0 position-relative">
                        <img src={author_image} />
                        <div className="status-indicator bg-success"></div>
                      </div>
                      <div className="ml-2">
                        <p className="text-truncate text-color font-size-14 font-weight-medium">
                          Hi there! I am wondering if you can help me with a problem I've
                          been having.
                        </p>
                        <p className="small text-gray">Udin Cilok · 1m</p>
                      </div>
                    </a>
                    <a
                      className="generic-list-item d-flex align-items-center"
                      href="dashboard-messages.html"
                    >
                      <div className="user-thumb user-thumb-sm flex-shrink-0 position-relative">
                        <img src={author_image} />
                        <div className="status-indicator"></div>
                      </div>
                      <div className="ml-2">
                        <p className="text-truncate text-color font-size-14 font-weight-medium">
                          Am I a good boy? The reason I ask is because someone told me
                          that people say this to all dogs, even if they aren't good
                        </p>
                        <p className="small text-gray">Joynal Ali · 4m</p>
                      </div>
                    </a>
                    <a
                      className="generic-list-item d-flex align-items-center"
                      href="dashboard-messages.html"
                    >
                      <div className="user-thumb user-thumb-sm flex-shrink-0 position-relative">
                        <img src={author_image} />
                        <div className="status-indicator bg-success"></div>
                      </div>
                      <div className="ml-2">
                        <p className="text-truncate text-color font-size-14 font-weight-medium">
                          Am I a good boy? The reason I ask is because someone told me
                          that people say this to all dogs, even if they aren't good
                        </p>
                        <p className="small text-gray">Colin Smith · 10m</p>
                      </div>
                    </a>
                    <a
                      className="generic-list-item d-flex align-items-center"
                      href="dashboard-messages.html"
                    >
                      <div className="user-thumb user-thumb-sm flex-shrink-0 position-relative">
                        <img src={author_image} />
                        <div className="status-indicator bg-success"></div>
                      </div>
                      <div className="ml-2">
                        <p className="text-truncate text-color font-size-14 font-weight-medium">
                          Am I a good boy? The reason I ask is because someone told me
                          that people say this to all dogs, even if they aren't good
                        </p>
                        <p className="small text-gray">Alex Smith · 1h</p>
                      </div>
                    </a>
                    <a
                      className="generic-list-item d-flex align-items-center"
                      href="dashboard-messages.html"
                    >
                      <div className="user-thumb user-thumb-sm flex-shrink-0 position-relative">
                        <img src={author_image} />
                        <div className="status-indicator"></div>
                      </div>
                      <div className="ml-2">
                        <p className="text-truncate text-color font-size-14 font-weight-medium">
                          Am I a good boy? The reason I ask is because someone told me
                          that people say this to all dogs, even if they aren't good
                        </p>
                        <p className="small text-gray">Kamran adi · Yesterday</p>
                      </div>
                    </a>
                    <a
                      className="dropdown-item text-center small text-gray font-weight-medium py-2"
                      href="dashboard-messages.html"
                    >
                      View More <i className="la la-arrow-right ml-1"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="dashboard-footer bg-white">
        <div className="container-fluid">
          <div className="copy-right d-flex align-items-center justify-content-between">
            <ul className="list-items term-list text-right">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <a href="">About</a>
              </li>
              <li>
                <a href="">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminNavbar;
